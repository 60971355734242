import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ApiService } from 'src/app/Service/api.service';
import { SnackbarService } from 'src/app/Service/snackbar.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-ward-mas',
  templateUrl: './ward-mas.component.html',
  styleUrls: ['./ward-mas.component.css']
})
export class WardMasComponent implements OnInit {

  dismasForm: FormGroup;
  districts:Object;
  tmmk_dist:Object
  branch: Object;
  branchtable: Object;
  submitted = false;
  datasource: any;
  getvalForm: FormGroup;
  att: string;
  stat: string[] = ["Y", "N"];
  btype: string[] = ['Villagepanchayat','Townpanchayat','Muncipality','Corporation']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('TABLE1') table1: ElementRef;
  loading = false;
  constructor(private api: ApiService, private formBuilder: FormBuilder,private spinner: NgxSpinnerService, private sb: SnackbarService) { 
    this.dismasForm = this.formBuilder.group({
      id: new FormControl('', ),
      name: new FormControl('', Validators.required),
      status: new FormControl('y', Validators.required),
      short_name: new FormControl('00',),
      mapped_data : new FormControl('',Validators.required),
      branchtype: new FormControl(''),
      district : new FormControl(''),
      method: new FormControl('insertWard')
      // rememberMe: new FormControl(false)
  });
  }

  displayedColumns: string[] = ['branch_name','branch_short_name','ward_name','status','Edit'];
  ngOnInit(): void {


    this.getData();
     this.getbranch();
     this.getmmkdistrict();
  


  
  }

  add(value){

  
    if (value.id){
      this.att = 'MasterData';
       
      this.api.serviceData(this.att,value,'insertWard','Edit').subscribe((d)=>{
       
        if(d['data']['status'] === 'Error' ){
        
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.dismasForm.controls['mapped_data'].reset()
           this.dismasForm.controls['branchtype'].reset()
           this.dismasForm.controls['district'].reset()
          this.dismasForm.controls['id'].reset()
        
          // this.dismasForm.controls['name'].reset()
        
          // this.dismasForm.controls['mapped_data'].reset()
          this.getData();
         
        }
      
      })

    }
    else{
      this.att = 'MasterData';

      this.api.serviceData(this.att,value,'insertWard','Insert').subscribe((d)=>{
      
        if(d['data']['status'] === 'Error' ){
         
          this.sb.error(d['data']['msg'])
        }
        else{
         
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.getData();
         
        }
        
      
      })
    }
    

   
  }

  exportTable(){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet
    (this.table1.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
    XLSX.writeFile(wb,'Report.xlsx'); 
  }

  edit(value){

    this.branch = this.branchtable;
    this.dismasForm = this.formBuilder.group({
      name: new FormControl(value.ward_name, Validators.required),
      status: new FormControl(value.status, Validators.required),
      id: new FormControl(value.ward_id,Validators.required),
      branch_id: new FormControl(value.branch_id,Validators.required),
      mapped_data : new FormControl(value.branch_id,Validators.required),
      branchtype: new FormControl(''),
      district : new FormControl(''),
      method: new FormControl('insertWard', Validators.required)
      
      // rememberMe: new FormControl(false)
  });


  }

 

  getData(){
    
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getWard'},'commonMethod','getData').subscribe((d:any)=>{
      console.log(d['data'])
      this.datasource = new MatTableDataSource(d['data'].reverse());
       
      setTimeout(() => {
        // this.paginator._intl.itemsPerPageLabel="Test String";
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }, 500);
    
     
    })
  }

  delete(value){
    // this.att = 'CommonMethods';

    // this.api.serviceData(this.att,value,'deleteDistrict','Delete').subscribe((d:any)=>{
    //   console.log(d)
    //  alert(d.msg)
    // })
  }

  applyFilter(filterValue : string) {

    //const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    // alert(this.datasource.filter = filterValue.trim().toLowerCase())
  }
  get f() { return this.dismasForm.controls; }

  
  getmmkdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.tmmk_dist = d['data']
      console.log(this.tmmk_dist)
      
     
    })

  }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }
  // getbranchvalue(value){
  // //   console.log(value)
  // //   this.dismasForm.patchValue({
  // //     mapped_data : value, 
  // //   })  
  // // }

  getdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.districts = d['data']
    
     
    })
  }

  getbranch(value="",branchtype=""){
   

    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{mmk_dist_id:value,branchtype:branchtype,method:'getBranch'},'commonMethod','getData').subscribe((d:any)=>{
  
      this.branch = d['data']
      if (branchtype==""){

        this.branchtable = d['data']
      }
    
    })
  }

 


}
