import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './Service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authenticationService: AuthenticationService) {}
  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot){
    const user = this.authenticationService.currentUserValue
    if(user){
      if(route.data.roles  && route.data.roles.indexOf(user['role']) === -1){
        this.router.navigateByUrl('admin/registers');
        return true;
      }

      return true;
      
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }
}

