import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor( public snackbar: MatSnackBar,
    private zone: NgZone,) { }


    success(msg:string){
      const config = new MatSnackBarConfig();
      config.panelClass = ['blue-snackbar'];
      config.duration = 3000;
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      this.zone.run(() => {
        this.snackbar.open( msg , 'x', config);
      });
    }

    error(msg:string){
      const config = new MatSnackBarConfig();
      config.panelClass = ['red-snackbar'];
      config.duration = 3000;
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      this.zone.run(() => {
        this.snackbar.open(msg, 'x', config);
      });
    }

    successm(msg:string){
      const config = new MatSnackBarConfig();
      config.panelClass = ['blue-snackbar'];
      config.duration = 3000;
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      this.zone.run(() => {
        this.snackbar.open( msg , 'x', config);
      });
    }

    errorm(msg:string){
      const config = new MatSnackBarConfig();
      config.panelClass = ['red-snackbar'];
      config.duration = 3000;
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
      this.zone.run(() => {
        this.snackbar.open(msg, 'x', config);
      });
    }
}
