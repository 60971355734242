import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/Service/api.service';
import { AuthenticationService } from 'src/app/Service/authentication.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  @ViewChild('sDialog') sDialog: TemplateRef<any>;

  openOtherDialog() {
  
    this.dialog.open(this.sDialog,{
      panelClass: 'my-classc'
    });
   
  }

  att: string;

  constructor(public translate: TranslateService,private router: Router, private api: ApiService, private authserver: AuthenticationService, private dialog: MatDialog) { 
    translate.addLangs(['English','தமிழ்']);
    if(localStorage.getItem('locale')){
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/)? browserLang: 'தமிழ்');
  
    }
    else{
      localStorage.setItem('locale','தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    }
  

  changeLang(language: string){
    localStorage.setItem('locale',language);
    this.translate.use(language);
  }
  logout(){
    this.authserver.logout({method:'logout'},'Logout','logout')
    this.openOtherDialog();
    setTimeout(()=>{
      this.dialog.closeAll()
      this.router.navigateByUrl('/login')
    }, 500);
       
     }

  ngOnInit():void{

  }

}
