<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">

            <div class="row mt-1 text-center">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="info-box">
                      <span class="info-box-icon bg-gradient-teal"><i class="fa fa-user-clock"></i></span>
          
                      <div class="info-box-content">
                        <span class="info-box-text ">{{ 'Todays Registration' | translate }}</span>
                        <h1 class="info-box-number text-center" *ngIf="totd || totd == 0"  >{{ totd }}</h1>
                      </div>
                      <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="info-box">
                      <span class="info-box-icon bg-gradient-indigo"><i class="fa fa-check-double"></i></span>
          
                      <div class="info-box-content">
                        <span class="info-box-text">{{ 'Approved Members' | translate }}</span>
                        <h1 class="info-box-number text-center" *ngIf="appp || appp == 0">{{ appp }}</h1>
                      </div>
                    
                    </div>
  
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="info-box">
                      <span class="info-box-icon bg-gradient-lightblue"><i class="fa fa-pause-circle"></i></span>
          
                      <div class="info-box-content">
                        <span class="info-box-text">{{ 'Pending Registrations' | translate }}</span>
                        <h1 class="info-box-number text-center" *ngIf="napp || napp == 0">{{ napp }}</h1>
                      </div>
                      <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                  </div>
                 

            </div>


        </div>
    </div>

    <div class="row text-center">
        <div class="col-12">


          <div class="row mt-1">
            <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="info-box">
                  <span class="info-box-icon bg-gradient-red"><i class="fa fa-user-plus"></i></span>
      
                  <div class="info-box-content">
                    <span class="info-box-text ">{{ 'Total Registrations' | translate }}</span>
                    <h1 class="info-box-number text-center" *ngIf="tot || tot == 0">{{ tot }}</h1>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="info-box">
                  <span class="info-box-icon bg-gradient-orange"><i class="fa fa-times"></i></span>
      
                  <div class="info-box-content">
                    <span class="info-box-text">{{ 'Rejected Registration' | translate }}</span>
                    <h1 class="info-box-number text-center" *ngIf="rej || rej == 0">{{ rej }}</h1>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="info-box">
                  <span class="info-box-icon bg-gradient-yellow"><i class="fa fa-rupee-sign"></i></span>
      
                  <div class="info-box-content">
                    <span class="info-box-text">{{ 'Amount collected' | translate }}</span>
                    <h1 class="info-box-number text-center" *ngIf="amount || amount == 0">{{ amount }}</h1>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="info-box">
                  <span class="info-box-icon bg-gradient-red"><i class="fa fa-thumbs-down"></i></span>
      
                  <div class="info-box-content">
                    <span class="info-box-text">{{ 'Not Paid' | translate }}</span>
                    <h1 class="info-box-number text-center" *ngIf="not || not == 0">{{ not }}</h1>
                  </div>
                  <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
             

        </div>

            
           
        </div>
    </div>
</div>
