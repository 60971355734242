
    <nav class="main-header navbar navbar-expand  navbar-expand-md">
        
        <ul class="navbar-nav">
         <li class="nav-item" >
           <a class="nav-link" data-widget="pushmenu"><i class="fas fa-bars"></i></a>
         </li>
       </ul> 
    
    <ul class="navbar-nav ml-auto" style="position: relative;" >
      <ul class="navbar-nav ml-auto" style="position: relative;" >
        <li class="nav-item">
          {{ 'Language' | translate }} :
               <select #langSelect (change)= "changeLang(langSelect.value)" style="margin-left: 10px;border-radius: 5px;padding: 5px;">
               <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang" >{{ lang  | translate }}</option>
           </select>
        </li>
        
      </ul>  

    </ul>  
     </nav>
     <aside class="main-sidebar sidebar-dark-primary elevation-4">
     <!-- Sidebar -->
     <a href="#" class="brand-link">
      <img src="assets/images/flag.jpg" alt="MMk Logo" class="brand-image elevation-3" >
      <span class="brand-text font-weight-light">MMK</span>
    </a>
     <div class="sidebar">
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <img src="assets/images/admin.png" class=" brand-image img-circle elevation-2" alt="User Image">
          </div>
          <div class="info">
            <a href="#" class="d-block"> {{ 'Admin' | translate }}</a>
           
          </div>   
    </div> 
    
    <hr>
     <nav>
       <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item mb-1 ">
            <a [routerLink]="['/admin/dashboard'] " routerLinkActive="active" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>&nbsp;
              <p> {{ 'Dashboard' | translate }}</p>
            </a>
          </li> 
        <li class="nav-item mb-1">
          <a [routerLink]="['/admin/registers']" routerLinkActive="inner_active" class="nav-link">
           <i class="nav-icon fas fa-table"></i> &nbsp;
            <p>{{ 'Registrations' | translate }}</p>
          </a>
        </li>
              <!-- <li class="nav-item mb-1">
                <a [routerLink]="['/admin/master']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon fa fa-database"></i>&nbsp;
                  <p> {{ 'Master Data' | translate }}</p>
                </a>
              </li> -->
        <li class="nav-item mb-1">
            <a href="#" class="nav-link">
              <i class="nav-icon  fa fa-database"></i>&nbsp;
              <p>
               {{ 'Master Data' | translate }}
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" >
              <li class="nav-item">
                <a [routerLink]="['/admin/district']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon far fa-circle "></i>&nbsp;
                  <p> {{ 'District' | translate }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/admin/mmkdist']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon far fa-circle "></i>&nbsp;
                  <p>{{ 'TMMK-District' | translate }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/admin/category']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon far fa-circle "></i>&nbsp;
                  <p>{{ 'Category' | translate }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/admin/ward']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon far fa-circle "></i>&nbsp;
                  <p>{{ 'Ward' | translate }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a [routerLink]="['/admin/role']"  routerLinkActive="inner_active" class="nav-link">
                  <i class="nav-icon far fa-circle "></i>&nbsp;
                  <p>{{ 'Role' | translate }}</p>
                </a>
              </li>
            </ul>
          </li>
              <li class="nav-item mb-1">
                <a [routerLink]="['/admin/archives']" routerLinkActive="inner_active" class="nav-link">
                 <i class="nav-icon fa fa-file"></i>&nbsp;
                  <p> {{ 'Archives' | translate }}</p>
                </a>
              </li>

              <li class="nav-item">
                <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                  <i class="nav-icon fas fa-sign-out-alt"></i>&nbsp;
                  <p> {{ 'Logout' | translate }}</p>
                </a>
              </li>
         </ul>
         </nav>
     </div>
     </aside> 

     <div class="row">
      <div class="col-12">
        <ng-template #sDialog >
          <h2 matDialogTitle> Authentication</h2>
          <div class="mt-2"></div>
          <p class="text-center" matDialogContent><em>Logged Out Successfully !!</em></p>
          <div class="mt-2 text-center">
            <i class="fa fa-thumbs-up fa-3x"></i>
          </div>
        </ng-template>

        </div>
  </div>
   