<ngx-spinner  size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > 
{{ 'Loading...' | translate }} </p></ngx-spinner>
<section class="content-header">
  
  </section>
  <style>
    .border{
      border-color: rgba(0, 54, 166, 0.67) !important;
    }
  </style>

  <!-- Main content -->
  <section class="content ">
    <div class="container-fluid">
      <div class="row justify-content-center" *ngIf="profvalue; else load;" >
        <div class="col-12 col-md-6">
          <div class="card card-widget widget-user shadow">
              <!-- Add the bg color to the header using any of the bg-* classes -->
              <div class="widget-user-header bg-darkz">
                <h3 class="widget-user-username">{{ profvalue['name'] }}</h3>
                <h5 class="widget-user-desc">{{ profvalue['mmk_mem_id'] }}</h5>
              </div>
              <div class="widget-user-image">
                <img class="profile-user-img img-fluid img-circle elevation-2" [src]="imageUrl" alt="User profile picture">
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{ profvalue['dob'] }}</h5>
                      <span class="description-text">{{ 'DOB' | translate }}</span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                  <div class="col-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{ profvalue['blood_group'] }}</h5>
                      <span class="description-text">{{ 'Blood Group' | translate }}</span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                  <div class="col-4">
                    <div class="description-block">
                      <h5 class="description-header">{{ profvalue['gender'] }}</h5>
                      <span class="description-text">{{ 'Gender' | translate }}</span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                </div>
                <ul class="list-group list-group-unbordered mb-3">
                  <li class="list-group-item bg-light">
                    <b>{{ 'Father Name' | translate }}</b> 
                    <p class="float-right  d-none d-md-block"> {{ profvalue['father_name'] }}</p>
                    <p class="d-block d-md-none"> {{ profvalue['father_name'] }}</p>
                  </li>
                   <li class="list-group-item bg-light">
                    <b>{{ 'Address' | translate }}</b>
                    <p class="float-right d-none d-md-block"> {{ profvalue['address'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['address'] }}</p>
                  </li>
                  <li class="list-group-item bg-light">
                    <b>{{ 'District' | translate }}</b> 
                    <p class="float-right d-none d-md-block"> {{ profvalue['district'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['district'] }}</p>
                  </li>
               
                  <li class="list-group-item bg-light">
                    <b>{{ 'Branch' | translate }}</b> <a class="float-right"></a>
                    <p class="float-right d-none d-md-block"> {{ profvalue['temp_branch'] }}</p>
                       <p class="d-block d-md-none"> {{ profvalue['temp_branch'] }}</p>
                  </li>
                  <li class="list-group-item bg-light">
                    <b>{{ 'Mobile Number' | translate }}</b> <a class="float-right"></a>
                    <p class="float-right d-none d-md-block"> {{ profvalue['mobile_no'] }}</p>
                    <p class="d-block d-md-none"> {{ profvalue['mobile_no'] }}</p>
                  </li>
                 
                  
                   <li class="list-group-item bg-light">
                    <b>{{ 'Email Address' | translate }}</b> 
                    <p class="float-right d-none d-md-block "> {{ profvalue['email_id'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['email_id'] }}</p>
                  </li>
                   <li class="list-group-item bg-light">
                    <b>{{ 'Renews' | translate }}</b> 
                    <p class="float-right d-none d-md-block "> {{ last_date | date  }}</p>
                    <p class="d-block d-md-none"> {{ last_date | date }}</p>
                  </li>
                   <li class="list-group-item bg-light text-center">
                    <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                      [value]="profvalue['mmk_mem_id']" cssClass="userQR" >
                    </ngx-qrcode>
                  </li>
                </ul>
                <a class="btn btn-block bg-darkz" [routerLink]="['/user/proupdate']"    data-toggle="collapse" data-target="#quot" style="color: silver;">{{ 'Update' | translate }}</a>

             
                <!-- /.row -->
              </div>
            </div>
          <!-- <div class="card h-100"  >
            <div class="card-body box-profile"  >
              <div class="text-center secondary-bg">
              <img class="profile-user-img img-fluid img-circle m-4" 
              [src]="imageUrl"
                alt="User profile picture" >
               
              </div>
              <div>
  
                <ul class="list-group list-group-unbordered mb-3">
                  <li class="list-group-item">
                    <b>{{ 'Name' | translate }}</b>
                   <p class="float-right  d-none d-md-block"> {{ profvalue['name'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['name'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'Father Name' | translate }}</b> 
                    <p class="float-right  d-none d-md-block"> {{ profvalue['father_name'] }}</p>
                    <p class="d-block d-md-none"> {{ profvalue['father_name'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'Gender' | translate }}</b> 
                    <p class="float-right d-none d-md-block"> {{ profvalue['gender'] }}</p>
                    <p class="d-block d-md-none"> {{ profvalue['gender'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'DOB' | translate }}</b> 
                    <p class="float-right  d-none d-md-block"> {{ profvalue['dob'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['dob'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'Blood Group' | translate }}</b> 
                    <p class="float-right d-none d-md-block"> {{ profvalue['blood_group'] }}</p>
                       <p class="d-block d-md-none"> {{ profvalue['blood_group'] }}</p>
                  </li>
                  </ul>
                  </div>
              
              <a class="btn btn-block bg-darkz" [routerLink]="['/user/proupdate']"    data-toggle="collapse" data-target="#quot" style="color: silver;">{{ 'Update' | translate }}</a>

             
            </div>
          </div> -->
        </div>
          <!-- /.card -->

          <!-- <div class="col-12 col-md-6 xs-mt-2" >
          <div class="card h-100"  >
            <div class="card-header secondary-bg
            " style="display: flex;">
              <h3 class="card-title ">{{ 'Address' | translate }}</h3>
              <div style="flex: 1;"></div>
              
            </div>
            <div class="card-body" >
              <div>

                <ul class="list-group list-group-unbordered mb-3">
        
                  <li class="list-group-item">
                    <b>{{ 'Address' | translate }}</b>
                    <p class="float-right d-none d-md-block"> {{ profvalue['address'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['address'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'District' | translate }}</b> 
                    <p class="float-right d-none d-md-block"> {{ profvalue['district'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['district'] }}</p>
                  </li>
               
                  <li class="list-group-item">
                    <b>{{ 'Branch' | translate }}</b> <a class="float-right"></a>
                    <p class="float-right d-none d-md-block"> {{ profvalue['temp_branch'] }}</p>
                       <p class="d-block d-md-none"> {{ profvalue['temp_branch'] }}</p>
                  </li>
                     <li class="list-group-item">
                    <b>{{ 'Ward' | translate }}</b> 
                    <p class="float-right d-none d-md-block"> {{ profvalue['ward'] }}</p>
                       <p class="d-block d-md-none"> {{ profvalue['ward'] }}</p>
                  </li>
                  <li class="list-group-item">
                    <b>{{ 'Mobile Number' | translate }}</b> <a class="float-right"></a>
                    <p class="float-right d-none d-md-block"> {{ profvalue['mobile_no'] }}</p>
                    <p class="d-block d-md-none"> {{ profvalue['mobile_no'] }}</p>
                  </li>
                 
                  
                   <li class="list-group-item">
                    <b>{{ 'Email Address' | translate }}</b> 
                    <p class="float-right d-none d-md-block "> {{ profvalue['email_id'] }}</p>
                     <p class="d-block d-md-none"> {{ profvalue['email_id'] }}</p>
                  </li>
                   <li class="list-group-item">
                    <b>{{ 'Renews' | translate }}</b> 
                    <p class="float-right d-none d-md-block "> {{ last_date | date  }}</p>
                    <p class="d-block d-md-none"> {{ last_date | date }}</p>
                  </li>
                  
                </ul>
              </div>

              <div>
                <strong><i class="fas fa-phone mr-3"></i> CONTACT DETAILS</strong>

              
              </div>

            </div>
          </div>
        </div> -->
        <!-- /.col -->
       
        <!-- /.col -->
      </div>

      <ng-template #load class="loadd">
        Loading.. <span class="d">.</span><span class="d d-2">.</span><span class="d d-3">.</span>

      </ng-template>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>

 

