import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-admindash',
  templateUrl: './admindash.component.html',
  styleUrls: ['./admindash.component.css']
})
export class AdmindashComponent implements OnInit {

  att:string;
  profvalue:any;
  appp:number;
  rej:number;
  napp:number;
  tot:number;
  totd:number;
  amount:number;
  not:number;


  constructor(private api: ApiService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getDatatoday()
    this.getDataapp()
    this.getDatanotapp()
    this.getDatarej()
    this.getamount()
    this.getnot()
    this.getDatatot()
  }


  getDataapp(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{status: 'Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{status: 'Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.appp = Object.keys(this.profvalue).length;
          
          this.spinner.hide();
        })
      }
    })
  }

  getDatanotapp(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{status: 'Not_Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{status: 'Not_Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.napp = Object.keys(this.profvalue).length;
          
          this.spinner.hide();
        })
      }
    })
  }


  getDatarej(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{status: 'Rejected',filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{status: 'Rejected',filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.rej = Object.keys(this.profvalue).length;
         
          this.spinner.hide();
        })
      }
    })
  }


  getDatatot(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{ method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{ method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.tot = Object.keys(this.profvalue).length;
         
          this.spinner.hide();
        })
      }
    })
  }


  getamount(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{ status: 'Paid',method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{ status: 'Paid',method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.amount = Object.keys(this.profvalue).length * 20;
          this.amount - 20;
        
          this.spinner.hide();
        })
      }
    })

  }

  getnot(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    this.api.serviceData(this.att,{ status: 'Not_Paid',method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
        this.api.serviceData(this.att,{ status: 'Not_Paid',method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.not = Object.keys(this.profvalue).length;
          this.spinner.hide();
        })
      }
    })

  }

  getDatatoday(){
    let  fval:any;
    this.att = 'CommonMethods';
    this.spinner.show();
    var today = new Date() ;
    let tomorrow = new Date(today)
     tomorrow.setDate(tomorrow.getDate() + 1)
    let to :string;
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
   
    to =  yyyy +'-'+ mm+'-'+dd;
   

    let to1 :string;
    var dd1 = String(tomorrow.getDate()).padStart(2, '0');
    var mm1 = String(tomorrow.getMonth() + 1).padStart(2, '0'); 
    var yyyy1 = tomorrow.getFullYear();
    to1 =  yyyy1 +'-'+ mm1 +'-'+ dd1  ;
  

   
    this.api.serviceData(this.att,{start_date: to,end_date: to1,filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      this.profvalue = d['data']
      this.totd = Object.keys(this.profvalue).length;
     
      this.spinner.hide();
      // if(fval){
      //   this.api.serviceData(this.att,{start_date: to,end_date: to1,filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(d=>{
      //     this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          
         
          
      //   })
      // }
    })
  }


}
