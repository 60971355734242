<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-spin" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- Content Header (Page header) -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">

        <h1 class="m-0">{{ 'Role' | translate }}</h1>
      </div><!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']" >Home</a></li>
          <li class="breadcrumb-item"> {{ 'Master Data' | translate }}</li>
          <li class="breadcrumb-item active">{{ 'Role' | translate }}</li>
        </ol>
      </div><!-- /.col -->
    </div><!-- /.row -->
  </div><!-- /.container-fluid -->
</div>
<section class="content">
  <div class="contanier-fluid m-2">
    <section class="card p-4">
      <div class="row justify-content-center ">
        <div class="col-12 col-md-6">
          <form [formGroup]="dismasForm" (ngSubmit)="add(dismasForm.value)">
            <div class="form-group">
              <label for="name">{{ 'Role' | translate }}</label>
              <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ 'Role is required' | translate }}</div>
              </div>
            </div>
            <div class="form-group" *ngIf="f.id.value">
              <label for="status">{{ 'Status' | translate }}</label>
              <input type="text" formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.status.errors }" />
              <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">{{ 'Status is required ' | translate }}</div>
              </div>
            </div>

            <div class="form-group  text-right">
              <div *ngIf="!f.id.value; else elseBlock">
                <input class="btn btn-lg  bg-darkz   mt-3" type="submit" style="color:white;"
                  [disabled]="!f.name.value || !f.status.value" value="{{ 'Add' | translate }}">
              </div>
              <ng-template #elseBlock>
                <input class="btn btn-lg  bg-darkz mt-3 " type="submit" style="color:white;"
                  [disabled]="!f.name.value || !f.status.value" value="{{ 'Update' | translate }}">
              </ng-template>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section class="card p-1">



      <div class="row m-2">
        <div class="col-xs-12 col-md-3 offset-md-10 " style="padding-bottom: 2%;">
          <input id="filter" class="form-control" type="text" (keyup)="doFilter($event.target.value)"
            placeholder="{{ 'Search' | translate}}">
        </div>
        <div class="col-12">
          <table mat-table [dataSource]="datasource" matSort *ngIf="datasource; else load">
            <ng-container matColumnDef="user_prev_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{element.user_prev_name }} </td>
            </ng-container>

            <ng-container matColumnDef="user_prev_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Authority Level' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{element.user_prev_id }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.status | translate }} </td>
            </ng-container>

            <ng-container matColumnDef="Edit">
              <th mat-header-cell *matHeaderCellDef>{{ 'Edit' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button (click)="edit(element)"><mat-icon>edit</mat-icon>  {{ 'Edit' | translate }}</button>
                <!-- <a mat-raised-button [routerLink]="['/edit/${element.district_id}']" >Edit</a> -->
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <ng-template #load>
            <div class="row text-center" style="align-items: center;">
              <div class="col-12">
                <h1>{{ 'No Data Available' | translate }}</h1>

              </div>

            </div>
          </ng-template>
          <mat-paginator [pageSizeOptions]="[5, 10, 15,20,25,30]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </section>
  </div>
</section>
