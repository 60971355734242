<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="content">
    
          <mat-tab-group >
                    <mat-tab label="{{ 'District' | translate }}">
                    <ng-template matTabContent>
                     <app-dist-mas></app-dist-mas>
                    </ng-template>
                       
                    </mat-tab>
                    <mat-tab label="{{ 'TMMK-District' | translate }}">
                                  <ng-template matTabContent>
                                    <app-mmk-mas></app-mmk-mas>
                    </ng-template>
                      
                          </mat-tab>
                    <mat-tab label="{{ 'Category' | translate }}">
                                  <ng-template matTabContent>
                                   <app-branch-mas></app-branch-mas>
                    </ng-template>
                           
                    </mat-tab>
                     <mat-tab label="{{ 'Ward' | translate }}">
                                   <ng-template matTabContent>
                                    <app-ward-mas></app-ward-mas>
                    </ng-template>
                           
                    </mat-tab>

                     <mat-tab label="{{ 'Role' | translate }}">
                                   <ng-template matTabContent>
                                      <app-role-mas></app-role-mas>
                    </ng-template>
                         
                    </mat-tab>
         </mat-tab-group>

       
</div>
