import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../Service/api.service';

@Component({
  selector: 'css-otp-model',
  templateUrl: './otp-model.component.html',
  styleUrls: ['./otp-model.component.css']
})
export class OtpModelComponent implements OnInit {

  att: string;
  mobileNum: string;

  vrfyform: FormGroup;
  constructor(private formBuilder: FormBuilder,private api: ApiService,private modalService: NgbModal,private router: Router) { }

  ngOnInit(): void {
   
    this.mobileNum = this.api.SharedData;
  
    this.vrfyform = this.formBuilder.group({
      mobile_number: new FormControl(this.mobileNum,Validators.required),
      otp: new FormControl('',Validators.required),
      method: new FormControl('verifyotp',Validators.required)
  
    })
  }

  vrfyotp(value){

     this.att = 'CommonMethods';
     this.api.serviceData(this.att,value,'commonMethod','verifyotp').subscribe(d =>{
    
       if(d['data']['status'] === 'Success'){
         this.modalService.dismissAll()
         this.router.navigateByUrl('/mmk/register')
       }
      
     })
   }

   get f() { return this.vrfyform.controls; }

}
