
   <div class="footer bg-darkz " style="padding-bottom: 4%;">
        <div class="row">

          <div class="col-3">
           <a class=" text-light" routerLink="https://www.facebook.com/mmkhqofficial/" target="_blank" >&nbsp; &nbsp;<i class="fab  fa-facebook-f fa-2x"></i></a>
           &nbsp; &nbsp;
           <a class=" text-light" routerLink="https://twitter.com/Mmk_itwing?s=09" target="_blank">&nbsp; &nbsp;<i  class="fab fa-twitter fa-2x"></i></a>
           
          </div>
          <div class="col-3">
            <p>{{ 'Contact' | translate }}</p>
            <p>{{ 'Mobile Number' | translate }} : <B>9095585988</B> </p>  
          </div>
          <div class="col-3">
            <a class=" text-light">&#169;2021 MMK</a>
          </div>
          <div class="col-3">
            Powered By <a class="text-light" routerLink="https://www.fasaha.net" target="_blank"> Fasaha</a>
            </div>
         
         
        </div>
      
   </div>