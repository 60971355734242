<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white">
    {{ 'Loading...' | translate }} </p>
</ngx-spinner>
<section class="content-header">
  
  </section>
<section class="content ">
    <div class="container-fluid">
<section class="card shadow">
  <h3 class="font-weight-bold p-2 text-center ">{{ 'MMK Membership Card' | translate }}</h3>
  <div id="contentToConvert" #screen
    style="height: 100%;width: fit-content;transform: translate(-50%,5%);left: 50%;position: relative;top:50%;">
    <div id="printsection">
      <section #screen id="printsection1"
        style="background-image: url('../../../assets/images/mmkidback.jpg'); background-size: 86mm 54mm;background-repeat: no-repeat;position: relative;height:54mm;width: 86mm;"
        *ngIf="profvalue">
        <img style="position: absolute;margin-top: 29%;left: 74.5%; border-radius: 5%;object-fit: cover;"
          [src]="this.imageUrl" width="65" height="78">
        <span
          style="position: absolute;margin-top: 28.5%;left: 25%;font-size: 8px;font-weight: 600;font-weight: 900 !important;width: 160px;word-wrap: break-word;line-height: 12px;text-align: start;">{{
          profvalue['name'] }}</span>
        <span
          style="position: absolute;margin-top: 35.5%;left: 25%;font-size: 8px;font-weight: 600;font-weight: 900 !important;width: 160px;word-wrap: break-word;line-height: 12px;text-align: start;">{{
          profvalue['father_name'] }}</span>
        <span
          style="position: absolute;margin-top: 42%;left: 25%;font-size: 8px;font-weight: 600;font-weight: 900 !important;">{{
          profvalue['member_reg_id'] }}</span>
        <span *ngIf="age"
          style="position: absolute;margin-top: 49.5%;left: 25%;font-size: 8px;font-weight: 900 !important;">{{ age
          }}</span>
        <span style="position: absolute;margin-top: 49%;left: 55%;font-size: 8px;font-weight: 900 !important;">{{
          profvalue['blood_group'] }}</span>
      </section>
      <!-- <img id="i1"  src=""> -->
      <section #screen1 id="printsection2" class="page-break"
        style="background-image: url('../../../assets/images/mmkidfront.jpg');  background-size: 86mm 54mm;background-repeat: no-repeat;position: relative;height:54mm;width: 86mm;"
        *ngIf="profvalue">
        <span
          style="position: absolute;margin-top: 19%;left: 26%;font-size: 8px;font-weight: 900 !important;width: 150px;word-wrap: break-word;line-height: 8px;text-align: start;">{{
          profvalue['temp_branch'] }}</span>
        <span
          style="position: absolute;margin-top: 27%;left: 26%;font-size: 8px;font-weight: 900 !important;width: 150px;word-wrap: break-word;line-height: 8px;text-align: start;">{{
          profvalue['tmmk_dist'] }}</span>
        <span
          style="position: absolute;margin-top: 36%;left: 26%;font-size: 8px;font-weight: 900 !important;width: 150px;word-wrap: break-word;line-height: 8px;text-align: start;">{{
          profvalue['address'] }}</span>
        <span
          style="position: absolute;margin-top: 44.5%;left: 26%;font-size: 9px;font-weight: 900 !important;width: 150px;word-wrap: break-word;line-height: 8px;text-align: start;">{{
          last_date | date }}</span>
        <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
          [value]="profvalue['mmk_mem_id']" cssClass="bshadow" style="position: absolute;margin-top: 22%;left: 72.5%;">
        </ngx-qrcode>

      </section>
    </div>
  </div>
  <div class="d-flex justify-content-center p-2 " style="margin-top: 9%;">
    <div class="row text-center">
      <div class="col-12">
        <button class="btn btn-secondary " id="content" #content (click)="captureScreen()">
          {{ 'Download PDF' | translate }}
        </button>
      </div>
      <!-- <div class="col-12 col-md-6">
        <button class="btn btn-secondary" (click)="downloadImage()">
          {{ 'Download Image' | translate }}
        </button>
      </div> -->
    </div>


  </div>
  &nbsp;
  <div id="download" style="display: none;">
    <img #canvas>
    <a #downloadLink></a>
  </div>
</section>
</div>
</section>



