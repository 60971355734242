import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { ArchivesComponent } from './Admin/archives/archives.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { MasterComponent } from './Admin/master/master.component';
import { ProfileActionComponent } from './Admin/profile-action/profile-action.component';
import { RegistrationsComponent } from './Admin/registrations/registrations.component';
import { AuthGuard } from './auth.guard';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { AdminlayComponent } from './Layout/adminlay/adminlay.component';
import { DashlayComponent } from './Layout/dashlay/dashlay.component';
import { IndexlayComponent } from './Layout/indexlay/indexlay.component';
import { LoginComponent } from './login/login.component';
import { MemcardComponent } from './memcard/memcard.component';
import { OtpModelComponent } from './otp-model/otp-model.component';
import { OtpComponent } from './otp/otp.component';
import { PassresetComponent } from './passreset/passreset.component';
import { PayModelComponent } from './pay-model/pay-model.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { Role } from './Shared/role';
import { UserdashComponent } from './userdash/userdash.component';
import { UserforgetpassComponent } from './userforgetpass/userforgetpass.component';
import { UserprofupComponent } from './userprofup/userprofup.component';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
import { QRprofileComponent } from './qrprofile/qrprofile.component';
import { AdmindashComponent } from './Admin/admindash/admindash.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DistMasComponent } from './Admin/dist-mas/dist-mas.component';
import { MmkMasComponent } from './Admin/mmk-mas/mmk-mas.component';
import { BranchMasComponent } from './Admin/branch-mas/branch-mas.component';
import { WardMasComponent } from './Admin/ward-mas/ward-mas.component';
import { RoleMasComponent } from './Admin/role-mas/role-mas.component';



const routes: Routes = [
      {
       path: 'login',
       component: LoginComponent
     },
     {
      path: 'register',
      component: RegisterComponent
    },
    {
    path: 'mmk',                      
    component: IndexlayComponent,
     
    children: [
     
     {
       path: 'qrprofile',
       component: QRprofileComponent
     },
    

     {
       path: 'paysuccess',
       component: PaysuccessComponent
     },
     {
       path: 'forgetpassword',
       component: ForgetpassComponent
     },
     {
      path: 'passwordreset',
      component: UserforgetpassComponent
    },
     {
      path: 'payment',
      component: PayModelComponent
    },
     {
       path: 'otpverify',
       component: OtpModelComponent
     },

     
    {
      path: 'otp',
      component: OtpComponent
    },
    // {
    //   path: 'adminlogin',
    //   component: AdminLoginComponent
    // },


    ]
  },
  {
    path: 'user',                      
    component: DashlayComponent,
    canActivate: [AuthGuard],
    data: { roles: Role.User },
     
    children: [
     {
       path: 'profile',
       component: ProfileComponent
     },
     {
      path: 'proupdate',
      component: UserprofupComponent
     },
     {
       path: 'dashboard',
       component: UserdashComponent
     },
     {
      path: 'passreset',
      component: PassresetComponent
    },

     {
      path: 'memcard',
      component: MemcardComponent
    },
    ]
  },
  {
    path: 'admin',                      
    component: AdminlayComponent,
    canActivate: [AuthGuard],
    data: { roles: Role.Admin },

     
    children: [
      {
        path: 'home',
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        component: AdmindashComponent
      },
     {
       path: 'registers',
       component: RegistrationsComponent
     },

     {
      path: 'archives',
      component: ArchivesComponent
    },

    {
      path: 'profdownload',
      component: ProfileActionComponent
    },
    {
      path: 'adduser',
      component: ProfileActionComponent
    },
    {
      path: 'master',
      component: MasterComponent
    },
      {
        path: 'district',
        component: DistMasComponent
      },
      {
        path: 'mmkdist',
        component: MmkMasComponent
      },
      {
        path: 'category',
        component: BranchMasComponent
      },
      {
        path: 'ward',
        component: WardMasComponent
      },
      {
        path: 'role',
        component: RoleMasComponent
      },


    ]
  },

  

  {path: '', redirectTo:'/login',pathMatch:'full'},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
