<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > 
{{ 'Loading...' | translate }} </p></ngx-spinner>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ng-template #sDialog >
        <h4 matDialogTitle> {{ 'Profile' | translate }}</h4>
        <div class="mt-2"></div>
        <p class="text-center" matDialogContent><em>{{ 'Profile Updated Successfully' | translate }} </em></p>
        <div class="mt-2 text-center">
          <i class="fa fa-thumbs-up fa-3x"></i>
        </div>
      </ng-template>

      </div>
</div>
    <div class="row">
      
            <div class="col-12 col-md-8 col-lg-8 mx-auto">
              <div class="card bg-gradient-light mt-2">
                <h4 class=" card-header bg-gradient-darkz text-center">{{ 'Profile Update' | translate }}</h4>
                <div class="card-body">
            <form [formGroup]="upForm" *ngIf="upForm" (ngSubmit)="update(upForm.value)">
              <div class="form-group">
                <label for="name">{{ 'Name' | translate }}</label>
                <input type="text" id="name"  class="form-control"  formControlName="name" required>
                <!-- <div *ngIf="f.name.invalid && f.name.dirty || f.name.touched" class="alert alert-danger">
                  <div *ngIf="f.name.errors.required">
                     {{ 'Name is required' | translate }}
                  </div>
                  <div *ngIf="f.name.errors.minlength">
                     {{ 'Name must be at least 2 characters long' | translate }}
                  </div>
                  <div *ngIf="f.name.errors.maxlength">
                    {{ 'Name can be 30 characters long' | translate }}
                  </div>
                </div> -->
              </div>
    
              <div class="form-label-group" style="margin-top: 20px;">
                <label for="father_name">{{ 'Father Name' | translate }}</label>
                <input type="text" id="father_name" class="form-control" formControlName="father_name" required>
                <!-- <div *ngIf="f.father_name.invalid && f.father_name.dirty || f.father_name.touched" class="alert alert-danger">
                  <div *ngIf="f.father_name.errors.required">
                     {{ 'Father name is required' | translate }}
                  </div>
                  <div *ngIf="f.father_name.errors.minlength">
                     {{ 'Father name must be at least 2 characters long' | translate }}
                  </div>
                  <div *ngIf="f.father_name.errors.maxlength">
                     {{ 'Father name can be 30 characters long' | translate }}
                  </div>
                </div> -->
              </div>
           
              <div class="form-label-group"  style="margin-top: 20px;">
                <label for="gender"> {{ 'Gender' | translate }}</label>
                <div class="row">
                    <div class="col-6">
                      <label for="male"> <input id="male" type="radio" value="MALE" name="gender" formControlName="gender">
                        {{ 'Male' | translate }} </label>
                    </div>
                  
                  <div class="col-6">
                    <label for="female"><input id="female" type="radio" value="FEMALE" name="gender" formControlName="gender"> 
                    {{ 'Female' | translate }}</label> 
                </div>
               </div>      
            </div>
            
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="dob">{{ 'DOB' | translate }}</label>
              <input type="date" id="dob"  class="form-control" formControlName="dob" required>
              <!-- <div *ngIf="f.dob.invalid && f.dob.dirty || f.dob.touched" class="alert alert-danger">
                <div *ngIf="f.dob.errors.required">
                   {{ 'Date of Birth  is required' | translate }}
                </div>
                </div> -->
            </div>
    
              
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="email_id">{{ 'Email Address' | translate }}</label>
              <input type="email_id" id="email_id"    class="form-control" formControlName="email_id" required>
             
            </div>

            <div class="form-label-group" style="margin-top: 20px;">
              <label for="address">{{ 'Address' | translate }}</label>
              <textarea type="textarea" id="address"   class="form-control" formControlName="address" required ></textarea>
            
            </div>

            <div class="form-label-group" style="margin-top: 20px;">
              <label for="pincode">{{ 'Pincode' | translate }}</label>
              <input type="number" id="pincode" name="pincode" pattern="[0-9]{4}" minlength = '6' placeholder="600001" required  class="form-control" formControlName="pincode">
             
            </div>
<!-- 
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="district_id">{{ 'District' | translate }}</label>
              <select   onfocus='this.size=dlen;'  class="form-control" formControlName="district_id" (change) ="getmmkdistrict(f.district_id.value)"    >
                <option [ngValue]="null" disabled>{{ 'Select District' | translate }}</option>
                <option *ngFor="let dis of districts" [value]="dis.dist_id"  [selected]="dis.dist_id === f.district_id.value"  >{{dis.district_name}}</option>
            </select>
      
             
            </div> -->

            <!-- <div class="form-label-group" style="margin-top: 20px;">
              <label for="tmmk_dist_id">{{ 'TMMK-District' | translate }}</label>
              <div *ngIf="tmmk_dist; else el">
              <select  onfocus='this.size=tdlen;' class="form-control" formControlName="tmmk_dist_id"  >
                <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id" [selected]="dis.tmmk_dist_id === f.tmmk_dist_id.value" >{{dis.tmmk_district_name}}</option>
            </select>
          
            </div>
            <ng-template #el>
              <div>
                <h5>{{ 'Select District' | translate }}</h5> 
              </div>
             
            </ng-template>
            
            </div> -->

            <!-- <div class="form-label-group" style="margin-top: 20px;">
              <label for="temp_branch">{{ 'Branch' | translate }}</label>
    
              <input type="temp_branch" id="temp_branch"    class="form-control" formControlName="temp_branch" >
             
                </div> -->

            <!-- <div class="form-label-group" style="margin-top: 20px;">
              <label for="constituency">{{ 'constituency' | translate }}</label>
              <select   onfocus='this.size=clen;'  class="form-control" formControlName="constituency" >
                <option [ngValue]="null" disabled>{{ 'Select constituency' | translate }}</option>
                <option *ngFor="let dis of constituencies" [value]="dis.constituency_id"  [selected]="dis.constituency_id === f.constituency.value"  >{{dis.constituency_name}}</option>
            </select>
           
             
            </div> -->

            
    
                     <!-- Upload image -->

                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="img">{{ 'Profile Image' | translate }}</label>
                    <div class="group-gap">
    
                        
                      <div class="avatar-upload" (click)="fileInput.click()">
                        <div class="avatar-edit">
                            <input type='file' id="imageUpload" aaccept="image/*;capture=camera" #fileInput   (change)="uploadFile($event)" />
                          </div>
                        <div class="avatar-preview" *ngIf="imageUrl">
                          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                          </div>
                        </div>
                       
                    </div>
                </div>
                    
                 
    
                 
                  </div>
    
    
                <input class="btn btn-lg  bg-darkz btn-info btn-block  mt-3" type="submit" style="color:white;"  [disabled]="upForm.valid" value="Update">
                
    
                <!-- <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div> -->
              </form>
    
        </div>
    </div>
</div>
</div>
</div>
