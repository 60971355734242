<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="card-body">
            <img src="../../assets/images/flag.jpg" style="opacity: 0.1;" alt="login" class="login-card-img">
            <div class="card-header ">
              <h2 class="card-title">{{ 'Payment Terms' | translate }}</h2>
              <div class="brand-wrapper text-right">
                {{ 'Language' | translate }} :
                <select #langSelect (change)="changeLang(langSelect.value)"
                  style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

                  <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                    [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
                </select>
              </div>
            </div>
            <div class="card-body" style="text-align: left;">
              <h4 style="font-size: 16px;"><b>{{ 'pt1' | translate }}</b></h4>
              <br>
              <h4 style="font-size: 16px;"><b>{{ 'pt2' | translate }}</b></h4>
              <br>
              <h4 style="font-size: 16px;"><b>{{ 'pt3' | translate }}</b></h4>
            </div>
            <div class="card-footer bg-gradient-light">
              <div class="row">
                <div class="col-12 text-right ">
                  <div class="form-group">
                    <!-- <a  href="https://members.mmkinfo.in/production//IFRAME_KIT/dataFrom.php?order_id=9362121822">{{ 'Pay Now' | translate }} </a> -->
                    <button type="button" class="btn btn-secondary " (click)="submitForm()">{{ 'Pay Now' | translate
                      }}</button>
                    <button type="button" class="btn btn-light ml-1" (click)="Cancel()">{{ 'Pay Later' | translate
                      }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>



<app-lfooter></app-lfooter>
