import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { map, mergeMapTo } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../Service/api.service';
import { AuthenticationService } from '../Service/authentication.service';
import { baseURL } from '../Shared/baseurl';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  prof: any;
  profvalue: Object;
  att: string;
  pp: any;
  imageUrl:string;
  userdata: any;
  last_date: any;


  constructor(private formBuilder: FormBuilder,private router: Router,
    private authservice: AuthenticationService,
    private api: ApiService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
   
    this.getpro()
    if(screen.width<=480){
      document.body.className += 'sidebar-closed sidebar-collapse';
  
    }

    
   
    

  }

  getpro(){
    this.spinner.show()
    this.att = 'commonMethods';
    this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_registration').subscribe(p=>{
      this.profvalue = p['data'][0]
      this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_account').subscribe(d=>{
        this.profvalue = Object.assign(this.profvalue,d['data'][0]);

  
        console.log(this.profvalue)
        this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path']
        var year = new Date(this.profvalue['approve_one_date']).getFullYear();
        var month = new Date(this.profvalue['approve_one_date']).getMonth();
        var day = new Date(this.profvalue['approve_one_date']).getDate();
        this.last_date = new Date(year + 2, month,day)
        console.log(this.last_date) 
        this.spinner.hide() 
       
      })
     
      
  })  
}

}
