import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { baseURL } from '../Shared/baseurl';
import { res } from '../Shared/res';
import { User } from '../Shared/User';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private loggedIn = new BehaviorSubject<boolean>(false); 
  private uid: string = null;
  private up: string = null;
  private token: string = null;


  public SharedData: string;

  public accountDet: Object;

  public payurl: string;


  public payurlper: string;

 

  public profDet: Object;

  datastringfy: string;



  get isLoggedIn() {
    return this.loggedIn.asObservable(); 
  }

  constructor(private httpClient: HttpClient, private router: Router) { }

  serviceData(att: string,data:any,actionModule:any, action:any): Observable<res>{
    const httpOptions = {
      headers: new HttpHeaders({
      })
    };
    const formData = new FormData();
    formData.append("reqData",btoa(btoa(btoa(unescape(encodeURIComponent(JSON.stringify({data,actionModule,action})))))))
    return this.httpClient.post<res>(`${baseURL.apiUrl}`+ att,formData)
  }
  
  login(att: string,data:any,actionModule:any, action:any,user:string):Observable<res>{
    const httpOptions = {
      headers: new HttpHeaders({
      })
    };
    const formData = new FormData();
    formData.append("reqData",btoa(btoa(btoa((JSON.stringify({data,actionModule,action}))))));
    return this.httpClient.post<res>(`${baseURL.apiUrl}`+ att,formData,httpOptions).pipe(
      tap(res =>{

        this.setDataAfterLogin(data,user);
        this.loggedIn.next(true);
      }),
      catchError(this.handleError
     ));
  }
  logout(att: string,data:any,actionModule:any, action:any):Observable<res>{
    const httpOptions = {
      headers: new HttpHeaders({
      })
    };
    const formData = new FormData();
    formData.append("reqData",btoa(btoa(btoa((JSON.stringify({data,actionModule,action}))))));
    return this.httpClient.post<res>(`${baseURL.apiUrl}`+ att,formData,httpOptions).pipe(
      tap(res =>{
         this.token = null;
         sessionStorage.clear();
         this.loggedIn.next(false);
         this.router.navigate(['/login']);
      }),
      retry(2),
    catchError(this.handleError));
   
  }
  
  resolveToken(): boolean {
    this.token = sessionStorage.getItem('token');
    this.loggedIn.next(this.token ?  true : false);
    return this.token ? true : false;
  }


  getToken(): string {
    return this.token;
  }

  hasToken(): boolean  {
    return this.getToken() ? true : false;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  private setDataAfterLogin(data,user) {
    this.token  = data['token'];
    // store some user data in the service
    this.uid  = data['mobile'];
    this.up = data['password']
    // store some data in local storage (webbrowser)
    sessionStorage.setItem('user',JSON.stringify(data))
    sessionStorage.setItem('role',user)
    sessionStorage.setItem('token' , this.token);
    sessionStorage.setItem('userid' , JSON.stringify(this.uid).replace('"','').replace('"',''));
    sessionStorage.setItem('userpass' , JSON.stringify(this.up).replace('"','').replace('"',''));
  }


}
