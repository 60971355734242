<footer class="footer  pt-3 pb-3 mr-4 ml-4 rounded-top"
  style="border-top:5px solid rgba(105, 105, 105, 0.774); background: linear-gradient(130deg,#000000,#adabac 100%,#ffffff 76.05%);">
  <div class="row text-center">
    <div class="col-md-3 col-12">
      <a href="https://www.facebook.com/mmkhqofficial/"><i class="fa fa-facebook p-2" style="font-size: 22px;"></i></a>
      <a href="https://twitter.com/jawahirullah_MH"><i class="fa fa-twitter p-2" style="font-size: 22px;"></i></a>
      <a href="https://www.youtube.com/channel/UCGG2KSgf74oZQWTV_6IeqNQ"><i class="fa fa-youtube p-2"
          style="font-size: 22px;"></i></a>

    </div>
    <div class="col-md-6 col-12 ">
      <span class="text-light ml-2 ">© COPYRIGHT - MANITHANEYA MAKKAL KATCHI | POWERED BY FASAHA</span>
    </div>
    <div class="col-md-3 col-12 ">
      <span class="text-light ml-2">
        {{ 'Mobile Number' | translate }} : <a href="tel:9095585988" class="font-weight-bold"><i
            class="fa fa-phone mr-1"></i>9095585988</a>
      </span>

    </div>
  </div>
</footer>
