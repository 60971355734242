import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';
import { baseURL } from 'src/app/Shared/baseurl';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  att:string;
  profvalue:any;
  app:number;
  rej:number;
  napp:number;
  tot:number;
  totd:number;
  amount:number;


  constructor(private api: ApiService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }


 





}
