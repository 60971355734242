import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Service/api.service';
import { AuthenticationService } from 'src/app/Service/authentication.service';
import { res } from 'src/app/Shared/res';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  @ViewChild('sDialog') sDialog: TemplateRef<any>;

  logForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  error = '';
  logd: any;
  att: string;


  openOtherDialog() {
  
    this.dialog.open(this.sDialog,{
      panelClass: 'my-classc'
    });
   
  }

  constructor(
    private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private api: ApiService,
        private dialog: MatDialog
        
  ) { }


  login(value:res){
    this.submitted = true;
    if(this.logForm.invalid){
      return;
    }
    this.loading = true;
    this.att = 'UserDetail';
    this.authenticationService.login(value,'Login','login').subscribe((d:any)=>{
      if(d['data']['status'] === 'Success'){
        this.openOtherDialog();
        setTimeout(()=>{
          this.dialog.closeAll()
          this.router.navigateByUrl('/admin/registers')
        }, 2500);
      }
      // else if(d['data']['msg'] != ''){

      // }

      else{
        this.error = 'Invalid Credentails'
      }   
    })
    
  }

  ngOnInit(): void {
    this.logForm = this.formBuilder.group({
      mobile: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      method: new FormControl('login'),
      role: new FormControl('admin',Validators.required)

      // rememberMe: new FormControl(false)
  });
  }

  get f() { return this.logForm.controls; }

}
