import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { translate } from '@angular/localize/src/utils';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../Service/api.service';
import { AuthenticationService } from '../Service/authentication.service';
import { SnackbarService } from '../Service/snackbar.service';

@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {

  otpForm: FormGroup;
  submitted = false;
  loading = false;
  ccdvalue: string;
  error = '';
  closeResult: any;

  att: string;

  vrfyform: FormGroup;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router,
    public translate: TranslateService, private authenticationService: AuthenticationService,
    private sb: SnackbarService) {
    translate.addLangs(['English', 'தமிழ்']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/) ? browserLang : 'தமிழ்');

    }
    else {
      localStorage.setItem('locale', 'தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['user/profile'])
    }


  }

  ngOnInit() {

    this.otpForm = this.formBuilder.group({
      mobile_no: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(12)]),
      mail_id: new FormControl('', [Validators.required, Validators.email]),
      method: new FormControl('forgot_password', Validators.required),

    });
  }
  sendotp(value) {
    this.att = 'UserDetail';
    this.api.serviceData(this.att, value, 'forgot_password', 'forgot_password').subscribe((d: any) => {
      if (d['data']['status'] === 'Success') {
        this.sb.success(d['data']['msg'])
        this.router.navigateByUrl('/login')
      }
      else {
        this.sb.error(d['data']['msg'])
      }
    })
  }

  get f() { return this.otpForm.controls; }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }


}
