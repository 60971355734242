import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrprofile',
  templateUrl: './qrprofile.component.html',
  styleUrls: ['./qrprofile.component.css']
})
export class QRprofileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
