<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            
            <h1 class="m-0">{{ 'Registrations' | translate }}</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']" >Home</a></li>
              <li class="breadcrumb-item active">{{ 'Registrations' | translate }}</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

<section class="content">
   
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12">
      <form [formGroup]="FilterForm" (ngSubmit)="getfiltervalue(FilterForm.value)" class="card p-4">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="district">{{ 'District' | translate }}</label>
              
              <ng-select *ngIf="dlen"  onfocus='this.size=dlen;'  formControlName="district" (change) ="getmmkdistrict(g.district.value)" placeholder="{{ 'Select District' | translate }}">
                <ng-option *ngFor="let dis of districts" [value]="dis.dist_id" [selected]="dis.dist_id === g.district.value" >{{ dis.district_name  | translate }}</ng-option>
              </ng-select>
              <!-- <select *ngIf="dlen"  onfocus='this.size=dlen;'  class="form-control" formControlName="district" (change) ="getmmkdistrict(g.district.value)">
                <option [ngValue]="null" disabled>{{ 'Select District' | translate }}</option>
                <option *ngFor="let dis of districts" [value]="dis.dist_id" [selected]="dis.dist_id === g.district.value" >{{ dis.district_name  | translate }}</option>
            </select> -->
             
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="tmmk_dist">{{ 'TMMK-District' | translate }}</label>
              <ng-select onfocus='this.size=tdlen;' formControlName="tmmk_dist" placeholder="{{ 'Select TMMK District' | translate }}">
                <ng-option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id" [selected]="dis.tmmk_dist_id === g.tmmk_dist.value" >{{ dis.tmmk_district_name  | translate }}</ng-option>
              </ng-select>
              <!-- <select onfocus='this.size=tdlen;'  class="form-control" formControlName="tmmk_dist" >
                <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id" [selected]="dis.tmmk_dist_id === g.tmmk_dist.value" >{{ dis.tmmk_district_name  | translate }}</option>
            </select> -->
             
            </div>
          </div>
          <!-- <div class="col-md-4 col-12">
          <div class="form-group" style="margin-top: 20px;" >
            <label for="branch_type">{{ 'Branch Type' | translate }}</label>
            <select  class="form-control" formControlName="branch_type" (change) ="getbranch(g.tmmk_dist.value,g.branch_type.value)" >
                <option [ngValue]="null" disabled>{{ 'Select Branch Type' | translate }}</option>
                <option *ngFor="let bt of btype ; let i = index;" value="{{i+1}}"  >{{ bt | translate  }}</option>
            </select>
        </div>
        </div> -->
          
        </div>
        

      <div class="row">
        <!-- <div class="col-md-4 col-12">
            <div class="form-label-group" style="margin-top: 20px;">
              <label for="branch">{{ 'Branch' | translate }}</label>
              <select onfocus='this.size=blen;'  class="form-control" formControlName="branch" (change) ="getward(g.branch.value)">
    
                <option [ngValue]="null" disabled>{{ 'Select Branch' | translate }}</option>
                <option *ngFor="let dis of branch" [value]="dis.branch_id" [selected]="dis.branch_id == g.branch.value" >{{ dis.branch_name  | translate }}</option>
            </select>
            </div>
          </div> -->
        <!-- <div class="col-12 col-md-4">
          <div class="form-label-group" style="margin-top: 20px;">
            <label for="ward">{{ 'Ward' | translate }}</label>
            <select onfocus='this.size=wlen;'  class="form-control" formControlName="ward">
  
              <option [ngValue]="null" disabled>{{ 'Select Ward' | translate }}</option>
              <option *ngFor="let dis of ward" [value]="dis.ward_id" [selected]="dis.ward_id == g.ward.value" >{{ dis.ward_name | translate }}</option>
          </select>
          </div>
  
         
        </div> -->

        

        <!-- <div class="col-12 col-md-6">
          <div class="form-label-group" style="margin-top: 20px;">
            <label for="start_date">{{ 'From Date' | translate }}</label>
            <input type="date"  id="start_date" name="start_date"   class="form-control" formControlName="start_date"  required>
          </div>
        </div>
        
         <div class="col-12 col-md-6">
          <div class="form-label-group" style="margin-top: 20px;">
            <label for="end_date">{{ 'To Date' | translate }}</label>
            <input type="date"  id="end_date" name="end_date"   class="form-control" formControlName="end_date"  required>
          </div>
        </div>  -->
      </div>
       <div class="row justify-content-center mt-4" >
         <div class="col-12 text-right">
         
            
           <button class="btn  bg-darkz mr-2"  type="submit" style="color:white;"><i class=" fas fa-search"></i> {{ 'Search' | translate }}</button>
            <Button class="btn btn-default" id="reset" type="text" (click)="reset()"><i class=" fas fa-sync-alt"></i>  {{ 'Reset' | translate}}</Button> 
         </div>
         
       </div>

       

      </form>
    </div>
  </div>

  <section class="card p-1" *ngIf="profvalue">
      <div class="row m-2 " *ngIf="profvalue.length != 0; else load" >
      <div class="col-md-3 col-12 pb-4 mt-3 offset-md-9">
           <!-- <label for="filter">{{ 'Filter' | translate}}</label>   -->
           <input id="filter" class="form-control" type="text" (keyup)="doFilter($event.target.value)" placeholder="{{ 'Search' | translate}}">  
      </div>
          <div class="col-12"   >
              <table mat-table [dataSource]="datasource"  class="responsive-table " matSort  >
                  <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                      <td mat-cell *matCellDef="let element" > {{ element.name }} </td>
                    </ng-container>
  
             
                    <ng-container matColumnDef="created_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Enrolled On' | translate }}</th>
                      <td mat-cell *matCellDef="let element" > {{ element.created_date }} </td>
                    </ng-container>
  
              
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
  
                      <td mat-cell *matCellDef="let element" >
                        <div *ngIf="element.status === '' ">
                          {{ 'Pending' | translate }}  
                          </div>
                         </td>
                       
                    </ng-container>
  
                    
  
                    <!-- <ng-container matColumnDef="img_path" style="width:50%;" >
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Profile' | translate }}</th>
                      <td mat-cell *matCellDef="let element" > 
                        <div >
                          <img [src]="'http://mmktnelections.com/bckend/assets/'+element.img_path" width="10%" class=" brand-image img-circle elevation-2" alt="User Image">
                        </div>
                       </td>
                    </ng-container> -->
  
                     <ng-container matColumnDef="view" >
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'View' | translate }}</th>
                      <td mat-cell *matCellDef="let element"> 
                        
                          <button mat-raised-button *ngIf="element.payment_status === 'Y'"  (click)="view(vmodel)"  ><mat-icon>visibility</mat-icon> {{ 'View' | translate }}</button>
                          <span class="d-block" *ngIf="element.payment_status === 'N'"  ><i  class="fas fa-exclamation-triangle" style="color: red;"></i>&nbsp;{{ 'Not Paid' | translate }}</span>
                        
                          <ng-template #vmodel let-modal>
                              <div class="modal-header bg-light">
                                <h4 class="modal-title" id="modal-basic-title">{{ 'Member Detail' | translate }}</h4>
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
  
                              <div class="modal-body">
                                <div class="card card-widget widget-user shadow-sm">
                                    <!-- Add the bg color to the header using any of the bg-* classes -->
                                    <div class="widget-user-header bg-darkz">
                                      <div class="widget-user-image">
                                        <img class="profile-user-img img-fluid img-circle elevation-2" [src]="'https://members.mmkinfo.in/production/assets/'+element.img_path" alt="User Profile">
                                      </div>
                                      <!-- /.widget-user-image -->
                                    </div>
                                    <div class="card-footer p-0">
                                      <ul class="nav flex-column">
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Name' | translate }} <span class="float-right ">{{ element.name }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Mobile Number' | translate }} <span class="float-right ">{{ element.mobile_no }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Father Name' | translate }} <span class="float-right ">{{ element.father_name }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Email Address' | translate }} <span class="float-right ">{{ element.email_id }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Blood Group' | translate }} <span class="float-right ">{{ element.blood_group }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Address' | translate }} <span class="float-right ">{{ element.address }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'District' | translate }} <span class="float-right ">{{ element.district }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'TMMK-District' | translate }} <span class="float-right ">{{ element.tmmk_dist }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Branch' | translate }} <span class="float-right ">{{ element.temp_branch }}</span>
                                          </p>
                                        </li>
                                        <li class="nav-item">
                                          <p  class="nav-link">
                                            {{ 'Ward' | translate }} <span class="float-right ">{{ element.ward }}</span>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                              <!-- <div class="card-body bg-gradient-light"  >
                                
                                <div class="row text-center">
                                  <div class="col-4">
                                    <div  class="row" >
                                      <div class="col-12">
                                        <span>{{ 'Name' | translate }}</span>
                                        <P>{{ element.name }}</P>
                                      </div>
                                      <div class="col-12">
                                          <h6>{{ 'Father Name' | translate }}</h6>
                                          <P>{{ element.father_name }}</P>
                                      </div>
                                      <div class="col-6">
                                          <h6>{{ 'Email Address' | translate }}</h6>
                                          <P>{{ element.email_id }}</P>
                                      </div>
                                      <div class="col-6">
                                          <h6>{{ 'Blood Group' | translate }}</h6>
                                          <P>{{ element.blood_group }}</P>
                                      </div>
  
                                      <div class="col-6">
                                          <h6>{{ 'Mobile Number' | translate }}</h6>
                                          <P>{{ element.mobile_no }}</P>
                                      </div>
                                  </div>
                         
                                  </div>
                                  <div class="col-4">
                                    <div class="row text-center">
                                      <div class="col-12">
                                        <div class="image" >
                                          <img [src]="'https://members.mmkinfo.in/production/assets/'+element.img_path" width="50%" class=" brand-image img-circle elevation-2" alt="User Image">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div  class="row" >
                                      <div class="col-12">
                                          <h6>{{ 'Address' | translate }}</h6>
                                          <P> {{ element.address }} </P>
                                      </div>
                                      <div class="col-6">
                                          <h6>{{ 'District' | translate }}</h6>
                                          <P> {{ element.district }}</P>
                                      </div>
                                      <div class="col-6">
                                          <h6>{{ 'TMMK-District' | translate }}</h6>
                                          <P>{{ element.tmmk_dist }}</P>
                                      </div>
                                      <div class="col-6">
                                          <h6>{{ 'Branch' | translate }}</h6>
                                          <P>{{ element.temp_branch }}</P>
                                      </div>
                                      <div class="col-6" *ngIf="element.ward">
                                        <h6>{{ 'Ward' | translate }}</h6>
                                        <P>{{ element.ward }}</P>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                                
                                         
                                  
                              </div> -->
                              </div>
  
                              <div class="modal-footer">
  
                                  <button type="button" class="btn btn-outline-dark"  (click)="accept(element)">{{ 'Accept' | translate }}</button>
  
                                  <button type="button" class="btn btn-outline-dark" (click)="reject(element)">{{ 'Reject' | translate }}</button>
                              </div>
  
                          </ng-template>
                          </td>
                    </ng-container>
  
  
  
                 
            
                      <!-- <ng-container matColumnDef="delete">
                       <th mat-header-cell *matHeaderCellDef>{{ 'Delete' | translate }}</th>
                       <td mat-cell  *matCellDef="let element" > 
                          <button class="bg-red" mat-raised-button  (click)="delete(element)">{{ 'Delete' | translate }}</button>
                       </td>
                    </ng-container> -->
                 
   
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
           
                <mat-paginator [pageSizeOptions]="[5, 10, 15,20,25,30]" showFirstLastButtons></mat-paginator>
          </div>
          
      </div>
      <ng-template #load>
        <div class="row text-center" style="align-items: center;">
          <div class="col-12">
              <h3>{{ 'No Data Available' | translate }}</h3>
          </div>
      </div>
      </ng-template>
  </section>
</div>
</section>