import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../Service/api.service';
import { SnackbarService } from '../Service/snackbar.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  otpForm: FormGroup;
  submitted = false;
  loading = false;
  ccdvalue : string;
  error = '';
  closeResult: any;

  att: string;

  vrfyform: FormGroup;

  constructor(private formBuilder: FormBuilder,private api: ApiService,private modalService: NgbModal,
    private router: Router, private sb: SnackbarService) { }

  ngOnInit() { 
    
    this.otpForm = this.formBuilder.group({
      mobile_number: new FormControl('',[Validators.required,Validators.minLength(10),Validators.maxLength(12)]),
      country_code: new FormControl('+91',Validators.required),
      status: new FormControl("N",Validators.required),
      method: new FormControl('sendotp',Validators.required),
      
      // rememberMe: new FormControl(false)
  }); 
  }

 

  sendotp(value){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,value,'commonMethod','sendotp').subscribe((d:any)=>{

      if(d['data']['status'] === 'Success'){
        this.sb.success(d['data']['msg'])
        this.api.SharedData = value.mobile_number

        this.router.navigateByUrl('/mmk/otpverify')
       
      } 
     
    }) 
  }



  onCountryChange(event){
    this.ccdvalue  = event['dialCode']
    this.otpForm.patchValue({
      country_code: event['dialCode']
    });
   
   


}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


  get f() { return this.otpForm.controls; }


}
