import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { baseURL } from './Shared/baseurl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ApiService } from './Service/api.service';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderComponent } from './Main/header/header.component';
import { IndexlayComponent } from './Layout/indexlay/indexlay.component';
import { DashlayComponent } from './Layout/dashlay/dashlay.component';
import { MemcardComponent } from './memcard/memcard.component';
import { AuthGuard } from './auth.guard';
import {MatIconModule} from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LheaderComponent } from './Main/lheader/lheader.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { RegistrationsComponent } from './Admin/registrations/registrations.component';
import { ArchivesComponent } from './Admin/archives/archives.component';
import { ProfileActionComponent } from './Admin/profile-action/profile-action.component';
import { AdminlayComponent } from './Layout/adminlay/adminlay.component';
import { AdminHeaderComponent } from './Main/admin-header/admin-header.component';
import { MasterComponent } from './Admin/master/master.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DialogBoxComponent } from './Admin/dialog-box/dialog-box.component';
import { UserdashComponent } from './userdash/userdash.component';
import { DistMasComponent } from './Admin/dist-mas/dist-mas.component';
import { MmkMasComponent } from './Admin/mmk-mas/mmk-mas.component';
import { BranchMasComponent } from './Admin/branch-mas/branch-mas.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import {  MatSortModule} from '@angular/material/sort';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {
  MatTabsModule
} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import { OtpComponent } from './otp/otp.component';
import { UserprofupComponent } from './userprofup/userprofup.component';
import { PassresetComponent } from './passreset/passreset.component';
import { UserforgetpassComponent } from './userforgetpass/userforgetpass.component';
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { OtpModelComponent } from './otp-model/otp-model.component';
import { PayModelComponent } from './pay-model/pay-model.component';
import { WardMasComponent } from './Admin/ward-mas/ward-mas.component';
import { RoleMasComponent } from './Admin/role-mas/role-mas.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
import { FooterComponent } from './Main/footer/footer.component';
import { QRprofileComponent } from './qrprofile/qrprofile.component';
import { AdmindashComponent } from './Admin/admindash/admindash.component';
import { NotfoundComponent } from './notfound/notfound.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SnackbarService } from './Service/snackbar.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LfooterComponent } from './Main/lfooter/lfooter.component';
export const createTranslateLoader = (http: HttpClient) =>{
  return new TranslateHttpLoader(http, '/assets/i18n/','.json');
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    HeaderComponent,
    IndexlayComponent,
    DashlayComponent,
    MemcardComponent,
    LheaderComponent,
    DashboardComponent,
    RegistrationsComponent,
    ArchivesComponent,
    ProfileActionComponent,
    AdminlayComponent,
    AdminHeaderComponent,
    MasterComponent,
    DialogBoxComponent,
    UserdashComponent,
    DistMasComponent,
    MmkMasComponent,
    BranchMasComponent,
    OtpComponent,
    UserprofupComponent,
    PassresetComponent,
    UserforgetpassComponent,
    AdminLoginComponent,
    OtpModelComponent,
    PayModelComponent,
    WardMasComponent,
    RoleMasComponent,
    ForgetpassComponent,
    PaysuccessComponent,
    FooterComponent,
    QRprofileComponent,
    AdmindashComponent,
    NotfoundComponent,
    LfooterComponent,
 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule ,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    NgbModule,
    Ng2TelInputModule,
    MatStepperModule,
    NgxSpinnerModule,
    NgxQRCodeModule,
    NgSelectModule,
   
    
   
    TranslateModule.forRoot(
      {
        loader:{
          provide:TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }
    ),
    BrowserAnimationsModule,
    NgbModule

   
  ],
  providers: [
    ApiService,
    SnackbarService,
    {provide : 'BaseURL', useValue: baseURL},
    AuthGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
