import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/Service/api.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { baseURL } from 'src/app/Shared/baseurl';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.css']
})
export class RegistrationsComponent implements OnInit {

  datasource: any;
  FilterForm: FormGroup;
  profvalue:any;
  imageUrl:string;

  
  districts:Object;
  dlen: number;
  branch: Object;
  blen: number
  tmmk_dist:Object;
  tdlen: number;
  ward: Object;
  wlen:number;
  att: string;
  options: string[] = ["A+", "B+", "AB+","O+","A+", "B+", "AB+",'O+'];
  closeResult: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  // dismasForm: FormGroup;
  submitted = false;
  mregForm: FormGroup;
  loading = false;
  displayedColumns: string[] = ['name','created_date','status','view'];
  btype: string[] = ['Villagepanchayat','Townpanchayat','Muncipality','Corporation']
  constructor(private api: ApiService, private formBuilder: FormBuilder,private modalService: NgbModal,private spinner: NgxSpinnerService){

  }
  ngOnInit(): void {
    this.getdistrict();
    this.getData();

    this.FilterForm = this.formBuilder.group({
      district : new FormControl(null),
      tmmk_dist : new FormControl(null),
      branch : new FormControl(null),
      branch_type : new FormControl(null),
      ward: new FormControl(null),
      start_date:new FormControl(''),
      end_date: new FormControl(''),
      filter: new FormControl(''),
      status: new FormControl('Not_Approved')
    })
    if(screen.width<=480)
    document.body.className += 'sidebar-closed sidebar-collapse';

  }

 

  view(content){
    console.log(content)

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'md'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) =>{
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
    );
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  getData(value=""){

    if(value){
      this.spinner.show()
      let mval:any;
      this.att = 'commonMethods';
      this.api.serviceData(this.att,value,'commonMethod','member_registration').subscribe(p=>{
        mval = p['data']
        if(mval){
          this.api.serviceData(this.att,value,'commonMethod','member_account').subscribe(d=>{
           
            this.profvalue = mval.map((item, i) => Object.assign({}, item, d['data'][i]));
            this.datasource = new MatTableDataSource(this.profvalue.reverse());
            setTimeout(() => {
              // this.paginator._intl.itemsPerPageLabel="Items Per Page";
              this.datasource.paginator = this.paginator;
              this.datasource.sort = this.sort;
              // this.datasource.sort = this.sort;
            }, 500);

            this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path']  

          });
        }
      
         
      this.spinner.hide()
      });
  }
    else{

      this.spinner.show()
      let fval:any;
      this.att = 'CommonMethods';
      this.api.serviceData(this.att,{status: 'Not_Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
        fval = d['data']
        if(fval){
          this.api.serviceData(this.att,{status: 'Not_Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(e=>{
          
          
  
            this.profvalue = fval.map((item, i) => Object.assign({}, item, e['data'][i]));
    
            this.datasource = new MatTableDataSource(this.profvalue.reverse());
            console.log(this.profvalue)
  
            setTimeout(() => {
         
              this.datasource.paginator = this.paginator;
              this.datasource.sort = this.sort;
              // this.paginator._intl.itemsPerPageLabel="Items Per Page";
              // this.datasource.sort = this.sort;
            }, 500);
  
            this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path'] 
          
            this.spinner.hide() 
  
          })
        }
       
      })
  }
  }

  accept(value){
    this.att = 'ApproveData';
    this.api.serviceData(this.att,{id:value.id,mobile_no:value.mobile_no,status:'Y',method:'firstLevelApprove'},'firstLevelApprove','approve').subscribe((d:any)=>{
    this.getData()
  

    this.modalService.dismissAll()
    })

  }

  reject(value){
    this.att = 'ApproveData';
    this.api.serviceData(this.att,{id:value.id,mobile_no:value.mobile_no,status:'R',method:'firstLevelApprove'},'firstLevelApprove','approve').subscribe((d:any)=>{
      this.getData()
    this.modalService.dismissAll()
    })

  }

 

 

  

 
  getbranch(value,branchtype){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{mmk_dist_id:value,branchtype:branchtype,method:'getBranch'},'commonMethod','getData').subscribe((d:any)=>{
      this.branch = d['data']
      this.blen = Object.keys(this.branch).length
     
     
    })
  }
  getmmkdistrict(value){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{district_id:value,method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.tmmk_dist = d['data']
      this.tdlen = Object.keys(this.tmmk_dist).length
    
    })

  }
  getdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.districts = d['data']
      this.dlen = Object.keys(this.districts).length
     
     
    })
  }

  getward(value){
    this.spinner.show()
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{branch_id:value, method:'getWard'},'commonMethod','getData').subscribe((d:any)=>{
      this.ward = d['data']
      this.wlen = Object.keys(this.ward).length
      this.spinner.hide()
    
    })
  }
  get f() { return this.mregForm.controls; }


  getfiltervalue(value){

    value["filter"]='Y';
    value["method"]='getdata';
   this.getData(value)

  }

  reset(){
    this.FilterForm.reset()
  }

  get g() { return this.FilterForm.controls; }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }

}
