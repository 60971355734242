import { Component, ElementRef, OnInit,ViewChild, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';
import { SnackbarService } from 'src/app/Service/snackbar.service';
import { res } from 'src/app/Shared/res';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dist-mas',
  templateUrl: './dist-mas.component.html',
  styleUrls: ['./dist-mas.component.css']
 
})
export class DistMasComponent implements OnInit {

  datasource: any;
  att: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  dismasForm: FormGroup;
  submitted = false;
  @ViewChild('TABLE1') table1: ElementRef;
  loading = false;

  stat: string[] = ["Y", "N"];
  constructor(private api: ApiService, private formBuilder: FormBuilder,private spinner: NgxSpinnerService, private sb: SnackbarService) { }

  displayedColumns: string[] = ['district_name','status','Edit'];
  ngOnInit(): void {
    this.getData();


    this.dismasForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: ['Y', Validators.required],
      id: [''],
      short_name: ['001',Validators.required],
      method: ['insertDistrict', Validators.required]
      // rememberMe: new FormControl(false)
  });
  }

  // ngAfterViewInit(): void {
  //   this.datasource.sort = this.sort;
  // }

  add(value){

    if (value.id){
      this.att = 'MasterData';
      
      this.api.serviceData(this.att,value,'insertDistrict','Edit').subscribe((d:any)=>{
        
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
         
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.dismasForm.controls['status'].reset()
          this.dismasForm.controls['id'].reset()
          this.getData();
         
        }
       
      })
    }

    else{

    
     
      this.att = 'MasterData';
      this.api.serviceData(this.att,value,'insertDistrict','Insert').subscribe((d:any)=>{
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.getData();
          
        }
      })
    } 

  }

  edit(value){
    this.dismasForm = this.formBuilder.group({
      name: new FormControl(value.district_name, Validators.required),
      status: new FormControl(value.status, Validators.required),
      short_name: new FormControl(value.district_short_name,Validators.required),
      id: new FormControl(value.dist_id,Validators.required),
      method: new FormControl('insertDistrict', Validators.required)
      
      // rememberMe: new FormControl(false)
  });

  
 
  }

  exportTable(){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet
    (this.table1.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
    XLSX.writeFile(wb,'Report.xlsx'); 
  }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }
 async  getData(){
    
    this.att = 'CommonMethods';
    await this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.datasource = new MatTableDataSource(d['data'].reverse());
  
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
        
    
      
     
    })
  }

  delete(value){
    // this.att = 'CommonMethods';

    // this.api.serviceData(this.att,value,'deleteDistrict','Delete').subscribe((d:any)=>{
    //   console.log(d)
    //  alert(d.msg)
    // })
  }

  applyFilter(filterValue : string) {

    //const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    // alert(this.datasource.filter = filterValue.trim().toLowerCase())
  }
  get f() { return this.dismasForm.controls; }

}
