<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-spin" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- Content Header (Page header) -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">

        <h1 class="m-0">{{ 'Archives' | translate }}</h1>
      </div><!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']" >Home</a></li>
          <li class="breadcrumb-item active">{{ 'Archives' | translate }}</li>
        </ol>
      </div><!-- /.col -->
    </div><!-- /.row -->
  </div><!-- /.container-fluid -->
</div>
<section class="content">
  <div class="container-fluid">
    <section class=" card p-4 mt-2">
      <div class="row justify-content-center">
        <div class="col-12  ">
          <form [formGroup]="FilterForm" (ngSubmit)="getfiltervalue(FilterForm.value)">
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-label-group" style="margin-top: 20px;" *ngIf="dlen">
                  <label for="district">{{ 'District' | translate }}</label>
                  <select onfocus='this.size=dlen;' class="form-control" formControlName="district"
                    (change)="getmmkdistrict(g.district.value)">
                    <option [ngValue]="null" disabled>{{ 'Select District' | translate }}</option>
                    <option *ngFor="let dis of districts" [value]="dis.dist_id"
                      [selected]="dis.dist_id === g.district.value">{{ dis.district_name | translate }}</option>
                  </select>

                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-label-group" style="margin-top: 20px;">
                  <label for="tmmk_dist">{{ 'TMMK-District' | translate }}</label>
                  <select onfocus='this.size=tdlen;' class="form-control" formControlName="tmmk_dist">
                    <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                    <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id"
                      [selected]="dis.tmmk_dist_id === g.tmmk_dist.value">{{ dis.tmmk_district_name | translate }}
                    </option>
                  </select>

                </div>
              </div>

              <div class="col-12 col-md-4">
                <div class="form-label-group" style="margin-top: 20px;">
                  <label for="status">{{ 'Status' | translate }}</label>
                  <select class="form-control" formControlName="status">
                    <option [ngValue]="null" disabled>{{ 'Select Status' | translate }}</option>
                    <option *ngFor="let option of status" [value]="option">
                      {{ option | translate }} </option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-md-4 col-12">
              <div class="form-group" style="margin-top: 20px;" >
                <label for="branch_type">{{ 'Branch Type' | translate }}</label>
                <select  class="form-control" (change) ="getbranch(g.tmmk_dist.value,g.branch_type.value)"  formControlName="branch_type" >
                    <option [ngValue]="null" disabled>{{ 'Select Branch Type' | translate }}</option>
                    <option *ngFor="let bt of btype ; let i = index;" value="{{i+1}}"  >{{ bt | translate  }}</option>
                </select>
            </div>
            </div> -->

            </div>

            <div class="row">
              <!-- <div class="col-md-4 col-12">
                <div class="form-label-group" style="margin-top: 20px;">
                  <label for="branch">{{ 'Branch' | translate }}</label>
                  <select onfocus='this.size=blen;'   class="form-control" formControlName="branch" (change) ="getward(g.branch.value)">

                    <option [ngValue]="null" disabled>{{ 'Select Branch' | translate }}</option>
                    <option *ngFor="let dis of branch" [value]="dis.branch_id" [selected]="dis.branch_id == g.branch.value" >{{ dis.branch_name | translate }}</option>
                </select>
                </div>
              </div> -->

              <!-- <div class="col-md-4 col-12">
                <div class="form-label-group" style="margin-top: 20px;">
                  <label for="ward">{{ 'Ward' | translate }}</label>
                  <select onfocus='this.size=wlen;'   class="form-control" formControlName="ward">

                    <option [ngValue]="null" disabled>{{ 'Select ward' | translate }}</option>
                    <option *ngFor="let dis of ward" [value]="dis.ward_id" [selected]="dis.ward_id == g.ward.value" >{{ dis.ward_name  | translate }}</option>
                </select>
                </div>
              </div> -->





            </div>




            <!-- <div class="row">

            <div class="col-12 col-md-4">
              <div class="form-label-group" style="margin-top: 20px;">
                <label for="start_date">{{ 'From Date' | translate }}</label>
                <input type="date"  id="start_date" name="start_date"   class="form-control" formControlName="start_date"  required>
              </div>


            </div>
            <div class="col-12 col-md-4">
              <div class="form-label-group" style="margin-top: 20px;">
                <label for="end_date">{{ 'To Date' | translate }}</label>
                <input type="date"  id="end_date" name="end_date"   class="form-control" formControlName="end_date"  required>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="form-label-group" style="margin-top: 20px;">
              <label for="role">{{ 'Role' | translate }}</label>
                  <select  onfocus='this.size=rlen;' class="form-control" formControlName="role">
                    <option [ngValue]="null" disabled>{{ 'Select Role' | translate }}</option>
                    <option *ngFor="let ro of roles" [value]="ro.user_prev_name">
                     {{ ro.user_prev_name | translate }} </option>
                    </select>
                    </div>
            </div>


          </div> -->

            <div class="row justify-content-center" style="padding-top: 5%;">
              <div class="col-12 text-right">
                <button class="btn   bg-darkz  mr-2" type="submit" style="color:white;"><i class=" fas fa-search"></i> {{
                  'Search' | translate }}</button>



                <Button class="btn btn-default" id="reset" type="text" (click)="reset()"> <i
                    class=" fas fa-sync-alt"></i> {{ 'Reset' | translate}}</Button>
              </div>

            </div>

          </form>
        </div>
      </div>
    </section>



    <section class="card p-4">
      <div class="row m-2" style="padding-top: 3%;">
        <div class="col-sm-3 col-xs-3   p-3">
          <button mat-raised-button  (click)="exportTable()"><mat-icon>file_download</mat-icon>  {{ 'Export' | translate
            }}</button>
          
        </div>

        <div class="col-sm-3  col-xs-3  offset-md-6 p-3  ">
          <input id="filter" class="form-control" type="text" (keyup)="doFilter($event.target.value)"
            placeholder="{{ 'Search' | translate}}">
        </div>

        <div class="col-12" #TABLE1 class="d-none ">
          <table mat-table #table1 [dataSource]="datasource" class="mat-elevation-z8 responsive-table " matSort
            *ngIf="datasource; else load">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>

            <ng-container matColumnDef="father_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Father Name' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.father_name }} </td>
            </ng-container>

            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Gender' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.gender }} </td>
            </ng-container>

            <ng-container matColumnDef="mobile_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Mobile Number' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.mobile_no }} </td>
            </ng-container>

            <ng-container matColumnDef="dob">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOB' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.dob }} </td>
            </ng-container>

            <ng-container matColumnDef="email_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email Address' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.email_id }} </td>
            </ng-container>

            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Address' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.address }} </td>
            </ng-container>


            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'country' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.country }} </td>
            </ng-container>

            <ng-container matColumnDef="pincode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Pincode' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.pincode }} </td>
            </ng-container>

            <ng-container matColumnDef="mmk_mem_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Account Id' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.mmk_mem_id }} </td>
            </ng-container>


            <ng-container matColumnDef="blood_group">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Blood Group' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.blood_group }} </td>
            </ng-container>

            <ng-container matColumnDef="district_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'District' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.district }} </td>
            </ng-container>

            <ng-container matColumnDef="tmmk_district_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TMMK-District' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.tmmk_district }} </td>
            </ng-container>


            <ng-container matColumnDef="branch_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Branch' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.branch }} </td>
            </ng-container>

            <ng-container matColumnDef="ward_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Ward' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.ward }} </td>
            </ng-container>

            <ng-container matColumnDef="approved_status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Approved_status' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.approved_status }} </td>
            </ng-container>

            <ng-container matColumnDef="payment_status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Payment Status' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.payment_status }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
          </table>
        </div>

        <div class="col-12" #TABLE style="width: fit-content;overflow-x: scroll;" [hidden]="!datasource">
          <table mat-table #table [dataSource]="datasource" class="responsive-table" matSort>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>


            <ng-container matColumnDef="updated_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Approved On' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.updated_date }} </td>
            </ng-container>


            <ng-container matColumnDef="approved_status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>

              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.approved_status === 'Y' ">
                  {{ 'Approved' | translate }}

                </div>
                <div *ngIf="element.approved_status === 'N' ">
                  {{ 'Pending' | translate }}

                </div>

              </td>

            </ng-container>


            <ng-container matColumnDef="mmk_mem_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MMK ID' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.mmk_mem_id }} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef >{{ 'Assign' | translate }}</th>
                        <td mat-cell *matCellDef="let element" >
                              <button mat-raised-button color="accent"  (click)="vup(upmodel,element)">
                                {{ 'Assign' | translate }}</button>
                            <ng-template #upmodel let-modal>
                                <div class="modal-header bg-gradient-dark">
                                  <h2 class="modal-title" id="modal-basic-title"> {{ 'Assign Responsiblity' | translate }}</h2>
                                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div class="modal-body">
                                    <div class="card-body bg-gradient-lightblue">
                                        <form [formGroup]="mregForm" (ngSubmit)="update(mregForm.value)">

                                          <div class="form-label-group" style="margin-top: 20px;">
                                            <label for="user_prev">{{ 'Role' | translate }}</label>
                                            <select  class="form-control" formControlName="user_prev">
                                              <option [ngValue]="null" disabled>{{ 'Select Role' | translate }}</option>
                                              <option *ngFor="let dis of roles" [value]="dis.user_prev_id" [selected]="dis.user_prev_id === f.user_prev.value" >{{dis.user_prev_name}}</option>
                                          </select>

                                          </div>

                                          <input class="btn btn-lg  bg-darkz btn-info btn-block  mt-3" type="submit" style="color:white;"  value="{{ 'Update' | translate }}">

                                          </form>


                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark" (click)="cancel()">{{ 'Cancel' | translate }}</button>
                                </div>
                            </ng-template>
                            </td>
                      </ng-container> -->

            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>{{ 'View Card' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <a  mat-raised-button (click)="download(element)"><mat-icon>visibility</mat-icon> {{ 'View Card' | translate }}</a>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 15,20,25,30]" showFirstLastButtons></mat-paginator>


        </div>
        <ng-template #load>
          <div class="row" style="align-items: center;">
            <div class="col-2">
              <div class="spinner-border text-blue">

              </div>
            </div>
            <div class="col-10">
              <h5>{{ 'Loading' | translate }} </h5>
            </div>
          </div>
        </ng-template>
      </div>
    </section>
  </div>
</section>
