<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-spin" [fullScreen] = "true"><p style="color: white" > 
{{ 'Loading...' | translate }} </p></ngx-spinner>
<div class="content" *ngIf="profvalue">
  <ng-container *ngIf="profvalue['payment_status'] == 'R'">
    <div class="content">
      <div class="row">
        <div class="col-12 col-md-7 col-lg-5 mx-auto" >
          <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
            <h4 class="card-header text-center bg-gradient-secondary">{{ 'Approval Status' | translate }}</h4>
            <div class="card-body text-center">
              <div class="col-12">
                <i class='fas fa-exclamation-triangle' style='font-size:68px;color:red;'></i>
              </div>
              <div class="col-12 mt-2">
                {{ 'App Reject' | translate }}
              </div>
            </div>
    
            <div class="card-footer">
              <div class="row text-center">
                <div class="col-12" >
                  {{ 'Contact Admin' | translate }}
                 </div>

                 <div class="col-12">
                  Email Id: mmktnelections@gmail.com
                 </div>
              </div>
            </div>
            </div>
       </div>
     </div>
    </div>
   
  </ng-container>
<ng-container *ngIf="profvalue['payment_status'] == 'Y'; else noblock">
  <ng-container *ngIf="profvalue['approve_one'] == 'Y'; else noblock">
  <app-profile></app-profile>
  </ng-container>
<ng-template #noblock> 

  <div class="content">
  <div class="row">
    <div class="col-12 col-md-7 col-lg-5 mx-auto" >
      <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
        <h4 class="card-header text-center bg-gradient-secondary">{{ 'Approval Status' | translate }}</h4>
        <div class="card-body text-center">
          <div class="col-12">
            <i class='fas fa-exclamation-triangle' style='font-size:68px;color:red;'></i>
          </div>
          <div class="col-12 mt-2">
            {{ 'App STAT' | translate }}
          </div>
        </div>

        </div>
        </div>
        </div>
      </div>


</ng-template>
</ng-container>
 <ng-template #noblock>
 <div class="content" *ngIf="paylink">
   

   <div class="row">
    <div class="col-12 col-md-7 col-lg-5 mx-auto" >
      <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
        <h4 class="card-header text-center bg-gradient-secondary">{{ 'Payment Status' | translate }}</h4>
        <div class="card-body text-center">
          <div class="col-12">
            <i class='fas fa-exclamation-triangle' style='font-size:68px;color:black;'></i>
          </div>
          <div class="col-12 mt-2">
            {{ 'Pay STAT' | translate }}
          </div>
        </div>

        <div class="card-footer">
          <div class="row text-center">
            <div class="col-12" >
              <a  href="{{paylink}}" target="_blank">{{'uselink' | translate }}</a>
             </div>
          </div>
        </div>
        </div>
   </div>
 </div>

 

  

 </div>
  </ng-template>

</div>
