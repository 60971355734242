<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">

    <div class="row ">
      <div class="col-12 col-md-10 col-lg-8 mx-auto">

      </div>
    </div>

    <div class="card mt-4 shadow">
      <div class="card-header">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-12">
            <h1 class=" pl-3 pt-2 font-weight-bold meera">{{ 'MMK Registration' | translate }}</h1>
          </div>
          <div class="col-lg-4 col-md-4 col-12 text-center">
            {{ 'Language' | translate }} :
            <select #langSelect (change)="changeLang(langSelect.value)"
              style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

              <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-body">

        <mat-horizontal-stepper [linear]="true" #stepper *ngIf="!this.mobilescreen">
          <mat-step [stepControl]="otpForm" [editable]="otpFormeditable" errorMessage="Mobile Number required.">
            <ng-template matStepLabel class="step-label">
              <span style="color: black !important;">{{ 'Mobile Verify' | translate }} <span
                  *ngIf="otpForm">*</span></span>

            </ng-template>

            <div class="row justify-content-center">
              <div class="col-12">
                <div class="col-12 col-md-7 col-lg-5 mx-auto">
                  <form [formGroup]="otpForm">
                    <div class="form-group">
                      <label for="mobile_number" class="sr-only">{{ 'Mobile Number' | translate }}</label>
                      <input type="text" pattern="\d*" maxlength="10" formControlName="mobile_number"
                        class="form-control" placeholder="Mobile Number">

                      <div *ngIf="sub && ff.mobile_number.errors" class="invalid-feedback">
                        <div *ngIf="ff.mobile_number.errors.required">{{ 'Mobile is required' | translate }}</div>
                        <div *ngIf="ff.mobile_number.errors.minlength">
                          {{ 'Mobile Number must be at least 10 numbers long' | translate }}
                        </div>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-secondary float-right" (click)="sendotp(otpForm.value,stepper)"
                          [disabled]="!otpForm.valid" type="button" style="color:white;">{{ 'Get OTP' | translate }}
                        </button>
                      </div>
                      <!-- <timer interval="1000" countdown="100">{{countdown}}</timer> -->
                      <!-- <div class="col-6">
                        <button class="btn btn-secondary float-right" (click)="resendotp(otpForm.value,stepper)" [disabled]="timerOn" type="button" style="color:white;" >{{ 'Resend OTP' | translate }}
                        </button>

                      </div>-->


                    </div>
                  </form>
                  <div class="row">
                    <div class="col-12">
                      <div *ngIf="err" class="info alert alert-default-dark mt-3 mb-0">{{ err | translate }}</div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </mat-step>


          <mat-step [stepControl]="vrfyform" [editable]="vrfyformeditable" errorMessage="OTP required.">
            <ng-template matStepLabel class="step-label">
              <span style="color: black !important;">{{ 'OTP Verify' | translate }} <span>*</span></span>

            </ng-template>

            <div class="row justify-content-center">
              <div class="col-12">
                <div class="col-12 col-md-7 col-lg-5 mx-auto">
                  <form [formGroup]="vrfyform">
                    <div class="form-group">

                      <label for="otp">{{ 'OTP Verification' | translate }}</label>
                      <input type="text" id="otp" class="form-control" formControlName="otp">
                      <div *ngIf="fff.otp.invalid &&  fff.otp.touched" class="invalid-feedback">
                        <div *ngIf="fff.otp.errors.required">
                          {{ 'OTP is required' | translate }}
                        </div>

                      </div>


                    </div>
                    <div class="form-group">
                      <span>
                        {{ "Didn't receive code??" | translate }} <a
                          style="color: blue; text-decoration: underline; cursor: pointer;"
                          (click)="resendotp(otpForm.value)">{{ 'Resend OTP' | translate }}</a>
                      </span>
                    </div>
                    <div class="row">

                      <div class="col-12">
                        <button class="btn btn-secondary float-right" (click)="vrfyotp(vrfyform.value,stepper)"
                          [disabled]="!vrfyform.valid" type="button" style="color:white;">{{ 'Verify' | translate
                          }}</button>
                      </div>
                    </div>

                  </form>



                </div>
              </div>
            </div>

          </mat-step>



          <h5 class=" card-header bg-gradient-dark text-center">{{ 'Registration' | translate }}</h5>

          <mat-step [stepControl]="thirdstepperform" [editable]="thirdFormeditable" errorMessage="Fill Required Fields">
            <form [formGroup]="thirdstepperform">
              <ng-template matStepLabel class="step-label">
                <span style="color: black !important;">{{ 'Personal Details' | translate }}
                  <span>*</span>
                </span>
              </ng-template>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="name">{{ 'Name' | translate }}</label>
                    <input type="text" id="name" class="form-control " formControlName="name"
                      [ngClass]="{ 'is-invalid': thirdval.name.errors }">
                    <div *ngIf="thirdval.name.invalid &&  thirdval.name.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.name.errors.required">
                        {{ 'Name is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.name.errors.minlength">
                        {{ 'Name must be at least 2 characters long' | translate }}
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6 ">
                  <div class="form-group">
                    <label for="father_name">{{ 'Father Name' | translate }}</label>
                    <input type="text" id="father_name" class="form-control" formControlName="father_name"
                      [ngClass]="{ 'is-invalid': thirdval.father_name.errors }">
                    <div *ngIf="thirdval.father_name.invalid &&  thirdval.father_name.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.father_name.errors.required">
                        {{ 'Father name is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.father_name.errors.minlength">
                        {{ 'Father name must be at least 2 characters long' | translate }}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="mobile_no">{{ 'Mobile Number' | translate }}</label>
                    <input type="number" id="mobile_no" maxlength="10" class="form-control" readonly
                      formControlName="mobile_no" [ngClass]="{ 'is-invalid': thirdval.mobile_no.errors }">
                    <div *ngIf="thirdval.mobile_no.invalid &&  thirdval.mobile_no.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.mobile_no.errors.required">
                        {{ 'Mobile Number is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.mobile_no.errors.minlength">
                        {{ 'Mobile Number must be at least 10 numbers long' | translate }}
                      </div>
                      <div *ngIf="thirdval.mobile_no.errors.maxlength">
                        {{ 'Mobile Number should be 12 numbers long' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">

                  <div class="form-group">
                    <label for="password">{{ 'Password' | translate }}</label>
                    <div style="display: flex;">
                      <input type="password" style="width: 90%;" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': thirdval.password.errors }" [type]="hide ? 'password' : 'text'" />
                      <mat-icon style="padding-left: 3%;padding-top: 1%;" class="placeholder " (click)="myFunction()">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                    <div *ngIf="thirdval.password.invalid &&  thirdval.password.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.password.errors.required">
                        {{ 'Password is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.password.errors.minlength">
                        {{ 'Password must be at least 6 numbers long' | translate }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-md-6">
                  <div class="form-group" *ngIf="maxD1">
                    <label for="dob">{{ 'DOB' | translate }}</label>
                    <input type="date" id="dob" min="1940-01-01" [max]="maxD1" placeholder="DD-MM-YYYY"
                      class="form-control" formControlName="dob" [ngClass]="{ 'is-invalid': thirdval.dob.errors }">
                    <div *ngIf="thirdval.dob.invalid &&  thirdval.dob.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.dob.errors.required">
                        {{ 'Date of Birth is required' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="gender">{{ 'Gender' | translate }}</label>
                    <div class="row">
                      <div class="col-6">
                        <label>
                          <input type="radio" formControlName="gender" value="MALE" /> <span> &nbsp;{{ 'Male' |
                            translate }}</span>
                        </label>
                      </div>
                      <div class="col-6">
                        <label><input type="radio" formControlName="gender" value="FEMALE"
                            [ngClass]="{ 'is-invalid': thirdval.gender.errors }" /> <span> &nbsp;{{ 'Female' | translate
                            }}</span></label>

                      </div>

                    </div>
                    <div *ngIf="thirdval.gender.invalid &&  thirdval.gender.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.gender.errors.required">
                        {{ 'Gender is required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>






              </div>
              <div class="row">

                <div class="col-12">
                  <button class="btn btn-secondary float-right"
                    (click)="thirdstepperbutton(thirdstepperform.value,stepper)" [disabled]="!thirdstepperform.valid"
                    type="button" style="color:white;">{{ 'Next' | translate }}
                  </button>

                </div>

              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="mregForm" errorMessage="Address Details required.">
            <form [formGroup]="mregForm">
              <ng-template matStepLabel class="step-label">
                <span style="color: black !important;">{{ 'Party Details' | translate }}
                  <span>*</span>
                </span>
              </ng-template>
              <div class="row  ">
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="blood_group">{{ 'Blood Group' | translate }}</label>
                    <select class="form-control" formControlName="blood_group"
                      [ngClass]="{ 'is-invalid':  f.blood_group.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select Blood Group' | translate }}</option>
                      <option *ngFor="let option of options" [value]="option">{{option}}</option>
                    </select>

                    <div *ngIf="f.blood_group.invalid && f.blood_group.touched" class="invalid-feedback">
                      <div *ngIf="f.blood_group.errors.required">
                        {{ 'Blood Group is required' | translate }}
                      </div>
                    </div>


                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="email_id">{{ 'Email Address' | translate }}</label>
                    <input type="email_id" id="email_id" class="form-control" formControlName="email_id"
                      [ngClass]="{ 'is-invalid': f.email_id.errors }">
                    <div *ngIf="f.email_id.invalid &&  f.email_id.touched" class="invalid-feedback">
                      <div *ngIf="f.email_id.errors.required">
                        {{ 'Email is required' | translate }}
                      </div>
                      <div *ngIf="f.email_id.errors.pattern">
                        {{ 'Email is Invalid' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="address">{{ 'Address' | translate }}</label>
                    <textarea type="textarea" id="address" class="form-control" formControlName="address"
                      [ngClass]="{ 'is-invalid': f.address.errors }"></textarea>
                    <div *ngIf="f.address.invalid &&  f.address.touched" class="invalid-feedback">
                      <div *ngIf="f.address.errors.required">
                        {{ 'Address is required' | translate }}
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="district">{{ 'District' | translate }}</label>
                    <select *ngIf="dlen" onfocus='this.size=dlen;' class="form-control" formControlName="district"
                      (change)="getmmkdistrict(f.district.value)" [ngClass]="{ 'is-invalid': f.district.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select District' | translate }}</option>
                      <option *ngFor="let dis of districts" [value]="dis.dist_id"
                        [selected]="dis.dist_id === f.district.value">{{dis.district_name}}</option>
                    </select>
                    <div *ngIf="f.district.invalid &&  f.district.touched" class="invalid-feedback">
                      <div *ngIf="f.district.errors.required">
                        {{ 'District is Required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6">

                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="tmmk_dist">{{ 'TMMK-District' | translate }}</label>
                    <div *ngIf="tmmk_dist; else el">
                      <select onfocus='this.size=tdlen;' class="form-control" formControlName="tmmk_dist"
                        (change)="getbranch(f.tmmk_dist.value)" [ngClass]="{ 'is-invalid': f.tmmk_dist.errors }">
                        <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                        <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id"
                          [selected]="dis.tmmk_dist_id === f.tmmk_dist.value">{{dis.tmmk_district_name}}</option>
                      </select>
                      <div *ngIf="f.tmmk_dist.invalid &&  f.tmmk_dist.touched" class="invalid-feedback">
                        <div *ngIf="f.tmmk_dist.errors.required">
                          {{ 'TMMK-District is Required' | translate }}
                        </div>
                      </div>
                    </div>
                    <ng-template #el>
                      <div>
                        <h5>{{ 'Select District' | translate }}</h5>
                      </div>

                    </ng-template>

                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="constituency">{{ 'constituency' | translate }}</label>
                    <select onfocus='this.size=clen;' class="form-control" formControlName="constituency"
                      [ngClass]="{ 'is-invalid':  f.constituency.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select constituency' | translate }}</option>
                      <option *ngFor="let dis of constituencies" [value]="dis.constituency_id"
                        [selected]="dis.constituency_id === f.constituency.value">{{dis.constituency_name}}</option>
                    </select>
                    <div *ngIf="f.constituency.invalid &&  f.constituency.touched" class="invalid-feedback">
                      <div *ngIf="f.constituency.errors.required">
                        {{ 'constituency is Required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="temp_branch">{{ 'Branch' | translate }}</label>

                    <input type="temp_branch" id="temp_branch" class="form-control" formControlName="temp_branch"
                      [ngClass]="{ 'is-invalid': f.temp_branch.errors }">
                    <div *ngIf="f.temp_branch.invalid &&  f.temp_branch.touched" class="invalid-feedback">
                      <div *ngIf="f.temp_branch.errors.required">
                        {{ 'Branch is required' | translate }}
                      </div>
                      <div *ngIf="f.temp_branch.errors.pattern">
                        {{ 'Branch is Invalid' | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="pincode">{{ 'Pincode' | translate }}</label>
                    <input type="number" id="pincode" pattern="[0-9]{6}" minlength='6' placeholder="600001"
                      class="form-control" formControlName="pincode" [ngClass]="{ 'is-invalid':f.pincode.errors }">
                    <div *ngIf="f.pincode.invalid &&  f.pincode.touched" class="invalid-feedback">
                      <div *ngIf="f.pincode.errors.required">
                        {{ 'Pincode is required' | translate }}
                      </div>
                      <div *ngIf="f.pincode.errors.minlength">
                        {{ 'Pincode must be at least 6 numbers long' | translate }}
                      </div>

                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6 col-12">
          <div class="form-group" style="margin-top: 20px;" >

            <label for="branch_type">{{ 'Branch Type' | translate }}</label>
            <select  class="form-control" formControlName="branch_type"  [ngClass]="{ 'is-invalid':loading  && f.branch_type.errors }" >
                <option [ngValue]="null" disabled>{{ 'Select Branch Type' | translate }}</option>
                <option *ngFor="let bt of btype ; let i = index;" value="{{i+1}}"  >{{ bt | translate  }}</option>
            </select>
            </div>
        </div> -->
                <div class="col-12 col-md-6">


                  <!-- <div *ngIf="branch; else ebb">
            <select  onfocus='this.size=blen;'  class="form-control" formControlName="branch"  (change) ="getward(f.branch.value)" [ngClass]="{ 'is-invalid':loading && f.branch.errors }">
              <option [ngValue]="null" disabled>{{ 'Select Branch' | translate }}</option>
              <option *ngFor="let dis of branch" [value]="dis.branch_id" [selected]="dis.branch_id == f.branch.value" >{{dis.branch_name}}</option>
          </select>
          <div *ngIf="f.branch.invalid &&  f.branch.touched" class="invalid-feedback">
            <div *ngIf="f.branch.errors.required">
               {{ 'Branch is Required' | translate }}
            </div>
            </div>
          </div>

          <ng-template #ebb>
            <div>
             <h5>{{ 'Select Branch Type' | translate }}</h5>
            </div>
          </ng-template> -->
                </div>

              </div>


              <div class="row">
                <!-- <div class="col-12 col-md-6">

          <div class="form-label-group" style="margin-top: 20px;">

            <label for="ward">{{ 'Ward' | translate }}</label>
            <div *ngIf="ward; else llw">
            <select  onfocus='this.size=wlen;'  class="form-control" formControlName="ward"  [ngClass]="{ 'is-invalid': loading && f.ward.errors }">
              <option [ngValue]="null" disabled>{{ 'Select Ward' | translate }}</option>
              <option *ngFor="let dis of ward" [value]="dis.ward_id" [selected]="dis.ward_id == f.ward.value" >{{dis.ward_name}}</option>
          </select>
          <div *ngIf="f.ward.invalid &&  f.ward.touched" class="invalid-feedback">
            <div *ngIf="f.ward.errors.required">
               {{ 'Ward is Required' | translate }}
            </div>
            </div>
            </div>
            <ng-template #llw>
              <div>
               <h5>{{ 'Select Branch' | translate }}</h5>
              </div>
            </ng-template>
          </div>

        </div> -->

                <div class="col-12">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="img_path">{{ 'Profile Image' | translate }}</label>
                    <div class="group-gap">

                      <div class="avatar-upload" (click)="fileInput.click()">
                        <div class="avatar-edit">
                          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                            (change)="uploadFile($event)" class="form-control" />
                        </div>
                        <div class="avatar-preview">
                          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="row mt-3 text-center">
                <div class="col-12">
                  <input type="checkbox" [(ngModel)]="checkbox" [ngModelOptions]="{standalone: true}" checked>
                  <label for="dec">
                    {{ 'Declaration' | translate }}
                  </label>

                </div>
                <div class="col-12">
                  <a (click)="decmodel(vmodel)" style="text-decoration:underline; color: blue;">
                    {{ 'Read Terms' | translate }}
                  </a>
                </div>
              </div>



              <div class="row ">

                <div class="col-12 ">
                  <button class="btn btn-secondary  mt-3 float-right" (click)="register(mregForm.value)"
                    [disabled]="!mregForm.valid && !checkbox" type="button" style="color:white;">
                    {{ 'Register' | translate }}
                  </button>

                </div>
              </div>
              <ng-template #vmodel let-modal>
                <div class="modal-header bg-gradient-dark">
                  <h2 class="modal-title" id="modal-basic-title" style="color: white; text-decoration: underline;">{{
                    'Terms & Conditions' | translate }}</h2>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="card-body bg-gradient-white">
                    <h4 style="font-size: 16px;"><b> {{ 'rt1' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt2' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt3' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt4' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt5' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt6' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt7' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt8' | translate }}</b></h4>
                    <br>
                    <h4 style="font-size: 16px;"><b> {{ 'rt9' | translate }}</b></h4>


                  </div>
                </div>
                <div class="card-footer bg-gradient-light">
                  <div class="row">
                    <div class="col-4 offset-8">
                      <button type="button" class="btn  bg-darkz" (click)="cancel()"> {{ 'Close' | translate }}</button>
                    </div>
                  </div>
                </div>

              </ng-template>



              <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
            </form>
          </mat-step>

        </mat-horizontal-stepper>
        <mat-vertical-stepper [linear]="true" #stepper *ngIf="this.mobilescreen">
          <mat-step [stepControl]="otpForm" [editable]="otpFormeditable" errorMessage="Mobile Number required.">
            <ng-template matStepLabel class="step-label">
              <span style="color: black !important;">{{ 'Mobile Verify' | translate }}</span>
            </ng-template>

            <div class="row justify-content-center">
              <div class="col-12">
                <div class="col-12 col-md-7 col-lg-5 mx-auto">
                  <form [formGroup]="otpForm">
                    <div class="form-group">
                      <label for="mobile_number" class="sr-only">{{ 'Mobile Number' | translate }}</label>
                      <input type="text" formControlName="mobile_number" class="form-control"
                        [ngClass]="{ 'is-invalid': sub && ff.mobile_number.errors }" placeholder="Mobile Number">
                      <div *ngIf="sub && ff.mobile_number.errors" class="invalid-feedback">
                        <div *ngIf="ff.mobile_number.errors.required">{{ 'Mobile is required' | translate }}</div>
                      </div>
                    </div>
                    <!-- <div class="form-group justify-content-center">
                        <label for="mobile_number">  {{ 'Mobile Number' | translate }}</label>
                        &nbsp;&nbsp;
                        <input type="number" id="mobile_number" formControlName="mobile_number"   class="form-control" placeholder="8608989111" [ngClass]="{ 'is-invalid': ff.mobile_number.errors }" />
                        <div *ngIf="ff.mobile_number.invalid &&  ff.mobile_number.touched" class="invalid-feedback">
                          <div *ngIf="ff.mobile_number.errors.required">
                            {{ 'Mobile  is required' | translate }}
                          </div>
                          <div *ngIf="ff.mobile_number.errors.minlength">
                            {{ 'Mobile Number must be at least 10 numbers long' | translate }}
                         </div>
                         <div *ngIf="ff.mobile_number.errors.maxlength">
                            {{ 'Mobile Number should be 12 numbers long' | translate }}
                         </div>
                          </div>
                    </div> -->
                    <div class="row">
                      <div class="col-12">
                        <button class="btn btn-secondary float-right" (click)="sendotp(otpForm.value,stepper)"
                          [disabled]="!otpForm.valid" type="button" style="color:white;">{{ 'Get OTP' | translate
                          }}</button>
                      </div>
                    </div>
                    <div *ngIf="err" class="alert alert-default-dark mt-3 mb-0">{{err}}</div>
                  </form>


                </div>
              </div>
            </div>

          </mat-step>


          <mat-step [stepControl]="vrfyform" [editable]="vrfyformeditable" errorMessage="OTP required.">
            <ng-template matStepLabel class="step-label">
              <span style="color: black !important;">{{ 'OTP Verify' | translate }}
                <span>*</span>
              </span>
            </ng-template>

            <div class="row justify-content-center">
              <div class="col-12">
                <div class="col-12 col-md-7 col-lg-5 mx-auto">
                  <form [formGroup]="vrfyform">
                    <div class="form-group">

                      <label for="otp">{{ 'OTP Verification' | translate }}</label>
                      <input type="text" id="otp" class="form-control" formControlName="otp">
                      <div *ngIf="fff.otp.invalid &&  fff.otp.touched" class="invalid-feedback">
                        <div *ngIf="fff.otp.errors.required">
                          {{ 'OTP is required' | translate }}
                        </div>

                      </div>


                    </div>
                    <div class="form-group">
                      <span>
                        {{ "Didn't receivea code??" }} <a
                          style="color: rgba(88, 88, 236, 0.493); text-decoration: underline; cursor: pointer;"
                          (click)="resendotp(otpForm.value)">{{ 'Resend OTP' | translate }}</a>
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button class="btn btn-secondary float-right" (click)="vrfyotp(vrfyform.value,stepper)"
                          [disabled]="!vrfyform.valid" type="button" style="color:white;">{{ 'Verify' | translate
                          }}</button>
                      </div>
                    </div>

                  </form>


                </div>
              </div>
            </div>

          </mat-step>


          <mat-step [stepControl]="thirdstepperform" [editable]="thirdFormeditable" fxFlex="100"
            errorMessage="Fill Required Fields">
            <form [formGroup]="thirdstepperform">
              <ng-template matStepLabel class="step-label">
                <span style="color: black !important;">{{ 'Personal Details' | translate }}
                  <span>*</span>
                </span>
              </ng-template>
              <div class="row overflow-auto">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="name">{{ 'Name' | translate }}</label>
                    <input type="text" id="name" class="form-control " formControlName="name"
                      [ngClass]="{ 'is-invalid': thirdval.name.errors }">
                    <div *ngIf="thirdval.name.invalid &&  thirdval.name.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.name.errors.required">
                        {{ 'Name is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.name.errors.minlength">
                        {{ 'Name must be at least 2 characters long' | translate }}
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6 ">
                  <div class="form-group">
                    <label for="father_name">{{ 'Father Name' | translate }}</label>
                    <input type="text" id="father_name" class="form-control" formControlName="father_name"
                      [ngClass]="{ 'is-invalid': thirdval.father_name.errors }">
                    <div *ngIf="thirdval.father_name.invalid &&  thirdval.father_name.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.father_name.errors.required">
                        {{ 'Father name is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.father_name.errors.minlength">
                        {{ 'Father name must be at least 2 characters long' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="mobile_no">{{ 'Mobile Number' | translate }}</label>
                    <input type="number" max="10" id="mobile_no" readonly class="form-control"
                      formControlName="mobile_no">
                    <div *ngIf="thirdval.mobile_no.invalid &&  thirdval.mobile_no.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.mobile_no.errors.required">
                        {{ 'Mobile Number is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.mobile_no.errors.minlength">
                        {{ 'Mobile Number must be at least 10 numbers long' | translate }}
                      </div>
                      <div *ngIf="thirdval.mobile_no.errors.maxlength">
                        {{ 'Mobile Number should be 12 numbers long' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">

                  <div class="form-group">
                    <label for="password">{{ 'Password' | translate }}</label>
                    <div style="display: flex;">
                      <input type="password" style="width: 90%;" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': thirdval.password.errors }" [type]="hide ? 'password' : 'text'" />
                      <mat-icon style="padding-left: 3%;padding-top: 1%;" class="placeholder " (click)="myFunction()">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                    <div *ngIf="thirdval.password.invalid &&  thirdval.password.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.password.errors.required">
                        {{ 'Password is required' | translate }}
                      </div>
                      <div *ngIf="thirdval.password.errors.minlength">
                        {{ 'Password must be at least 6 numbers long' | translate }}
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group" *ngIf="maxD1">
                    <label for="dob">{{ 'DOB' | translate }}</label>
                    <input type="date" id="dob" min="1940-01-01" [max]="maxD1" placeholder="DD-MM-YYYY"
                      class="form-control" formControlName="dob" [ngClass]="{ 'is-invalid': thirdval.dob.errors }">
                    <div *ngIf="thirdval.dob.invalid &&  thirdval.dob.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.dob.errors.required">
                        {{ 'Date of Birth is required' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="gender">{{ 'Gender' | translate }}</label>
                    <div class="row">
                      <div class="col-6">
                        <label>
                          <input type="radio" formControlName="gender" value="MALE" /> <span> &nbsp;{{ 'Male' |
                            translate }}</span>
                        </label>
                      </div>
                      <div class="col-6">
                        <label><input type="radio" formControlName="gender" value="FEMALE"
                            [ngClass]="{ 'is-invalid': thirdval.gender.errors }" /> <span> &nbsp;{{ 'Female' | translate
                            }}</span></label>

                      </div>

                    </div>
                    <div *ngIf="thirdval.gender.invalid &&  thirdval.gender.touched" class="invalid-feedback">
                      <div *ngIf="thirdval.gender.errors.required">
                        {{ 'Gender is required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>





              </div>
              <div class="row">

                <div class="col-12">
                  <button class="btn btn-secondary float-right"
                    (click)="thirdstepperbutton(thirdstepperform.value,stepper)" [disabled]="!thirdstepperform.valid"
                    type="button" style="color:white;">{{ 'Next' | translate }}
                  </button>

                </div>

              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="mregForm" errorMessage="Address Details required.">
            <form [formGroup]="mregForm">
              <ng-template matStepLabel class="step-label">
                <span style="color: black !important;">{{ 'Party Details' | translate }}
                  <span>*</span>
                </span>
              </ng-template>
              <div class="row  ">
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="blood_group">{{ 'Blood Group' | translate }}</label>
                    <select class="form-control" formControlName="blood_group"
                      [ngClass]="{ 'is-invalid': f.blood_group.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select Blood Group' | translate }}</option>
                      <option *ngFor="let option of options" [value]="option">{{option}}</option>
                    </select>

                    <div *ngIf="f.blood_group.invalid && f.blood_group.touched" class="invalid-feedback">
                      <div *ngIf="f.blood_group.errors.required">
                        {{ 'Blood Group is required' | translate }}
                      </div>
                    </div>


                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="email_id">{{ 'Email Address' | translate }}</label>
                    <input type="email_id" id="email_id" class="form-control" formControlName="email_id" required
                      [ngClass]="{ 'is-invalid': f.email_id.errors }">
                    <div *ngIf="f.email_id.invalid &&  f.email_id.touched" class="invalid-feedback">
                      <div *ngIf="f.email_id.errors.required">
                        {{ 'Email is required' | translate }}
                      </div>
                      <div *ngIf="f.email_id.errors.pattern">
                        {{ 'Email is Invalid' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="address">{{ 'Address' | translate }}</label>
                    <textarea type="textarea" id="address" class="form-control" formControlName="address"
                      [ngClass]="{ 'is-invalid': f.address.errors }"></textarea>
                    <div *ngIf="f.address.invalid &&  f.address.touched" class="invalid-feedback">
                      <div *ngIf="f.address.errors.required">
                        {{ 'Address is required' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="constituency">{{ 'constituency' | translate }}</label>
                    <select *ngIf="clen" onfocus='this.size=clen;' class="form-control" formControlName="constituency"
                      [ngClass]="{ 'is-invalid': loading && f.constituency.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select constituency' | translate }}</option>
                      <option *ngFor="let dis of constituencies" [value]="dis.constituency_id"
                        [selected]="dis.constituency_id === f.constituency.value">{{dis.constituency_name}}</option>
                    </select>
                    <div *ngIf="f.constituency.invalid &&  f.constituency.touched" class="invalid-feedback">
                      <div *ngIf="f.constituency.errors.required">
                        {{ 'constituency is Required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="district">{{ 'District' | translate }}</label>
                    <select *ngIf="dlen" onfocus='this.size=dlen;' class="form-control" formControlName="district"
                      (change)="getmmkdistrict(f.district.value)" [ngClass]="{ 'is-invalid': f.district.errors }">
                      <option [ngValue]="null" disabled>{{ 'Select District' | translate }}</option>
                      <option *ngFor="let dis of districts" [value]="dis.dist_id"
                        [selected]="dis.dist_id === f.district.value">{{dis.district_name}}</option>
                    </select>
                    <div *ngIf="f.district.invalid &&  f.district.touched" class="invalid-feedback">
                      <div *ngIf="f.district.errors.required">
                        {{ 'District is Required' | translate }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6">

                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="tmmk_dist">{{ 'TMMK-District' | translate }}</label>
                    <div *ngIf="tmmk_dist; else el">
                      <select onfocus='this.size=tdlen;' class="form-control" formControlName="tmmk_dist"
                        (change)="getbranch(f.tmmk_dist.value)" [ngClass]="{ 'is-invalid': f.tmmk_dist.errors }">
                        <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                        <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id"
                          [selected]="dis.tmmk_dist_id === f.tmmk_dist.value">{{dis.tmmk_district_name}}</option>
                      </select>
                      <div *ngIf="f.tmmk_dist.invalid &&  f.tmmk_dist.touched" class="invalid-feedback">
                        <div *ngIf="f.tmmk_dist.errors.required">
                          {{ 'TMMK-District is Required' | translate }}
                        </div>
                      </div>
                    </div>
                    <ng-template #el>
                      <div>
                        <h5>{{ 'Select District' | translate }}</h5>
                      </div>

                    </ng-template>

                  </div>
                </div>
                <!-- <div class="col-md-6 col-12">
          <div class="form-group" style="margin-top: 20px;" >
            <label for="branch_type">{{ 'Branch Type' | translate }}</label>
            <select  class="form-control" formControlName="branch_type" (change) ="getbranch(f.tmmk_dist.value)" >
                <option [ngValue]="null" disabled>{{ 'Select Branch Type' | translate }}</option>
                <option *ngFor="let bt of btype ; let i = index;" value="{{i+1}}"  >{{ bt | translate  }}</option>
            </select>
        </div>
        </div> -->
                <div class="col-12 col-md-6">

                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="temp_branch">{{ 'Branch' | translate }}</label>

                    <input type="temp_branch" id="temp_branch" class="form-control" formControlName="temp_branch"
                      [ngClass]="{ 'is-invalid': f.temp_branch.errors }">
                    <div *ngIf="f.temp_branch.invalid &&  f.temp_branch.touched" class="invalid-feedback">
                      <div *ngIf="f.temp_branch.errors.required">
                        {{ 'Branch is required' | translate }}
                      </div>
                      <div *ngIf="f.temp_branch.errors.pattern">
                        {{ 'Branch is Invalid' | translate }}
                      </div>
                    </div>

                    <!-- <div *ngIf="branch; else ebb">
            <select  onfocus='this.size=blen;'  class="form-control" formControlName="branch"  (change) ="getward(f.branch.value)"  [ngClass]="{ 'is-invalid': f.branch.errors }">
              <option [ngValue]="null" disabled>{{ 'Select Branch' | translate }}</option>
              <option *ngFor="let dis of branch" [value]="dis.branch_id" [selected]="dis.branch_id == f.branch.value" >{{dis.branch_name}}</option>
          </select>
          <div *ngIf="f.branch.invalid &&  f.branch.touched" class="invalid-feedback">
            <div *ngIf="f.branch.errors.required">
               {{ 'Branch is Required' | translate }}
            </div>
            </div>
          </div>
          <ng-template #ebb>
            <div>
             <h5>{{ 'Select Branch Type' | translate }}</h5>
            </div>
          </ng-template> -->

                  </div>
                </div>
                <!-- <div class="col-12 col-md-6">

          <div class="form-label-group" style="margin-top: 20px;">
            <label for="ward">{{ 'Ward' | translate }}</label>
            <div *ngIf="ward; else llw">
            <select  onfocus='this.size=wlen;'   class="form-control" formControlName="ward" [ngClass]="{ 'is-invalid': f.ward.errors }">
              <option [ngValue]="null" disabled>{{ 'Select Ward' | translate }}</option>
              <option *ngFor="let dis of ward" [value]="dis.ward_id" [selected]="dis.ward_id == f.ward.value" >{{dis.ward_name}}</option>
          </select>
          <div *ngIf="f.ward.invalid &&  f.ward.touched" class="invalid-feedback">
            <div *ngIf="f.ward.errors.required">
               {{ 'Ward is Required' | translate }}
            </div>
            </div>
          </div>
          <ng-template #llw>
            <div>
             <h5>{{ 'Select Branch' | translate }}</h5>
            </div>
          </ng-template>

          </div>

        </div> -->
                <div class="col-12 col-md-6">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="pincode">{{ 'Pincode' | translate }}</label>
                    <input type="number" id="pincode" pattern="[0-9]{6}" minlength='6' placeholder="600001"
                      class="form-control" formControlName="pincode" [ngClass]="{ 'is-invalid': f.pincode.errors }">
                    <div *ngIf="f.pincode.invalid &&  f.pincode.touched" class="invalid-feedback">
                      <div *ngIf="f.pincode.errors.required">
                        {{ 'Pincode is required' | translate }}
                      </div>
                      <div *ngIf="f.pincode.errors.minlength">
                        {{ 'Pincode must be at least 6 numbers long' | translate }}
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-label-group" style="margin-top: 20px;">
                    <label for="img_path">{{ 'Profile Image' | translate }}</label>
                    <div class="group-gap">

                      <div class="avatar-upload" (click)="fileInput.click()">
                        <div class="avatar-edit">
                          <input type='file' id="imageUpload" aaccept="image/*;capture=camera" #fileInput
                            (change)="uploadFile($event)" class="form-control" />
                        </div>
                        <div class="avatar-preview">
                          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div class="row mt-3 text-center">
                <div class="col-2">
                  <input type="checkbox" [(ngModel)]="checkbox" [ngModelOptions]="{standalone: true}" checked>
                </div>
                <div class="col-10">
                  <label for="dec">
                    {{ 'Declaration' | translate }}
                  </label>
                </div>

                <div class="col-12">
                  <a (click)="decmodel(vmodel)" style="text-decoration:underline; color: blue; cursor: pointer;"> {{
                    'Read Terms' | translate }}</a>
                </div>



              </div>

              <ng-template #vmodel let-modal>
                <div class="modal-header bg-gradient-dark">
                  <h2 class="modal-title" id="modal-basic-title" style="color: white; text-decoration: underline;">{{
                    'Terms & Conditions' | translate }}</h2>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="card-body bg-gradient-light">
                    {{ 'rt1' | translate }}
                    <br>
                    {{ 'rt2' | translate }}
                    <br>
                    {{ 'rt3' | translate }}
                    <br>
                    {{ 'rt4' | translate }}
                    <br>
                    {{ 'rt5' | translate }}
                    <br>
                    {{ 'rt6' | translate }}
                    <br>
                    {{ 'rt7' | translate }}
                    <br>
                    {{ 'rt8' | translate }}
                    <br>
                    {{ 'rt9' | translate }}
                  </div>
                </div>
                <div class="card-footer bg-gradient-light">
                  <div class="row">
                    <div class="col-4 offset-8">
                      <button type="button" class="btn bg-darkz" (click)="cancel()"> {{ 'Close' | translate }}</button>
                    </div>
                  </div>
                </div>

              </ng-template>

              <div class="row">

                <div class="col-12 ">
                  <button class="btn btn-secondary  mt-3 float-right" (click)="register(mregForm.value)"
                    [disabled]="!mregForm.valid && !checkbox" type="button" style="color:white;">
                    {{ 'Register' | translate }}
                  </button>

                </div>
              </div>



              <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
            </form>
          </mat-step>

        </mat-vertical-stepper>
        <hr>
        <div class="row text-center">


          <div class="col-12">
            <p class="login-card-footer-text">{{ 'Already a member ?' | translate }}&nbsp;&nbsp;&nbsp;<a
                [routerLink]="['/login']"> {{ 'Login' | translate }}</a></p>


          </div>

        </div>

      </div>
    </div>

    <!-- <div class="content d-block d-md-none">
  <div class="row">
    <div class="col-12 col-md-7 col-lg-5 mx-auto" >
      <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
        <h2 class="card-header text-center bg-gradient-secondary"> {{ 'Contact' | translate }}</h2>


        <div class="card-footer">
          <div class="row text-center">


             <div class="col-12">
             <p>{{ 'Email Address' | translate }} : <b>mmktnelections@gmail.com</b></p>
            <p>{{ 'Mobile Number' | translate }} : <B>9095585988</B> </p>
             </div>
          </div>
        </div>
        </div>
   </div>
 </div>
</div> -->

  </div>


</main>
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>
