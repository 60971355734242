import { HttpClient } from '@angular/common/http';
import { ErrorHandler, TemplateRef, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../Service/api.service';
import { baseURL } from '../Shared/baseurl';
import { Fileup } from '../Shared/fileup';

@Component({
  selector: 'app-userprofup',
  templateUrl: './userprofup.component.html',
  styleUrls: ['./userprofup.component.css']
})
export class UserprofupComponent implements OnInit {

  @ViewChild('sDialog') sDialog: TemplateRef<any>;

  upForm: FormGroup;
  att:string;
  imageUrl: any ;

  randomUrl: any;
  editFile: boolean = true;
  removeUpload: boolean = false;
  districts:Object;
  constituencies:Object;
  dlen: number;
  clen:number;
  tmmk_dist:Object;
 tdlen: number;

  @ViewChild('fileInput') el: ElementRef;
 
  filetype: Fileup;

  profvalue: Object;

  openOtherDialog() {
  
    this.dialog.open(this.sDialog,{
      panelClass: 'my-classc'
    });
   
  }
  constructor(private api: ApiService, private formBuilder: FormBuilder,private httpClient: HttpClient,private router: Router,private spinner: NgxSpinnerService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getpro(); 
  
    if(screen.width<=480){
      document.body.className += 'sidebar-closed sidebar-collapse';
  
    }
    
  }

  getpro(){
    this.spinner.show()
    this.att = 'commonMethods';
    this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_registration').subscribe(p=>{
      this.profvalue = p['data'][0]
      this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_account').subscribe(d=>{
        this.profvalue = Object.assign(this.profvalue,d['data'][0]);
        console.log(this.profvalue)
  
        this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path'] 
        this.upForm = this.formBuilder.group(this.profvalue,[Validators.required]);
        
        this.upForm.patchValue({
          district: this.profvalue['dist_id'],
          tmmk_dist: this.profvalue['tmmk_dist_id'],
          temp_branch: this.profvalue['temp_branch'],
          branch: '',
          ward: '',
          dob:this.profvalue['dob'].split("/").reverse().join("-"),
        })
      
        this.spinner.hide() 
       
      })
     
     
  })  
}
    // this.httpClient.get(baseURL.apiUrl+'assets/'+this.upForm.value.img_path).subscribe((p) => {
    //   // HANDLE file found
    
    //   this.imageUrl= baseURL.apiUrl+'assets/'+this.upForm.value.img_path;
    // }, (err) => {
    //   // HANDLE file not found
    //   if (err.status === 404) {
    //     this.imageUrl= 'assets/images/admin.png';
    //   }
    // });
   
uploadFile(event) {
    
  let reader = new FileReader(); // HTML5 FileReader API
  let file = event.target.files[0];
  let extension = file.name.substring(file.name.lastIndexOf(".")+1);

  if (event.target.files && event.target.files[0]) {
    reader.readAsBinaryString(file);
    reader.onload = () => {
       this.randomUrl = reader.result;
       let encode = btoa(this.randomUrl);
       this.api.serviceData('ImageUpload',{"folder":"users", "image": encode,"name":this.upForm.value.mobile_no + '.' + extension},'Upload','add').subscribe(r=>{
        
        this.imageUrl =  baseURL.apiUrl+'assets/'+r['data']['imagepath'];
      
         this.upForm.patchValue({
          img_path: r['data']['imagepath']

         })
       })
      this.editFile = false;
      this.removeUpload = true;
    } 
  }
}
  update(value){
    console.log(value)
  
    this.att = 'UserDetail';
    this.api.serviceData(this.att,value,'Register','Edit').subscribe((d:any)=>{
      this.upForm.reset()
      if(d['data']['status'] === 'Success'){
        this.openOtherDialog();
        setTimeout(()=>{
          this.dialog.closeAll()
          this.router.navigateByUrl('/user/profile') 
        }, 1000);
         
      }
      else{

      }
     })

  }

  // getdistrict(){
  //   this.att = 'CommonMethods';
  //   this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
  //     this.districts = d['data']
  //     console.log(this.districts)
  //     this.dlen = Object.keys(this.districts).length  
  //   })
  // }

  // getmmkdistrict(value){
  //   this.spinner.show()
  //   if(this.upForm.value.tmmk_dist_id){
  //     this.upForm.controls['tmmk_dist_id'].reset()
  //   }
  //   this.att = 'CommonMethods';
  //   this.api.serviceData(this.att,{district_id:value,method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
  //   this.tmmk_dist = d['data']
  //   this.tdlen = Object.keys(this.tmmk_dist).length
  //   console.log(this.tmmk_dist)
  //   this.spinner.hide() 
  //   })
  // }


  // getConstituency(){
  //   this.att = 'CommonMethods';
  //   this.api.serviceData(this.att, { method:'getConstituency'},'commonMethod','getData').subscribe((d:any)=>{
  //     this.constituencies = d['data']
  //     console.log(this.constituencies)
  //     this.clen = Object.keys(this.constituencies).length  
  //   })
  // }
  get f() { return this.upForm.controls; }
}
