<main class="d-flex align-items-center  py-3 py-md-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="col-12 col-md-7 col-lg-5 mx-auto">
          <div class="card bg-gradient-light my-5 mt-2">
            <h5 class=" card-header text-center"> {{ 'Change Password' | translate }}</h5>
            <div class="card-body">
              <form [formGroup]="passreset">
                <div class="form-group">
                  <label>{{ 'Old Password' | translate }}</label>
                  <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control" />

                </div>

                <div class="form-group">
                  <label>{{ 'New Password' | translate }}</label>
                  <input formControlName="newPwd" id="newPwd" type="password" class="form-control" />

                </div>

                <div class="form-group">
                  <label> {{ 'Confirm Password' | translate }}</label>
                  <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />


                </div>

                <button class="btn btn-primary" (click)="change()">{{ 'Change Password' | translate }}</button>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>




<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="../../assets/images/flag.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              {{ 'Language' | translate }} :
              <select #langSelect (change)="changeLang(langSelect.value)"
                style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                  [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
              </select>
            </div>
            <form [formGroup]="passreset">

              <div class="form-group">
                <label>{{ 'Old Password' | translate }}</label>
                <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control" />

              </div>

              <div class="form-group">
                <label>{{ 'New Password' | translate }}</label>
                <input formControlName="newPwd" id="newPwd" type="password" class="form-control" />

              </div>

              <div class="form-group">
                <label> {{ 'Confirm Password' | translate }}</label>
                <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />


              </div>
              <button class="btn btn-primary" (click)="change()">{{ 'Change Password' | translate }}</button>

            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>
