<app-admin-header></app-admin-header>
<div class="content-wrapper" style="min-height: 2171.6px;">
  <router-outlet></router-outlet>
</div>
<!-- <footer class="main-footer">
    <div class="float-right d-none d-sm-block">

      <b>Version</b> 3.1.0
    </div>
    <strong>Copyright &copy; 2021-2022 <a href="www.mmkinfo.in" target="_blank">MMKINFO.in</a>.</strong> All rights reserved.
</footer> -->
