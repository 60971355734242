import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-pay-model',
  templateUrl: './pay-model.component.html',
  styleUrls: ['./pay-model.component.css']
})
export class PayModelComponent implements OnInit {
  pl: string;

  @ViewChild('form') form: ElementRef;

  constructor(private api: ApiService, private router: Router, private http: HttpClient, public fb: FormBuilder,
    public translate: TranslateService) {
    this.pl = sessionStorage.getItem('payurl');

  }


  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }


  ngOnInit(): void {





  }
  Cancel() {
    this.router.navigateByUrl('/login')
  }
  submitForm() {
    window.location.href = this.pl;



  }


}
