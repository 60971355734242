<app-lheader></app-lheader>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="col-12 col-md-7 col-lg-5 mx-auto">
                <div class="card bg-gradient-light my-5 mt-2">
                  <h5 class=" card-header bg-darkz text-center"> {{ 'OTP' | translate }}</h5>
                  <div class="card-body ">
                    <form [formGroup]="vrfyform" (ngSubmit)="vrfyotp(vrfyform.value)">
                        <div class="form-group">
                         
                          <label for="otp">{{ 'OTP' | translate }}</label>
                          <input type="number" id="otp" class="form-control"   formControlName="otp" >
                          <div *ngIf="f.otp.invalid &&  f.otp.touched" class="alert alert-danger">
                            <div *ngIf="f.otp.errors.required">
                              {{ 'OTP  is required' | translate }}
                            </div>
                      
                          </div>
                       
                          
                        </div>
            
                        <input class="btn btn-lg  bg-darkz btn-info btn-block  mt-3" type="submit" style="color:white;"  value="{{ 'Submit' | translate }} ">
            
            
                        </form>

                  </div>
                  </div>
            </div>
        </div>
    </div>
</div>
