<app-lheader></app-lheader>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <ng-template #sDialog >
            <h2 matDialogTitle> Authentication</h2>
            <div class="mt-2"></div>
            <p class="text-center" matDialogContent><em>Login Success !!</em></p>
            <div class="mt-2 text-center">
              <i class="fa fa-thumbs-up fa-3x"></i>
            </div>
          </ng-template>

          </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-5 col-lg-7 d-none d-sm-inline-block">
            <img src="/assets/images/mmklogo.jpg" class="img-fluid" alt="logo">
        </div>
        <div class="col-12 col-md-7 col-lg-5 mx-auto">
          <div class="card bg-gradient-light my-5 mt-2">
            <h5 class=" card-header bg-darkz text-center">{{ 'MMK' | translate }}</h5>
            <div class="card-body">
             
              <form [formGroup]="logForm" (ngSubmit)="login(logForm.value)">
                <div class="form-group">
                    <label for="mobile">{{ 'Mobile Number' | translate }}</label>
                    <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">{{ 'Password' | translate }}</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                
                <!-- <div class="form-group d-none">
                    <div class="row">
                    <div class="col-2 mt-1">
                        <input type="checkbox" formControlName="rememberMe" class="form-control">
                    </div>
                   <div class="col-10">
                    <label>Remember me</label>
                   
                   </div>
                </div>
                        
                </div> -->

                <div class="form-group">
                    <div class="row">
                        <div class="col-12">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                     {{ 'Login' | translate }}
                    </button>
                    </div>
                    <!-- <div class="col-6">
                    <a routerLink="/admin/passreset" class="btn btn-info"> {{ 'Reset' | translate }}</a>
                 </div> -->

                 </div>
                 </div>
                
                 <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
              </form>

              
              
            </div>
          </div>
        </div>
      </div>
</div>
