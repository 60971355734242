import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../Service/api.service';
import { AuthenticationService } from '../Service/authentication.service';
import { baseURL } from '../Shared/baseurl';

@Component({
  selector: 'app-userdash',
  templateUrl: './userdash.component.html',
  styleUrls: ['./userdash.component.css']
})
export class UserdashComponent implements OnInit {

  prof: any;
  profvalue: Object;
  att: string;
  pp: any;
  imageUrl:string;
  paylink: string;


  

  constructor(public translate: TranslateService,private router: Router, private api: ApiService, private authserver: AuthenticationService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.getpro();
    
    if(sessionStorage.getItem('paylink')){
      //this.spinner.show()
      this.paylink = sessionStorage.getItem('paylink');
      //this.spinner.hide()
    }  
    
    if(screen.width<=480){
    document.body.className += 'sidebar-closed sidebar-collapse';

  }
  }

getpro(){
  this.spinner.show()
  this.att = 'commonMethods';
  this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_registration').subscribe(p=>{
    this.profvalue = p['data'][0]
    this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_account').subscribe(d=>{
      this.profvalue = Object.assign(this.profvalue,d['data'][0]);

      console.log(this.profvalue)
      this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path'] 
    
      this.spinner.hide() 
    })
   
    
})  
}
}
