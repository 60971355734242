

  <div class="container">
      <div class="row d-flex justify-content-center" style="margin-top: 27%;">
          <div class="col-12">
            <div class="error-page mt-4">
                <h1 class="headline" style="color: red;"> 404</h1>
          
                <div class="error-content">
                  <h3><i class="fas fa-exclamation-triangle" style="color: red;"></i> {{ 'Oops! Page not found' | translate }}</h3>
          
                <a href="/login"><h3 class="text-center">{{ 'Return to dashboard or Login' | translate }}</h3></a> 
                
         
                </div>
              </div>
          </div>
      </div>
  </div>

 