<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card p-4">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="../../assets/images/flag.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              {{ 'Language' | translate }} :
              <select #langSelect (change)="changeLang(langSelect.value)"
                style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                  [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
              </select>
            </div>
            <form [formGroup]="fgpForm" *ngIf="fgpForm" (ngSubmit)="send(fgpForm.value)">

              <div class="form-group">
                <!-- <label for="password">{{ 'New Password' | translate }}</label> -->
                <span class="input-placeholder">
                  <input style="float: left" type="password" formControlName="password"
                    placeholder="{{ 'New Password' | translate }}" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [type]="hide ? 'password' : 'text'" />
                </span>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ 'Password is required' | translate }}</div>
                </div>
              </div>

              <div class="row text-center mt-3">
                <div class="col-12">
                  <input class="btn w-100   bg-darkz btn-info  mt-3" type="submit" style="color:white;"
                    value="{{ 'Update' | translate }}">
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>

<app-lfooter></app-lfooter>
