<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="../../assets/images/flag.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              {{ 'Language' | translate }} :
              <select #langSelect (change)="changeLang(langSelect.value)"
                style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                  [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
              </select>
            </div>
            <p class="login-card-description">{{ 'Forget Password' | translate }}</p>
            <form [formGroup]="otpForm">
              <p>Please enter your email & mobile number to recieve token</p>
              <div class="form-group">
                <label for="mail_id">{{ 'Mobile Number' | translate }}</label>
                <!-- <label for="mobile_no">{{ 'Mobile Number' | translate }}</label> -->
                <input type="text" formControlName="mobile_no" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }"
                  placeholder="{{ 'Mobile Number' | translate }}" />
                <div *ngIf="submitted && f.mobile_no.errors" class="invalid-feedback">
                  <div *ngIf="f.mobile_no.errors.required">{{ 'Mobile is required' | translate }}</div>
                </div>
              </div>
              <div class="form-group ">
                <label for="mail_id">{{ 'Email Address' | translate }}</label>
                <div class="input-group">
                  <input type="mail_id" id="mail_id" class="form-control" formControlName="mail_id"
                    placeholder="{{ 'Email Address' | translate }}">
                  <div *ngIf="f.mail_id.invalid &&  f.mail_id.touched" class="invalid-feedback">
                    <div *ngIf="f.mail_id.errors.required">
                      {{ 'Email is required' | translate }}
                    </div>
                    <div *ngIf="f.mail_id.errors.pattern">
                      {{ 'Email is Invalid' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <button class="btn btn-secondary float-right w-100 mb-3" (click)="sendotp(otpForm.value)"
                  [disabled]="!otpForm.valid" type="submit" style="color:white;">{{ 'Submit' | translate }}
                </button>
                <p class="login-card-footer-text mt-4 text-right">{{ 'GoBacktoLogin' | translate }}&nbsp;&nbsp;&nbsp;<a
                    [routerLink]="['/login']" style="text-decoration: underline;"> {{ 'Login' | translate }}</a></p>
                <!-- <a routerLink="/login" class="btn btn-default  m-1 float-left w-100">Cancel</a> -->
              </div>
            </form>

            <nav class="login-card-footer-nav">
              <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>
<app-lfooter></app-lfooter>
