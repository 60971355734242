import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';
import { SnackbarService } from 'src/app/Service/snackbar.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-mmk-mas',
  templateUrl: './mmk-mas.component.html',
  styleUrls: ['./mmk-mas.component.css']
})
export class MmkMasComponent implements OnInit {

  districts:Object;
  dismasForm: FormGroup;
  submitted = false;
  datasource: any;
  getvalForm: FormGroup;
  att: string;
  stat: string[] = ["Y", "N"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('TABLE1') table1: ElementRef;
  loading = false;
  constructor(private api: ApiService, private formBuilder: FormBuilder,private spinner: NgxSpinnerService,private sb:SnackbarService) { }

  displayedColumns: string[] = ['tmmk_district_name','status','Edit'];
  ngOnInit(): void {
   
    this.getdistrict()
    this.getData(); 

    this.dismasForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: ['Y', Validators.required],
      id: [''],
      short_name: ['',Validators.required],
      mapped_data: ['',Validators.required],
      method: ['insertMMKDistrict', Validators.required]
      // rememberMe: new FormControl(false)
  });

   
  }

  exportTable(){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet
    (this.table1.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
    XLSX.writeFile(wb,'Report.xlsx'); 
  }

  add(value){

    if (value.id){
      this.att = 'MasterData';
      this.api.serviceData(this.att,value,'insertMMKDistrict','Edit').subscribe((d:any)=>{
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.dismasForm.controls['mapped_data'].reset()
          this.dismasForm.controls['status'].reset()
          this.dismasForm.controls['id'].reset()
          this.getData();
        //   window.location.reload()
        }
      
      })

    }
    else{
      this.att = 'MasterData';
      this.api.serviceData(this.att,value,'insertMMKDistrict','Insert').subscribe((d:any)=>{
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.getData();
          // window.location.reload()
        }
      
      })
    }
    

   
  }

  getdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.districts = d['data']
     
     
    })
  }

  edit(value){

    this.dismasForm = this.formBuilder.group({
      name: new FormControl(value.tmmk_district_name, Validators.required),
      status: new FormControl(value.status, Validators.required),
      id: new FormControl(value.tmmk_dist_id,Validators.required),
      short_name: new FormControl(value.tmmk_district_short_name,Validators.required),
      method: new FormControl('insertMMKDistrict', Validators.required),
      mapped_data : new FormControl(value.district_id,Validators.required),
      
      // rememberMe: new FormControl(false)
  });

  

  }
  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }

  getData(){
    this.spinner.show()
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.datasource = new MatTableDataSource(d['data'].reverse());

      setTimeout(() => {
        // this.paginator._intl.itemsPerPageLabel="Test String";
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }, 500);
      this.spinner.hide()
     
     
    })
  }

  delete(value){
    // this.att = 'CommonMethods';

    // this.api.serviceData(this.att,value,'deleteDistrict','Delete').subscribe((d:any)=>{
    //   console.log(d)
    //  alert(d.msg)
    // })
  }

  applyFilter(filterValue : string) {

    //const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    // alert(this.datasource.filter = filterValue.trim().toLowerCase())
  }
  get f() { return this.dismasForm.controls; }

 
 

}
