import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, mergeMapTo } from 'rxjs/operators';
import { ApiService } from 'src/app/Service/api.service';
import { SnackbarService } from 'src/app/Service/snackbar.service';
import { baseURL } from 'src/app/Shared/baseurl';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.css']
})
export class ArchivesComponent implements OnInit {
  att: string;
  roles: Object;
  FilterForm: FormGroup;
  datasource: any;
  status: string[] = ['Approved'  ,'Not_Approved','Rejected','Paid','Not_Paid'];
  displayedColumns: string[] = ['name','updated_date','approved_status','mmk_mem_id','download'];
  displayedColumns1: string[] = ['name','father_name','gender','mobile_no','dob','email_id','address','country','pincode','mmk_mem_id','blood_group','district_name','tmmk_district_name','branch_name','ward_name','approved_status','payment_status'];
  options: string[] = ["A+", "B+", "AB+","O+","A-", "B-", "AB-",'O-'];
  btype: string[] = ['Villagepanchayat','Townpanchayat','Muncipality','Corporation']

  mregForm: FormGroup;
  closeResult:any;
  profvalue:any;
  imageUrl:string;
 
  districts:Object;
  dlen: number;
  branch: Object;
  blen: number
  tmmk_dist:Object;
  tdlen: number = 0;
  ward: Object;
  wlen:number;
  rlen: number;
  profDet: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE1') table1: ElementRef;

  constructor(private api: ApiService,private sb: SnackbarService,
     private formBuilder: FormBuilder, private router: Router ,private modalService: NgbModal,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    // ngAfterViewInit (){
    //   this.datasource.sort = this.sort;
    // }
    this.getdistrict();
    this.getData();
    this.getRole();
    this.FilterForm = this.formBuilder.group({
      district : new FormControl(null),
      tmmk_dist : new FormControl(null),
      branch : new FormControl(null),
      branch_type: new FormControl(null),
      start_date:new FormControl(''),
      end_date: new FormControl(''),
      filter: new FormControl(''),
      status: new FormControl(null),
      ward: new FormControl(null),
      role: new FormControl(null)

    })
    if(screen.width<=480)
    document.body.className += 'sidebar-closed sidebar-collapse';

  }

 async getData(value=""){
    
    if(value){
      let mval:any;
      this.att = 'commonMethods';

    await  this.api.serviceData(this.att,value,'commonMethod','member_registration').subscribe(p=>{
        mval = p['data']
        if(mval){
          this.api.serviceData(this.att,value,'commonMethod','member_account').subscribe(d=>{
           
            this.profvalue = mval.map((item, i) => Object.assign({}, item, d['data'][i]));
            this.datasource = new MatTableDataSource(this.profvalue.reverse());
            console.log(this.datasource)
           
            
              this.datasource.sort = this.sort;
              this.datasource.paginator = this.paginator;
              
       
             
            

            this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path']  
           

          })
        }   
      })
    
    
    }
    else{
     let  fval:any;
    this.att = 'CommonMethods';
    
     await this.api.serviceData(this.att,{status: 'Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_registration').subscribe((d:any)=>{
      fval = d['data']
      if(fval){
         this.api.serviceData(this.att,{status: 'Approved',filter:'Y' ,method:'getdata'},'commonMethod','member_account').subscribe(d=>{
          this.profvalue = fval.map((item, i) => Object.assign({}, item, d['data'][i]));
          this.datasource = new MatTableDataSource(this.profvalue.reverse());
         
        
              this.datasource.paginator = this.paginator;
              this.datasource.sort = this.sort;
         
           
      
          this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path']
        
        })
      }
    })
  }
  }


  getfiltervalue(value){
    value["filter"]='Y';
    value["method"]='getdata';
    this.getData(value)
  }

  view(content,ele){
   
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) =>{
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
    );
  }

  vup(con,value){
    
       this.mregForm = this.formBuilder.group({
        mobile_no: [value.mobile_no, Validators.required],
        user_id: [value.member_acc_id,Validators.required],
        user_prev:[value.prev,Validators.required],
        method: ['change_prev',Validators.required]
        })


    this.modalService.open(con, {ariaLabelledBy: 'modal-basic-title',size: 'md'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) =>{
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getbranch(value,branchtype){
    console.log(value)
    console.log(branchtype)
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{mmk_dist_id:value,branchtype:branchtype,method:'getBranch'},'commonMethod','getData').subscribe((d:any)=>{
      this.branch = d['data']
      console.log(this.branch)
      this.blen = Object.keys(d['data']).length
    
     
    })
  }
  getmmkdistrict(value){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{district_id:value,method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.tmmk_dist = d['data']
      this.tdlen = Object.keys(d['data']).length
   
     
    })

  }

  getRole(){
    this.att = 'commonMethods';
    this.api.serviceData(this.att,{method:'getRole'},'commonMethod','getRole').subscribe((d:any)=>{
      this.roles = d['data']
      this.rlen = Object.keys(d['data']).length
     
      
    });
  }

  download(value){
    console.log(value);
    // this.profDet = value;
    sessionStorage.setItem('cardet',JSON.stringify(value));
    console.log(sessionStorage.setItem('cardet',JSON.stringify(value)));
    setTimeout(() => {
     
      this.router.navigateByUrl('admin/profdownload')
    }, 500);
    
  }

  getward(value){
   
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{branch_id:value, method:'getWard'},'commonMethod','getData').subscribe((d:any)=>{
      this.ward = d['data']
     
      this.wlen = Object.keys(d['data']).length
     
   
    })
  }
  getdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.districts = d['data']
      this.dlen = Object.keys(d['data']).length
    
     
    })
  }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }
  reset(){
    this.FilterForm.reset()
  }
  get g() { return this.FilterForm.controls; }

  get f() { return this.mregForm.controls; }
  exportTable(){
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet
    (this.table1.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Sheet1');
    XLSX.writeFile(wb,'Report.xlsx'); 
  }

  update(value){
    
   
    this.att = 'UserDetail';
    this.api.serviceData(this.att,value,'change_prev','member_registration').subscribe((d:any)=>{
      if(d['data']['status'] === 'Success'){
        this.sb.success(d['data']['msg'])
        //this.sb.open(d['data']['msg'],'Dismiss',{duration:5})
        this.getData();
        this.mregForm.reset()
        this.modalService.dismissAll()
      }
      else{
        this.sb.error(d['data']['msg'])
        //this.sb.open(d['data']['msg'],'Dismiss',{duration:2})
      } 
    })
  }

 

  cancel(){
    this.modalService.dismissAll();
  }

}
