import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';

import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

import { ApiService } from '../Service/api.service';
import { baseURL } from '../Shared/baseurl';
import { Router } from '@angular/router';


@Component({
  selector: 'app-memcard',
  templateUrl: './memcard.component.html',
  styleUrls: ['./memcard.component.css']
})
export class MemcardComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  @ViewChild('screen') screen: ElementRef;
  // @ViewChild('screen2') screen2: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  // @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  // @ViewChild('downloadLink2') downloadLink2: ElementRef;
  base_url = 'https://members.mmktnelections.com/mmk/qrprofile/?memberId=';

  att: string;
  profvalue: Object;
  imageUrl: string;
  pp: string;
  age: number;
  last_date: any;

  constructor(private api: ApiService,
    private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.getpro()

    if (screen.width <= 480) {
      document.body.className += 'sidebar-closed sidebar-collapse';

    }



  }


  downloadImage() {

    html2canvas(document.getElementById("printsection"), { allowTaint: false, useCORS: true, canvas: this.canvas.nativeElement.src, scale: 5 }).then(canvas => {
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.profvalue['mmk_mem_id'] + '.png';
      this.downloadLink.nativeElement.click();
    });

  }

  getpro() {
    this.spinner.show()
    this.att = 'commonMethods';
    this.api.serviceData(this.att, { user_id: sessionStorage.getItem('user_id'), method: "getsingledata" }, 'commonMethod', 'member_registration').subscribe(p => {
      this.profvalue = p['data'][0]
      this.api.serviceData(this.att, { user_id: sessionStorage.getItem('user_id'), method: "getsingledata" }, 'commonMethod', 'member_account').subscribe(d => {
        this.profvalue = Object.assign(this.profvalue, d['data'][0]);

        this.imageUrl = baseURL.apiUrl + 'assets/' + this.profvalue['img_path']
        this.age = this.getAge(this.profvalue['dob'])
        var year = new Date(this.profvalue['approve_one_date']).getFullYear();
        var month = new Date(this.profvalue['approve_one_date']).getMonth();
        var day = new Date(this.profvalue['approve_one_date']).getDate();
        this.last_date = new Date(year + 2, month, day)
        console.log(this.profvalue)


      })

      this.spinner.hide()
    })
  }


  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }



  // function to convert img url to base64
  toDataURL(src, callback, outputFormat) {
    let image = new Image();
    image.crossOrigin = 'Anonymous';
    var dpr = window.devicePixelRatio || 1;
    image.onload = function () {
      let dimension = <HTMLImageElement>document.getElementById('myImg');

      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = Math.ceil(dimension.height * 500);
      canvas.width = Math.ceil(dimension.width * 500);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      dataURL = canvas.toDataURL(outputFormat, dpr * 300);
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      // image.src = "https://i.imgur.com/rAsrKG7.jpg";
      image.src = src;
    }
  }

  // functition to generate pdf
  public captureScreen() {

    var data = document.getElementById('printsection');
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>' + this.profvalue["mmk_mem_id"] + '</title><style type="text/css" > @media print {@page {size: 86mm 54.2mm  ; margin:0 !important;font-weight:900 !important } body{font-family: Arimo, sans-serif !important;font-weight:900 !important}} .bshadow > img{height: 65px !important;}.page-break{ display:block; page-break-before:auto;margin-top 0% !important;page-break-after:avoid; -webkit-print-color-adjust: exact; } </style></head>';
    windowContent += '<body style="margin: 0 !important;">';
    windowContent += data.innerHTML;
    windowContent += '</body>';
    windowContent += '</html>';

    const printWin = window.open('', '_top', '');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();


    window.history.go(0);
    //location.reload();


    // printWin.document.addEventListener('load', function ($event) {
    //   printWin.focus();
    //   printWin.print();
    //   console.log($event);

    //   printWin.onafterprint = function () {
    //     console.log("sssssssssssss");
    //     window.close();

    //     printWin.document.close()
    //   }

    //   // printWin.document.close();
    //   // printWin.close();
    // }, true);
    //   var data = document.getElementById('contentToConvert');
    //   var HTML_Width = 86;
    //   var HTML_Height = 54;
    // var top_left_margin = 0;
    // var PDF_Width = HTML_Width;
    // var PDF_Height = HTML_Height;
    // var canvas_image_width = HTML_Width;
    // var canvas_image_height = HTML_Height;

    // const pag = [48,86];
    // var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;




    // html2canvas(data.getElementsByTagName("section")[0],{allowTaint:true}).then(function(canvas) {
    // 	canvas.getContext('2d');
    // 	var imgData = canvas.toDataURL("image/jpeg", 1.0);
    // 	var pdf = new jsPDF('l', 'em', pag);
    //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //       // for (var i = 0; i <= totalPDFPages; i++) {
    //       // 	pdf.addPage(pag, 'l');
    //       //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //       // }
    //       html2canvas(data.getElementsByTagName("section")[1],{allowTaint:true}).then(function(canvas2) {
    //         var imgData2 = canvas2.toDataURL("image/jpeg", 1.0);
    //         pdf.addPage(pag, 'l');
    //         pdf.addImage(imgData2, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //         pdf.save("HTML-Document.pdf");
    //       });
    //     // pdf.save("HTML-Document.pdf");
    //    });

  }

  download() {
    html2canvas(document.getElementById("printsection"), { allowTaint: false, useCORS: false, canvas: this.canvas.nativeElement.src, scale: 1 }).then(function (canvas) {
      canvas.getContext('2d');
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      console.log(imgData);
      var doc = new jsPDF('p', 'mm', [86, 108]);
      // Save the PDF
      doc.addImage(imgData, 'JPG', 0, 0, 86, 108);
      doc.save('Test.pdf');
    });
  }




}
