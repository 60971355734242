import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';
import { SnackbarService } from 'src/app/Service/snackbar.service';

@Component({
  selector: 'app-role-mas',
  templateUrl: './role-mas.component.html',
  styleUrls: ['./role-mas.component.css']
})
export class RoleMasComponent implements OnInit {

  datasource: any;
  att: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  dismasForm: FormGroup;
  submitted = false;
  loading = false;
  constructor(private api: ApiService, private formBuilder: FormBuilder,private spinner: NgxSpinnerService, private sb: SnackbarService) { }

  displayedColumns: string[] = ['user_prev_name','user_prev_id','status','Edit'];
  ngOnInit(): void {
    this.getData();


    this.dismasForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      status: new FormControl('Y', Validators.required),
      id: new FormControl(''),
      method: 'insertRole'
      // rememberMe: new FormControl(false)
  });
  }

  add(value){

    if (value.id){
      this.att = 'MasterData';

      this.api.serviceData(this.att,value,'insertRole','Edit').subscribe((d:any)=>{
    
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.getData();
        }
        
      
      })

    }
    else{
    
      this.att = 'MasterData';

      this.api.serviceData(this.att,value,'insertRole','Insert').subscribe((d:any)=>{
        if(d['data']['status'] === 'Error' ){
          this.sb.error(d['data']['msg'])
        }
        else{
          this.sb.success(d['data']['msg'])
          this.dismasForm.controls['name'].reset()
          this.getData();
        }
        
      
      })
    }
    

   
  }

  edit(value){
    this.dismasForm = this.formBuilder.group({
      name: new FormControl(value.user_prev_name, Validators.required),
      status: new FormControl(value.status, Validators.required),
      id: new FormControl(value.user_prev_id,Validators.required),
      method: new FormControl('insertRole', Validators.required)
      
      // rememberMe: new FormControl(false)
  });

  
  
  }

  public doFilter = (value: string) => {
    this.datasource.filter = value.trim().toLocaleLowerCase();
  }
  getData(){
    this.att = 'commonMethods';
    this.api.serviceData(this.att,{method:'getRole'},'commonMethod','getRole').subscribe((d:any)=>{
    this.datasource = new MatTableDataSource(d['data'].reverse());
      setTimeout(() => {
        // this.paginator._intl.itemsPerPageLabel="Test String";
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }, 500); 
    })
  }

  delete(value){
    // this.att = 'CommonMethods';

    // this.api.serviceData(this.att,value,'deleteDistrict','Delete').subscribe((d:any)=>{
    //   console.log(d)
    //  alert(d.msg)
    // })
  }

  applyFilter(filterValue : string) {

    //const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    // alert(this.datasource.filter = filterValue.trim().toLowerCase())
  }
  get f() { return this.dismasForm.controls; }

}
