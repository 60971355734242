import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../Service/api.service';
import { AuthenticationService } from '../Service/authentication.service';
import { SnackbarService } from '../Service/snackbar.service';

@Component({
  selector: 'app-userforgetpass',
  templateUrl: './userforgetpass.component.html',
  styleUrls: ['./userforgetpass.component.css']
})
export class UserforgetpassComponent implements OnInit {

  att: string;

  currentRoute: string;

  submitted: string;

  fgpForm: FormGroup;
  constructor(private fb: FormBuilder, private api: ApiService, private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService, private authenticationService: AuthenticationService,
    private sb: SnackbarService) {
    translate.addLangs(['English', 'தமிழ்']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/) ? browserLang : 'தமிழ்');

    }
    else {
      localStorage.setItem('locale', 'தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['user/profile'])
    }


  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.fgpForm = this.fb.group({
        password: ['', Validators.required],
        enc_data: [p['token'], Validators.required],
        method: ['change_password', Validators.required]
      })

    })





  }

  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }

  send(value) {
    this.att = 'UserDetail';
    this.api.serviceData(this.att, value, 'change_password', 'change_password').subscribe((d: any) => {
      if (d['data']['status'] === 'Success') {
        this.sb.success(d['data']['msg'])
        this.router.navigateByUrl('/login')
      }
    })

  }

  get f() {
    return this.fgpForm.controls
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

}
