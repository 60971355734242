import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../Service/authentication.service';

@Component({
  selector: 'app-passreset',
  templateUrl: './passreset.component.html',
  styleUrls: ['./passreset.component.css']
})
export class PassresetComponent implements OnInit {
  passreset: FormGroup;
  constructor(fb: FormBuilder, public translate: TranslateService,
    private authenticationService: AuthenticationService, private router: Router,) {
    translate.addLangs(['English', 'தமிழ்']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/) ? browserLang : 'தமிழ்');

    }
    else {
      localStorage.setItem('locale', 'தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['user/profile'])
    }
    this.passreset = fb.group({
      'oldPwd': ['', Validators.required],
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    });

  }


  ngOnInit(): void {
  }

  change() {

  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

}
