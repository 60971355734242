<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<!-- <nav class="navbar navbar-expand-md "
  style=" background: linear-gradient(130deg,#000000,#adabac 41.07%,#ffffff 76.05%);height: 100px;">
  <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">

  </div>
  <div class="mx-auto order-0">
    <a class="navbar-brand mx-auto" href="#"><img src="../../../assets/logommk.png" style="height: 100px;"
        class="img-fluid" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a routerLink="/login" class="btn btn-primary mr-3"> {{ 'Login' | translate }}</a>
      </li>
      <li class="nav-item">
        {{ 'Language' | translate }} :
        <select #langSelect (change)="changeLang(langSelect.value)"
          style="margin-left: 10px;border-radius: 5px;padding: 5px;">

          <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
            {{
            lang | translate }}</option>
        </select>
      </li>
    </ul>
  </div>
</nav> -->


<nav class=" text-center" style=" background: linear-gradient(130deg,#000000,#adabac 41.07%,#ffffff 76.05%);">
  <img src="../../assets/logommk.png" class="img-fluid" alt="">
</nav>
