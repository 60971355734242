import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lheader',
  templateUrl: './lheader.component.html',
  styleUrls: ['./lheader.component.css']
})
export class LheaderComponent implements OnInit {

  constructor(public translate: TranslateService) { 
    translate.addLangs(['English','தமிழ்']);
    if(localStorage.getItem('locale')){
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்"/)? browserLang: 'தமிழ்');
  
    }
    else{
      localStorage.setItem('locale','தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    }
  

  changeLang(language: string){
    localStorage.setItem('locale',language);
    this.translate.use(language);
  }

  ngOnInit(): void {
  }



}
