<app-lheader></app-lheader>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="col-12 col-md-7 col-lg-5 mx-auto">
                <div class="card bg-gradient-light my-5 mt-2">
                  <h5 class=" card-header bg-darkz text-center"> {{ 'OTP' | translate }}</h5>
                  <div class="card-body ">
                   
                    <form [formGroup]="otpForm">
                      <div class="form-group justify-content-center">
                          <label for="mobile_number">  {{ 'Mobile Number' | translate }}</label>
                          &nbsp;&nbsp;
                          <input type="text" id="mobile_number" formControlName="mobile_number"   class="form-control" placeholder="8608989111" />
                          <div *ngIf="f.mobile_number.invalid &&  f.mobile_number.touched" class="alert alert-danger">
                            <div *ngIf="f.mobile_number.errors.required">
                              {{ 'Mobile Number  is required' | translate }}
                            </div>
                            <div *ngIf="f.mobile_number.errors.minlength">
                              {{ 'Mobile Number must be at least 10 numbers long' | translate }}
                           </div>
                           <div *ngIf="f.mobile_number.errors.maxlength">
                              {{ 'Mobile Number should be 12 numbers long' | translate }}
                           </div>
                            </div>
                      </div>

                      <button class="btn btn-SM  bg-darkz    mt-3" (click)="sendotp(otpForm.value)" [disabled]="!otpForm.valid" type="submit" style="color:white;">{{ 'Submit' | translate }}
</button>

                      </form>

                      </div>
                      </div>
                      </div>
        </div>
    </div>
  
</div>