import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../Shared/User';
import { res } from '../Shared/res';
import { baseURL } from '../Shared/baseurl';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    value: any;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
}

login(data,actionModule,action) {
  const httpOptions = {
    headers: new HttpHeaders({
    })
  };
 
    const formData = new FormData();
    formData.append("reqData",btoa(btoa(btoa((JSON.stringify({data,actionModule,action}))))));
   return this.http.post<any>(`${baseURL.apiUrl}`+'UserDetail', formData,httpOptions)
      .pipe(map(user => {
        sessionStorage.setItem('user', JSON.stringify(user['data']));
        this.currentUserSubject.next(user['data']);
        return user;
      }));
}

logout(data,actionModule,action) {
  const httpOptions = {
    headers: new HttpHeaders({
    })
  };

  const formData = new FormData();
  formData.append("reqData",btoa(btoa(btoa((JSON.stringify({data,actionModule,action}))))));

  return this.http.post<res>(`${baseURL.apiUrl}`+'UserDetail' , formData,httpOptions).subscribe(res=>{
    console.log(res)
    sessionStorage.clear()
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('prev');
    sessionStorage.removeItem('payurl');
    sessionStorage.removeItem('cardet');
    sessionStorage.removeItem('paylink');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  });
  
}

}
