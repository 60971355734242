import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/Service/api.service';
import { AuthenticationService } from 'src/app/Service/authentication.service';
import { baseURL } from 'src/app/Shared/baseurl';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('sDialog') sDialog: TemplateRef<any>;


  prof: any;
  profvalue: {};
  att: string;
  pp: any;
  imageUrl:string;


  openOtherDialog() {
  
    this.dialog.open(this.sDialog,{
      panelClass: 'my-classc'
    });
   
  }

  constructor(public translate: TranslateService,private router: Router, private api: ApiService, private authserver: AuthenticationService,private spinner: NgxSpinnerService, private dialog: MatDialog) { 
    translate.addLangs(['English','தமிழ்']);
    if(localStorage.getItem('locale')){
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/)? browserLang: 'தமிழ்');
  
    }
    else{
      localStorage.setItem('locale','தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    }
  

  changeLang(language: string){
    localStorage.setItem('locale',language);
    this.translate.use(language);
  }

  ngOnInit(): void {
    this.getpro()
  }

  logout(){
     this.authserver.logout({method:'logout'},'Logout','logout')
       this.openOtherDialog();
       setTimeout(()=>{
       this.dialog.closeAll()
       this.router.navigateByUrl('/login')
       }, 500);
      }
   
      getpro(){
      this.spinner.show()
      this.att = 'commonMethods';
      this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_registration').subscribe(p=>{
      this.profvalue = p['data'][0]
      this.api.serviceData(this.att,{user_id: sessionStorage.getItem('user_id'), method: "getsingledata" },'commonMethod','member_account').subscribe(d=>{
      this.profvalue = Object.assign(this.profvalue,d['data'][0]);
      this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path'] 
      })
      this.spinner.hide() 
      }) 
        
    } 
}
