<main class="d-flex align-items-center py-3 py-md-0">
  <div class="container col-lg-4 col-12">
    <div class="card login-card">
      <div class="row no-gutters">
        <!-- <div class="col-md-5">
        </div> -->
        <div class="col-12">
          <img src="../../assets/images/flag.jpg" style="opacity: 0.1;" alt="login" class="login-card-img">
          <div class=" " style="border-radius: 8px;">
            <!-- <h2 class=" text-center">{{ 'Payment Status' | translate }}</h2> -->
            <div class="card-body text-center">
              <div class="col-12">
                <i class='fas fa-check-circle' style='font-size:68px;color:rgb(26, 153, 43);'></i>
              </div>
              <div class="col-12 mt-2">
                {{ 'Pay success' | translate }}
              </div>
            </div>

            <div class="card-footer">
              <div class="row text-center">
                <div class="col-4 offset-8">
                  Go to<a href="https://members.mmkinfo.in/login"> {{ 'Login' |
                    translate }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>
<app-lfooter></app-lfooter>
