import { Component,ChangeDetectorRef, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, CheckboxControlValueAccessor} from '@angular/forms';
import { ApiService } from '../Service/api.service';
import { Fileup } from '../Shared/fileup';
import { baseURL } from '../Shared/baseurl';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../Service/snackbar.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  encapsulation: ViewEncapsulation.None
  // providers: [{
  //   provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  // }]
})
export class RegisterComponent implements OnInit {
  mregForm: FormGroup;
  thirdstepperform: FormGroup;
  otpForm: FormGroup;
  sendkey : {};
  error: string;
  err: string;
  checkbox: boolean = true;
  er:string;
  sub = false;
  loading = false;
 options: string[] = ["A+", "B+", "AB+","O+","A-", "B-", "AB-",'O-'];
 
 btype: string[] = ['Villagepanchayat' ,'Townpanchayat','Muncipality','Corporation']
 districts:Object;
 constituencies:Object;
 dlen: number;
 branch: Object;
 blen: number
 tmmk_dist:Object;
 tdlen: number;
 clen: number;
 ward: Object;
 wlen:number;
 timerOn :boolean;
 countDown:number;
 @ViewChild('fileInput') el: ElementRef;
 imageUrl: any = 'assets/images/admin.png' ;

 randomUrl: any;
 closeResult: any;
 editFile: boolean = true;
 removeUpload: boolean = false;
 att: string;
 vrfyformeditable: boolean =true;
 otpFormeditable: boolean =true;
 thirdFormeditable: boolean =true;
 filetype: Fileup;
 deli: any;
 datasource: any;
 finalRes: any
 timelef: any;

// responsive
mobilescreen: boolean=false;
  // wizard
  step='d-block';
  step2='d-none';
  stepact='active';
  step2act='';

  Setbutton :String;
  mobileNum: string;

  vrfyform: FormGroup;

  declaration:string;

 
  maxDate: Date;

  maxD1 : any;


  constructor(private formBuilder: FormBuilder,private sb: SnackbarService,
     private router: Router, private api: ApiService, private cd: ChangeDetectorRef,private modalService: NgbModal,private _formBuilder: FormBuilder,private spinner: NgxSpinnerService,
        public translate: TranslateService) { 
    translate.addLangs(['English','தமிழ்']);
    if(localStorage.getItem('locale')){
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/)? browserLang: 'தமிழ்');
  
    }
    else{
      localStorage.setItem('locale','தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }

    
  }

  hide: boolean = true;
  changeLang(language: string){
    localStorage.setItem('locale',language);
    this.translate.use(language);
  }
  myFunction() {
    this.hide = !this.hide;
  }
  
  ngOnInit(): void {

    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() -18 ))
    let c = this.maxDate.getMonth()  > 9 ? this.maxDate.getMonth()+ 1 : '0' + (this.maxDate.getMonth()+ 1).toString()
    let dat = this.maxDate.getDate()  > 9 ? this.maxDate.getDate() : '0' + this.maxDate.getDate()
    this.maxD1 = this.maxDate.getFullYear()+'-'+c +'-'+dat


    
    this.vrfyform = this.formBuilder.group({
      mobile_number: ["",[Validators.required,Validators.minLength(6)]],
      otp: ['',Validators.required],
      method: ['verifyotp',Validators.required]
  
    })

    if(screen.width<=425){
    
      this.mobilescreen = true;
    }else{
      this.mobilescreen = false;
    }

    this.otpForm = this.formBuilder.group({
      mobile_number: ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      country_code: ['+91',Validators.required],
      status: ["N",Validators.required],
      method: ['sendotp',Validators.required],
      
      // rememberMe: new FormControl(false)
    }); 


    this.Setbutton = 'N';
    
    this.mregForm = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.getdistrict();

    this.getConstituency();
    this.thirdstepperform = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(30)]],
      father_name: ['', [Validators.required, Validators.minLength(2),
        Validators.maxLength(30)]],
        gender: ['MALE', Validators.required],
        mobile_no: ['', [Validators.required, Validators.minLength(10),
          Validators.maxLength(10)]],
        dob: ['', Validators.required],
        status: [''],
        country: ['India'],
        password: ['', [Validators.required,Validators.minLength(6)]],  
        img_path: [this.imageUrl,[Validators.required]],
        method: ['addUser']
    });
    
    this.mregForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(30)]],
      father_name: ['', [Validators.required, Validators.minLength(2),
        Validators.maxLength(30)]],
        gender: ['MALE', Validators.required],
        mobile_no: ['', [Validators.required, Validators.minLength(10),
          Validators.maxLength(10)]],
        dob: ['', Validators.required],
        email_id: ['',[ Validators.required,Validators.pattern("[^ @]*@[^ @]*")]],
        status: [''],
        country: ['India'],
        constituency: [''],
        password: ['', [Validators.required,Validators.minLength(6)]],  
        address: ['', [Validators.required]],
        branch: ['', ],
        branch_type: ['',],
        ward: ['',],
        temp_branch:[null,[Validators.required]],
        district: [null, [Validators.required]],
        tmmk_dist: [null,[Validators.required]],
        pincode: ['',[ Validators.required,Validators.minLength(8)]],
        blood_group: [null, [Validators.required, Validators.min(1)]],
        img_path: [this.imageUrl,[Validators.required]],
        method: ['addUser']
    });
    

    // condition for gender check

    // this.gender.valueChanges.subscribe(checked =>{
    //   if(checked && this.gender.value === 'MALE'){
    //     this.mregForm.addControl('img_path',new FormControl('assets/images/admin.png', [Validators.required]))
    //   }
    //   else{
    //     this.mregForm.removeControl('img_path')
    //   }
    // })

    // if(this.mregForm.value.gender != '' || null && this.mregForm.value.name != '' || null  ){
    // this.Setbutton = 'Y'
    // }

    

  }



  
  sendotpm(value,stepper: MatStepper){
   
    this.err = '';
    this.sub = true;
    if(this.otpForm.invalid){
      return;
    }
   
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,value,'commonMethod','sendotp').subscribe((d:any)=>{
    
      if(d['data']['status'] === 'Success'){

    

        this.sb.successm(d['data']['msg'])
     
        this.otpFormeditable= !this.otpFormeditable;
        stepper.next();
         this.vrfyform.patchValue({
          mobile_number:value.mobile_number
        });
        
      } 
      if(d['data']['status'] === 'Error'){

        this.spinner.hide()
        this.sb.successm(d['data']['msg'])
       
          
      }
    }) 
  }

  sendotp(value,stepper: MatStepper){
   
    this.err = '';
    this.sub = true;
    if(this.otpForm.invalid){
      return;
    }
   
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,value,'commonMethod','sendotp').subscribe((d:any)=>{
    
      if(d['data']['status'] === 'Success'){

    

        this.sb.success(d['data']['msg'])
     
        this.otpFormeditable= !this.otpFormeditable;
        stepper.next();
         this.vrfyform.patchValue({
          mobile_number:value.mobile_number
        });
        
      } 
      if(d['data']['status'] === 'Error'){

        this.spinner.hide()
        this.sb.success(d['data']['msg'])
       
          
      }
    }) 
  }

  resendotp(value){
  
    console.log(value)
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,value,'commonMethod','sendotp').subscribe((d:any)=>{
   
      
      if(d['data']['status'] === 'Success'){
         this.sb.success(d['data']['msg'])
        // this.spinner.hide()
        // this.otpFormeditable= !this.otpFormeditable;
        // // stepper.next();
        //  this.vrfyform.patchValue({
        //   mobile_number:value.mobile_number
        // });
        
      } 
      if(d['data']['status'] === 'Error'){
        this.sb.success(d['data']['msg'])
        // this.spinner.hide()
        // this.err = d['data']['msg']
        // this.otpForm.controls['mobile_no'].reset()
          
      }
    }) 
  }

  timer(remaining) {
    let m : number;
    let mm: any;
    let ss: any;
    let s : number;
    m = Math.floor(remaining / 60);
    s = remaining % 60;
    
    mm = m < 10 ? '0' + m : m;
    ss = s < 10 ? '0' + s : s;
    this.timelef = mm + ':' + ss;
    remaining -= 1;
    
    if(remaining >= 0 && this.timerOn) {
      setTimeout(function() {
          this.timer(remaining);
      }, 100);
      return;
    }
  
    if(!this.timerOn) {
      // Do validate stuff here
      return;
    }
    
    // Do timeout stuff here
   
  }

  decmodel(content){
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) =>{
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  //method to get the gender
  get gender() {
    return this.mregForm.get('gender') as FormControl;

  }




  uploadFile(event) {
    this.spinner.show()
    this.sb.success('Uploading..Please wait!!')
    
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    let extension = file.name.substring(file.name.lastIndexOf(".")+1);
  
    if (event.target.files && event.target.files[0]) {
      reader.readAsBinaryString(file);
      

      // When file uploads set it to file formcontrol
      reader.onload = () => {
         this.randomUrl = reader.result;
         let encode = btoa(this.randomUrl);
         
        
         this.api.serviceData('ImageUpload',{"folder":"users", "image": encode,"name":this.mregForm.value.mobile_no + '.' + extension},'Upload','add').subscribe(r=>{
          
          this.imageUrl =  baseURL.apiUrl+'assets/'+r['data']['imagepath'];
        
           this.mregForm.patchValue({
            img_path: r['data']['imagepath']

           })
           this.sb.success('Uploaded Successfully')
         })
         
     
        this.editFile = false;
        this.removeUpload = true;
      }
     
      this.cd.markForCheck();    
    }

    this.spinner.hide()
  }

  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.iconsdb.com%2Fblack-icons%2Fadministrator-icon.html&psig=AOvVaw2qjpsFoU63qRQabRWy5BGN&ust=1610992588054000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNjGlKDFo-4CFQAAAAAdAAAAABAD';
    this.editFile = true;
    this.removeUpload = false;
    this.mregForm.patchValue({
      file: [null]
    });
  }

  getbranch(value){
    if(this.mregForm.value.branch){
      this.mregForm.controls['branch'].reset()
    }
    this.spinner.show
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{mmk_dist_id:value,method:'getBranch'},'commonMethod','getData').subscribe((d:any)=>{
    this.branch = d['data']
      if(this.branch){
        this.blen = Object.keys(this.branch).length
      }
      
      this.spinner.hide()
   
     
    })
  }

  getward(value){
    if(this.mregForm.value.ward){
      this.mregForm.controls['ward'].reset()
    }
    this.spinner.show()
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{branch_id:value, method:'getWard'},'commonMethod','getData').subscribe((d:any)=>{
      this.ward = d['data']
      if(this.ward){
        this.wlen = Object.keys(this.ward).length
      }
     
      this.spinner.hide()
    
    })
  }
  getmmkdistrict(value){
    if(this.mregForm.value.tmmk_dist){
      this.mregForm.controls['tmmk_dist'].reset()
    }
    if(this.mregForm.value.branch_type){
      this.mregForm.controls['branch_type'].reset()
    }
    this.spinner.show()
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{district_id:value,method:'getMMKDistrict'},'commonMethod','getData').subscribe((d:any)=>{
    this.tmmk_dist = d['data']
    this.tdlen = Object.keys(this.tmmk_dist).length
    this.spinner.hide() 
    })
  }

  getdistrict(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,{method:'getDistrict'},'commonMethod','getData').subscribe((d:any)=>{
      this.districts = d['data']
      this.dlen = Object.keys(this.districts).length  
    })
  }


  getConstituency(){
    this.att = 'CommonMethods';
    this.api.serviceData(this.att, { method:'getConstituency'},'commonMethod','getData').subscribe((d:any)=>{
      this.constituencies = d['data']
      console.log(this.constituencies)
      this.clen = Object.keys(this.constituencies).length  
    })
  }

  register(value){
    console.log(value)
    // if((value.address === "" || null) || (value.blood_group === "" || null) ||  (value.branch === "" || null) 
    // || (value.branch_type === "" || null) || (value.district === ""|| null) || (value.dob === "" || null)
    // || (value.email_id === "" || (value.father_name === "" || null)  || (value.gender === "" || null)
    // || (value.name === "" || null) || (value.password === "" || null) || (value.pincode === "" || null) 
    // || (value.ward === "" || null) || (value.tmmk_dist === "" || null) ))
    // {
    //   this.sb.error('Fill the required details');
    // }
       if((value.address === "" || null) || (value.blood_group === "" || null)
    ||  (value.district === ""|| null) || (value.dob === "" || null)
    || (value.email_id === "" || (value.father_name === "" || null)  || (value.gender === "" || null)
    || (value.name === "" || null) || (value.password === "" || null) || (value.pincode === "" || null)  || (value.tmmk_dist === "" || null)
    ))
    {
      this.sb.error( 'Fill the required details');
    }
    else if(value.img_path === "assets/images/admin.png"){
      this.sb.error('Upload the profile picture');
   
    }
    else{
    this.loading = true;
    this.att = 'UserDetail';
    this.api.serviceData(this.att,value,'Register','Add').subscribe((d)=>{
      
      if(d['data']['status'] === 'Success')
      {
        this.sb.success('Registered Successfully');
      
        sessionStorage.setItem('payurl',d['data']['redirectUrl']['redirect_url'])
        this.router.navigate(['/mmk/payment'])  
      }
      else{
        this.loading = false;
       this.sb.error(d['data']['msg'])
      } 
    })
    }
   
   
  }

  registerm(value){
    console.log(value)
    // if((value.address === "" || null) || (value.blood_group === "" || null) ||  (value.branch === "" || null) 
    // || (value.branch_type === "" || null) || (value.district === ""|| null) || (value.dob === "" || null)
    // || (value.email_id === "" || (value.father_name === "" || null)  || (value.gender === "" || null)
    // || (value.name === "" || null) || (value.password === "" || null) || (value.pincode === "" || null) 
    // || (value.ward === "" || null) || (value.tmmk_dist === "" || null) ))
    // {
    //   this.sb.error('Fill the required details');
    // }
       if((value.address === "" || null) || (value.blood_group === "" || null)
    ||  (value.district === ""|| null) || (value.dob === "" || null)
    || (value.email_id === "" || (value.father_name === "" || null)  || (value.gender === "" || null)
    || (value.name === "" || null) || (value.password === "" || null) || (value.pincode === "" || null)  || (value.tmmk_dist === "" || null)
    ))
    {
      this.sb.errorm( 'Fill the required details');
    }
    else if(value.img_path === "assets/images/admin.png"){
      this.sb.errorm('Upload the profile picture');
   
    }
    else{
    this.loading = true;
    this.att = 'UserDetail';
    this.api.serviceData(this.att,value,'Register','Add').subscribe((d)=>{
      
      if(d['data']['status'] === 'Success')
      {
        this.sb.successm('Registered Successfully');
      
        sessionStorage.setItem('payurl',d['data']['redirectUrl']['redirect_url'])
        this.router.navigate(['/mmk/payment'])  
      }
      else{
        this.loading = false;
       this.sb.errorm(d['data']['msg'])
      } 
    })
    }
   
   
  }

  get f() { return this.mregForm.controls; }

  get thirdval() { return this.thirdstepperform.controls; }
  vrfyotpm(value,stepper: MatStepper){
   
        this.att = 'CommonMethods';
        this.api.serviceData(this.att,value,'commonMethod','verifyotp').subscribe(d =>{
       if(d['data']['status'] === 'Success'){
         this.sb.successm(d['data']['msg'])
        
       ;
        this.thirdstepperform.patchValue({mobile_no: value.mobile_number});
         this.modalService.dismissAll()
         this.vrfyformeditable= !this.vrfyformeditable;
         stepper.next()
       }
       if(d['data']['status'] === 'Error'){
       
        this.spinner.hide()
        this.sb.errorm(d['data']['msg'])
         
      }
     })
   }

   vrfyotp(value,stepper: MatStepper){
   
    this.att = 'CommonMethods';
    this.api.serviceData(this.att,value,'commonMethod','verifyotp').subscribe(d =>{
   if(d['data']['status'] === 'Success'){
     this.sb.success(d['data']['msg'])
    
   ;
    this.thirdstepperform.patchValue({mobile_no: value.mobile_number});
     this.modalService.dismissAll()
     this.vrfyformeditable= !this.vrfyformeditable;
     stepper.next()
   }
   if(d['data']['status'] === 'Error'){
   
    this.spinner.hide()
    this.sb.error(d['data']['msg'])
     
  }
 })
}

   thirdstepperbutton(value,stepper: MatStepper){
    
    this.mregForm.patchValue(value);
    this.thirdFormeditable=!this.thirdFormeditable;
    this.sb.success('Data Validated')
 
    stepper.next();
  
   }

   thirdstepperbuttonm(value,stepper: MatStepper){
    
    this.mregForm.patchValue(value);
    this.thirdFormeditable=!this.thirdFormeditable;
    this.sb.successm('Data Validated')
 
    stepper.next();
  
   }
   get ff() { return this.otpForm.controls; }


   get fff() {
     return this.vrfyform.controls;
   }

   cancel(){
     this.modalService.dismissAll()
   }

}
