import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { ApiService } from 'src/app/Service/api.service';
import { baseURL } from 'src/app/Shared/baseurl';
import { Fileup } from 'src/app/Shared/fileup';
import { res } from 'src/app/Shared/res';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ScriptService } from './script.service';
declare var pdfMake: any;
@Component({
  selector: 'app-profile-action',
  templateUrl: './profile-action.component.html',
  styleUrls: ['./profile-action.component.css']
})
export class ProfileActionComponent implements OnInit {
  img1
  img2
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  att: string;
  profvalue: any;
  last_date: any;
  imageUrl: string;
  base_url = 'https://members.mmktnelections.com/mmk/qrprofile/?memberId=';
  age: number;
  @ViewChild('screen') screen: ElementRef;
  // @ViewChild('screen2') screen2: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  // @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  @ViewChild('screen1') screen1: ElementRef;
  // @ViewChild('screen2') screen2: ElementRef;
  @ViewChild('canvas1') canvas1: ElementRef;
  // @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('downloadLink1') downloadLink1: ElementRef;

  constructor(private api: ApiService, private router: Router,  private spinner: NgxSpinnerService, private scriptService: ScriptService) {
    this.scriptService.load('pdfMake', 'vfsFonts');
  }

  ngOnInit(): void {

    this.profvalue =  JSON.parse(sessionStorage.getItem('cardet'));
  

    if(this.profvalue['approve_one_date']){
      var year = new Date(this.profvalue['approve_one_date']).getFullYear();
      var month = new Date(this.profvalue['approve_one_date']).getMonth();
      var day = new Date(this.profvalue['approve_one_date']).getDate();
      this.last_date = new Date(year + 2, month,day)
    
    }

   
  
    // console.log(this.profvalue)
    this.age = this.getAge(this.profvalue['dob'])
    if (this.profvalue['gender'] === 'FEMALE') {
      this.imageUrl = 'assets/images/women.png'
    }
    else {
      this.imageUrl = baseURL.apiUrl + 'assets/' + this.profvalue['img_path']
    }

  }

  // downloadImage(){
  //   html2canvas(this.screen.nativeElement,{backgroundColor:null,height:204,width:325}).then(canvas => {
  //     this.canvas.nativeElement.src = canvas.toDataURL();
  //     this.downloadLink.nativeElement.href = canvas.toDataURL('image/png',1.0);
  //     this.downloadLink.nativeElement.download = 'memcardfront.png';
  //     this.downloadLink.nativeElement.click();

  //   });
  //    html2canvas(this.screen1.nativeElement,{backgroundColor:null,height:204,width:325}).then(canvas1 => {
  //     this.canvas1.nativeElement.src = canvas1.toDataURL();
  //     this.downloadLink1.nativeElement.href = canvas1.toDataURL('image/png',1.0);
  //     this.downloadLink1.nativeElement.download = 'memcardfront2.png';
  //     this.downloadLink1.nativeElement.click();

  //   });

  // }

  // getpro(value){
  //   this.spinner.show()
  //   this.att = 'commonMethods';
  //   this.api.serviceData(this.att,{user_id: value.id, method: "getsingledata" },'commonMethod','member_registration').subscribe(p=>{
  //     this.profvalue = p['data'][0]

  //     this.api.serviceData(this.att,{user_id: value.id, method: "getsingledata" },'commonMethod','member_account').subscribe(d=>{
  //       Object.assign(this.profvalue,d['data'][0]);

  //       this.age = this.getAge(this.profvalue['dob'])
  //       if(this.profvalue['gender'] === 'FEMALE'){
  //         this.imageUrl = 'assets/images/women.png'
  //       }
  //       else{
  //         this.imageUrl =  baseURL.apiUrl+'assets/'+this.profvalue['img_path']
  //       }

  //       this.toDataURL(this.imageUrl,
  //         function (dataUrl) {
  //           let fi;
  //           fi = dataUrl;

  //           document.getElementById('myImg').style.objectFit='cover';
  //           var myCanvas = <HTMLImageElement> document.getElementById('myImg');
  //           myCanvas.src = dataUrl;

  //         },''
  //       )
  //     })
  //     this.spinner.hide()
  //   })
  // }
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // function to convert img url to base64
  // toDataURL(src, callback, outputFormat) {
  //   let image = new Image();
  //   image.crossOrigin = 'anonymous';
  //   image.onload = function () {
  //     let dimension = <HTMLImageElement> document.getElementById('myImg');
  //     let canvas = document.createElement('canvas');
  //     let ctx = canvas.getContext('2d');
  //     let dataURL;
  //     canvas.height = dimension.height;
  //     canvas.width = dimension.width;

  //     ctx.drawImage(image, 0, 0);
  //     dataURL = canvas.toDataURL(outputFormat,1);
  //     callback(dataURL);
  //   };
  //   image.src = src;
  //   if (image.complete || image.complete === undefined) {
  //     // image.src = "https://i.imgur.com/rAsrKG7.jpg";
  //     image.src = src;
  //   }
  // }

  // functition to generate pdf
  public captureScreen() {
    var data = document.getElementById('printsection');
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>' + this.profvalue["mmk_mem_id"] + '</title><style type="text/css" > @media print {@page {size: 86mm 54.2mm ; margin:0 !important; }} .bshadow > img{height: 65px !important;}.page-break{ display:block; page-break-before:auto;margin-top 0% !important;page-break-after:avoid; -webkit-print-color-adjust: exact; } </style></head>';
    windowContent += '<body style="margin: 0 !important">';
    windowContent += data.innerHTML;
    windowContent += '</body>';
    windowContent += '</html>';

    const printWin = window.open('', '', '');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();

    setTimeout(() => {
      this.router.navigateByUrl('/admin/archives')
    }, 500);
    
    //location.reload();

    // printWin.document.addEventListener('load', function() {
    //     printWin.focus();
    //     printWin.print();
    //     printWin.document.close();
    //     printWin.close();
    // }, true);

    //   var data = document.getElementById('contentToConvert');
    //   var HTML_Width = 86;
    //   var HTML_Height = 54;
    // var top_left_margin = 0;
    // var PDF_Width = HTML_Width;
    // var PDF_Height = HTML_Height;
    // var canvas_image_width = HTML_Width;
    // var canvas_image_height = HTML_Height;

    // const pag = [48,86];
    // var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;



    // html2canvas(data.getElementsByTagName("section")[0],{allowTaint:true}).then(function(canvas) {
    // 	canvas.getContext('2d');
    // 	var imgData = canvas.toDataURL("image/png", 1.0);
    // 	var pdf = new jsPDF('l', 'em', pag);
    //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //       // for (var i = 0; i <= totalPDFPages; i++) {
    //       // 	pdf.addPage(pag, 'l');
    //       //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //       // }
    //       html2canvas(data.getElementsByTagName("section")[1],{allowTaint:true}).then(function(canvas2) {
    //         var imgData2 = canvas2.toDataURL("image/png", 1.0);
    //         pdf.addPage(pag, 'l');
    //         pdf.addImage(imgData2, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    //         pdf.save("HTML-Document.pdf");
    //       });
    //     // pdf.save("HTML-Document.pdf");
    //    });

  }



  // async showPdf() {
  //   let page1 = document.getElementById("printsection1");
  //   let page2 = document.getElementById("printsection2");
  //   let docDefinition = {
  //     pageSize: {
  //       width: 325,
  //       height: 204,
  //       marigin: 0
  //     },

  //     pageMargins: [0, 0, 0, 0],
  //     pageOrientation: "landscape",
  //     content: [
  //       {
  //         image: await this.getBase64ImageFromURL(
  //           page1
  //         ),
  //         height: 204,
  //         width: 325
  //       },
  //       {
  //         image: await this.getBase64ImageFromURL(
  //           page2
  //         ),
  //         height: 204,
  //         width: 325
  //       }
  //     ]
  //   };

  //   pdfMake.createPdf(docDefinition).open();
  // }

  /*getBase64ImageFromURL(url) {
    // return new Promise((resolve, reject) => {
    //   htmlToImage.toPng(url)
    //   .then(function (dataUrl) {
    //     var img = new Image();
    //     img.setAttribute("crossOrigin", "anonymous");
    //     img.onload = () => {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = img.width;
    //     canvas.height = img.height;

    //     var ctx = canvas.getContext("2d");
    //     ctx.drawImage(img, 0, 0);

    //     var dataURL = canvas.toDataURL("image/png");

    //     resolve(dataURL);
    //   };

    //   img.onerror = error => {
    //     reject(error);
    //   };

    //     img.src = dataUrl;
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });
      // var img = new Image();
      // img.setAttribute("crossOrigin", "anonymous");

      // img.onload = () => {
      //   var canvas = document.createElement("canvas");
      //   canvas.width = img.width;
      //   canvas.height = img.height;

      //   var ctx = canvas.getContext("2d");
      //   ctx.drawImage(img, 0, 0);

      //   var dataURL = canvas.toDataURL("image/png");

      //   resolve(dataURL);
      // };

      // img.onerror = error => {
      //   reject(error);
      // };

      // img.src = url;
   // });
  }*/

}
