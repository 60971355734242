<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<nav class=" text-center" style=" background: linear-gradient(130deg,#000000,#adabac 41.07%,#ffffff 76.05%);">
  <img src="../../assets/logommk.png" class="img-fluid" alt="">
</nav>
<!--
<div class="content" >
  <div class="row">
      <div class="col-12">
        <ng-template #sDialog >
          <h2 matDialogTitle> {{ 'Authentication' | translate }} </h2>
          <div class="mt-2"></div>
          <p class="text-center" matDialogContent><em> {{ 'Login Success' | translate }} </em></p>
          <div class="mt-2 text-center">
            <i class="fa fa-thumbs-up fa-3x"></i>
          </div>
        </ng-template>
        </div>
  </div>
  <br>
  <br>
  <div class="row no-gutters">
      <div class="col-12 col-md-5 col-lg-7  d-none d-sn-block">
          <img src="/assets/images/web.JPG"  width="100%" alt="logo">
      </div>
      <div class="col-12 col-md-5 col-lg-7 pb-3 d-block d-sm-none">
        <img src="/assets/images/web.JPG"  width="100%" alt="logo">
    </div>
      <div class="col-12 col-md-7 col-lg-5 mx-auto" >
        <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
          <h2 class=" card-header  text-center  secondary-bg">{{ 'Member Login' | translate }}</h2>
          <div class="card-body">

            <form [formGroup]="logForm" (ngSubmit)="login(logForm.value)">
              <div class="form-group">
                  <label for="mobile">{{ 'Mobile Number' | translate }}</label>
                  <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                  <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                      <div *ngIf="f.mobile.errors.required">{{ 'Mobile is required' | translate }}</div>
                  </div>
              </div>
              <div class="form-group">

                  <label for="password">{{ 'Password' | translate }}</label>
                <div style="display: flex;">
                  <input type="password" style="width: 90%;" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [type]="hide ? 'password' : 'text'"   />

                  <mat-icon style="padding-left: 3%;padding-top: 1%;color:#112d4e;" class="placeholder " (click)="myFunction()">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">{{ 'Password is required' | translate }}</div>
                  </div>
              </div>

              <div class="form-group" >



              </div>



              <div class="form-group">
                  <div class="row text-center mt-4">
                    <div class="col-6">
                      <a routerLink="/mmk/forgetpassword" class="text-secondary" > {{ 'Forget Password' | translate }}</a>

                 </div>

                      <div class="col-6">
                  <button [disabled]="loading" class="btn  btn-secondary bg-darkz">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                   {{ 'Login' | translate }}
                  </button>
                  </div>

                  </div>

               </div>

               <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
            </form>
            <hr>
            <div  class="row text-center" >

              <div class="col-12">
                {{ 'Not a member ?' | translate }}&nbsp;&nbsp;&nbsp;<a routerLink="/mmk/register" class="btn  btn-secondary  secondary-bg"> {{ 'Register' | translate }}</a>


             </div>
            </div>






          </div>
        </div>
      </div>


    </div>

    <div class="content d-block d-md-none">
  <div class="row">
    <div class="col-12 col-md-7 col-lg-5 mx-auto" >
      <div class="card bg-gradient-white shadow" style="border-radius: 5%;" >
        <h2 class="card-header text-center bg-gradient-secondary"> {{ 'Contact' | translate }}</h2>


        <div class="card-footer">
          <div class="row text-center">


             <div class="col-12">
             <p>{{ 'Email Address' | translate }} : <b>mmktnelections@gmail.com</b></p>
            <p>{{ 'Mobile Number' | translate }} : <B>9095585988</B> </p>
             </div>
          </div>
        </div>
        </div>
   </div>
 </div>
</div>
</div> -->
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ng-template #sDialog>
          <h2 matDialogTitle> {{ 'Authentication' | translate }} </h2>
          <div class="mt-2"></div>
          <p class="text-center" matDialogContent><em> {{ 'Login Success' | translate }} </em></p>
          <div class="mt-2 text-center">
            <i class="fa fa-thumbs-up fa-3x"></i>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="card login-card">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img src="../../assets/images/flag.jpg" alt="login" class="login-card-img">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <div class="brand-wrapper">
              {{ 'Language' | translate }} :
              <select #langSelect (change)="changeLang(langSelect.value)"
                style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">

                <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                  [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
              </select>
            </div>
            <p class="login-card-description">{{ 'Member Login' | translate }}</p>
            <form [formGroup]="logForm" (ngSubmit)="login(logForm.value)">
              <div class="form-group">
                <label for="mobile" class="sr-only">{{ 'Mobile Number' | translate }}</label>
                <input name="mobile" id="mobile" type="text" formControlName="mobile" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" placeholder="Mobile No.">
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                  <div *ngIf="f.mobile.errors.required">{{ 'Mobile is required' | translate }}</div>
                </div>
              </div>
              <div class="form-group mb-2">
                <label for="password" class="sr-only">{{ 'Password' | translate }}</label>
                <div class="input-group">

                  <input type="password" name="password" id="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [type]="hide ? 'password' : 'text'"
                    placeholder="***********">
                  <div class="input-group-addon">
                    <mat-icon style="padding-top: 36%;color:#112d4e;" class="placeholder " (click)="myFunction()">{{hide
                      ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </div>
                </div>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ 'Password is required' | translate }}</div>
                </div>
              </div>
              <a routerLink="/mmk/forgetpassword" class="text-secondary pull-right mb-4"><b>{{ 'Forget Password' |
                  translate }}</b> </a>

              <button [disabled]="loading" class="btn btn-block login-btn bg-darkz  mb-4 ">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'Login' | translate }}
              </button>

            </form>

            <p class="login-card-footer-text">{{ 'Not a member ?' | translate }}&nbsp;&nbsp;&nbsp;<a
                [routerLink]="['/register']" style="text-decoration: underline;"> {{ 'Register' | translate }}</a></p>
            <nav class="login-card-footer-nav">
              <div *ngIf="error" class="alert alert-default-dark mt-3 mb-0">{{error}}</div>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>
<app-lfooter></app-lfooter>
