
    <nav class="main-header navbar navbar-expand  navbar-expand-md">
        
        <ul class="navbar-nav">
        
         <li class="nav-item" >
           <a class="nav-link"    data-widget="pushmenu">&nbsp; &nbsp;<i class="fas fa-bars"></i></a>
         </li>
       </ul> 
    
    <ul class="navbar-nav ml-auto d-none d-md-block" style="position: relative;" >
      <li class="nav-item " >
        <label>{{ 'Language' | translate }} :</label>
         <select #langSelect (change)= "changeLang(langSelect.value)" style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">
         
             <option style="padding: 5px;border:0 !important" *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang  | translate  }}</option>
         </select>
      </li>
      
    </ul>  
     </nav>
     
     <aside class="main-sidebar sidebar-dark-primary elevation-4" *ngIf="profvalue">
     <!-- Sidebar -->
     <a href="#" class="brand-link">
      <img src="assets/images/flag.jpg" alt="MMk Logo" class="brand-image elevation-3" >
      <span class="brand-text font-weight-light">MMK</span>
    </a>
     <div class="sidebar" *ngIf="profvalue['payment_status'] == 'Y' && profvalue['approve_one'] == 'Y'; else noside ">
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image" *ngIf="imageUrl">
            <img  src={{imageUrl}} class=" brand-image img-circle" alt="User Image">
          </div>
          <div class="info ">
            <a routerLink="/dashboard/profile" routerLinkActive="inner_active"><i class="d-block"></i> </a>
             <a href="#" class="d-block"> {{ profvalue['name'] }}</a>
          </div> 
       
    
          
   

    </div> 
    
    <hr>
     <nav>
       <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item mb-1 ">
          <div class="row d-block d-sm-none">
            <div class="col-12">
              <label style="color: white;" class="ml-2">{{ 'Language' | translate }} :</label>
              <select #langSelect (change)= "changeLang(langSelect.value)" style="margin-left: 10px;border-radius: 5px;padding: 5px;">
       
                <option style="padding: 5px;border:0 !important" *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang | translate }}</option>
            </select>
            </div>
          </div> 
        </li>
        <li class="nav-item mb-1 active">
          <a [routerLink]="['/user/memcard'] " routerLinkActive="inner_active" class="nav-link">
            <i class="nav-icon fa fa-credit-card"></i>
            <p> {{ 'Membership Card' | translate }}</p>
          </a>
        </li>
              <li class="nav-item mb-1">
                <a [routerLink]="['/user/profile']" routerLinkActive="active"  class="nav-link">
                  <i class="nav-icon fas fa-user"></i>
                  <p> {{ 'Profile' | translate }} </p>
                </a>
              </li>

              <li class="nav-item">
                <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                  <i class="nav-icon fas fa-sign-out-alt"></i>
                  <p>{{ 'Logout' | translate }}</p>
                </a>
              </li>

            
         </ul>
 
        
         </nav>
     </div>
<ng-template  #noside>


     <div class="sidebar" >
      <div class="user-panel mt-4 d-flex" >
          <div class="image">
              <img  src="assets/images/admin.png" class=" brand-image img-circle elevation-2" alt="User Image">
            </div>
         
            <div class="row d-block d-sm-none">
              <div class="col-12">
                <label style="color: white;" class="ml-2">{{ 'Language' | translate }} :</label>
                <select #langSelect (change)= "changeLang(langSelect.value)" style="border:0;margin-left: 10px;border-radius: 5px;padding: 5px;">
         
                  <option style="padding: 5px;border:0 !important" *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
              </select>
              </div>
            </div> 
      </div> 
      
      <hr>
       <nav>
         <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                  <a (click)="logout()" class="nav-link" style="cursor: pointer;">
                    <i style="color: white;" class="nav-icon fas fa-sign-out-alt"></i>
                    <p style="color: white;">{{ 'Logout' | translate }}</p>
                  </a>
                </li>

              
           </ul>
           </nav>
       </div>
      </ng-template>

     </aside> 

      <div class="row">
      <div class="col-12">
        <ng-template #sDialog >
          <h2 matDialogTitle> {{ 'Authentication' | translate }} </h2>
          <div class="mt-2"></div>
          <p class="text-center" matDialogContent><em> {{ 'Logged Out Successfully' | translate }}</em></p>
          <div class="mt-2 text-center">
            <i class="fa fa-thumbs-up fa-3x"></i>
          </div>
        </ng-template>
      </div>
      </div>

  
   
  
   