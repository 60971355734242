import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from '../Service/api.service';
import { res } from '../Shared/res';
import { AuthenticationService } from '../Service/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../Shared/User';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../Service/snackbar.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('sDialog') sDialog: TemplateRef<any>;

  logForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  error = '';
  logd: any;
  att: string;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  // cookie:string;

  openOtherDialog() {
  
    this.dialog.open(this.sDialog,{
      panelClass: 'my-classc'
    });
   
  }
  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }
  constructor(
    private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private api: ApiService,
        private dialog: MatDialog,
        public translate: TranslateService,
        private sb: SnackbarService
        
  ) { 
    translate.addLangs(['English','தமிழ்']);
    if(localStorage.getItem('locale')){
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/English|தமிழ்/)? browserLang: 'தமிழ்');
  
    }
    else{
      localStorage.setItem('locale','தமிழ்');
      translate.setDefaultLang('தமிழ்');
    }
    if(this.authenticationService.currentUserValue){
      this.router.navigate(['user/profile'])
    }

  
  }
 
  // constructor(public translate: TranslateService) { 
    
  //   }
  

  changeLang(language: string){
    localStorage.setItem('locale',language);
    this.translate.use(language);
  }

  login(value:res){
    this.submitted = true;
    if(this.logForm.invalid){
      return;
    }
    this.loading = true;
    this.authenticationService.login(value,'Login','login').pipe(first())
    .subscribe(data=>{
     
     
      if(data['data']['status'] === 'Success'){
        if(data['data']['redirectUrl']){
          sessionStorage.setItem('paylink',data['data']['redirectUrl'])
         
        }
        if(data['data']['data']){
          let cookie = JSON.parse(atob(atob(atob(data['data']['data']))))
         
          sessionStorage.setItem('user_id', cookie.accountDetails[0].member_acc_id)
          sessionStorage.setItem('prev', cookie.accountDetails[0].prev)
          this.api.accountDet = cookie.accountDetails[0].member_acc_id;
        }
        this.openOtherDialog();
        setTimeout(()=>{
          this.dialog.closeAll()
          this.router.navigateByUrl('/user/dashboard')
        }, 2000);
      }
      else{
        this.loading = false;
        this.sb.error('Invalid Credentials')
        // this.error = 'Invalid Credentials';
      }
    },
    )     
  }

  ngOnInit(): void {
    this.logForm = this.formBuilder.group({
      mobile: new FormControl('', [Validators.required,Validators.minLength(10)]),
      password: new FormControl('', [Validators.required,Validators.minLength(6)]),
      method: new FormControl('login'),
      role: new FormControl('')
  });

}
get f() { return this.logForm.controls; }

}
