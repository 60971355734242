import { Component, OnInit,ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DistMaster } from 'src/app/Shared/DistMaster';
import { ApiService } from 'src/app/Service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DistMasComponent } from '../dist-mas/dist-mas.component';
import { MmkMasComponent } from '../mmk-mas/mmk-mas.component';






@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MasterComponent implements OnInit {

  @ViewChild(DistMasComponent)  private  dismas : DistMasComponent;
  @ViewChild(MmkMasComponent) private mmkmas : MmkMasComponent;
  dataSource = new MatTableDataSource();
  constructor(private api: ApiService,private spinner: NgxSpinnerService) {
   }
  ngOnInit(): void { 
      this.spinner.show()
      setTimeout(()=>{
        this.spinner.hide()
      }, 1000); 
      if(screen.width<=480)
      document.body.className += 'sidebar-closed sidebar-collapse';
    }

    // onTabChanged(event: MatTabChangeEvent) 
    // {
    //   if(event.index == 0)
    // {
    //   this.dismas.
    // }

    // }
}
