<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-spin" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- Content Header (Page header) -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">

        <h1 class="m-0">{{ 'Ward' | translate }}</h1>
      </div><!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']" >Home</a></li>
          <li class="breadcrumb-item"> {{ 'Master Data' | translate }}</li>
          <li class="breadcrumb-item active">{{ 'Ward' | translate }}</li>
        </ol>
      </div><!-- /.col -->
    </div><!-- /.row -->
  </div><!-- /.container-fluid -->
</div>
<section class="content">
  <div class="contanier-fluid m-2">
    <section class="card p-4 ">
      <div class="row justify-content-center ">
        <div class="col-12 col-md-6 ">

          <form [formGroup]="dismasForm" (ngSubmit)="add(dismasForm.value)">

            <div class="form-group" *ngIf="!f.id.value">
              <label for="district">{{ 'TMMK District' | translate }}</label>
              <select class="form-control" formControlName="district">
                <option [ngValue]="null" disabled>{{ 'Select TMMK District' | translate }}</option>
                <option *ngFor="let dis of tmmk_dist" [value]="dis.tmmk_dist_id"
                  [selected]="dis.tmmk_dist_id === f.district.value">{{dis.tmmk_district_name}}</option>
              </select>
            </div>

            <div class="form-group" *ngIf="!f.id.value">
              <label for="branchtype">{{ 'Branch Type' | translate }}</label>
              <select class="form-control" formControlName="branchtype"
                (change)="getbranch(f.district.value,f.branchtype.value)">
                <option [ngValue]="null" disabled>{{ 'Select Branch Type' | translate }}</option>
                <option *ngFor="let bt of btype ; let i = index;" value="{{i+1}}">{{ bt | translate }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="mapped_data">{{ 'Branch' | translate }}</label>
              <select class="form-control" formControlName="mapped_data">
                <option [ngValue]="null" disabled>{{ 'Select Branch' | translate }}</option>
                <option *ngFor="let dis of branch" [value]="dis.branch_id"
                  [selected]="dis.branch_id === f.mapped_data.value">{{dis.branch_name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="name">{{ 'Ward' | translate }}</label>
              <input type="text" formControlName="name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{ 'Ward is required' | translate }}</div>
              </div>
            </div>
            <div class="form-group" *ngIf="f.id.value">
              <label for="status">{{ 'Status' | translate }}</label>
              <select class="form-control" formControlName="status" [ngClass]="{ 'is-invalid': f.status.errors }">
                <option [ngValue]="null" disabled>{{ 'Select Status' | translate }}</option>
                <option *ngFor="let st of stat" [value]="st">{{ st | translate }}</option>
              </select>
            </div>

            <div class="form-group  text-right">
              <div *ngIf="!f.id.value; else elseBlock">
                <input class="btn btn-lg  bg-darkz   mt-3" type="submit"
                  [disabled]="!f.name.value || !f.status.value || !f.mapped_data.value || !f.branchtype.value || !f.district.value"
                  style="color:white;" value="{{ 'Add' | translate }}">
              </div>
              <ng-template #elseBlock>
                <input class="btn btn-lg  bg-darkz mt-3 " type="submit"
                  [disabled]="!f.name.value || !f.status.value || !f.mapped_data.value " style="color:white;"
                  value="{{ 'Update' | translate }}">
              </ng-template>

            </div>


          </form>
        </div>
      </div>
    </section>
    <section class="card p-1 mt-2">
      <div class="row m-2">
        <div class="col-sm-3 col-xs-3   p-3">
          <button mat-raised-button  (click)="exportTable()"><mat-icon>file_download</mat-icon>  {{ 'Export' | translate
            }}</button>
          
        </div>

        <div class="col-sm-3  col-xs-3  offset-md-6 p-3 ">
          <input id="filter" class="form-control" type="text" (keyup)="doFilter($event.target.value)"
            placeholder="{{ 'Search' | translate}}">
        </div>
        <div class="col-12" #TABLE1>
          <table mat-table [dataSource]="datasource" matSort *ngIf="datasource; else load">

            <ng-container matColumnDef="branch_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Branch' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngFor="let b of branchtable">
                  <p *ngIf="element.branch_id == b.branch_id">{{ b.branch_name }}</p>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="branch_short_name">
              <th mat-header-cell *matHeaderCellDef>{{ 'Branch Type' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngFor="let b of branchtable">
                  <p *ngIf="element.branch_id == b.branch_id">{{ btype[b.branch_short_name-1] }}</p>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ward_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Ward' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.ward_name | translate }} </td>
            </ng-container>


            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{ element.status | translate }} </td>
            </ng-container>

            <ng-container matColumnDef="Edit">
              <th mat-header-cell *matHeaderCellDef>{{ 'Edit' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button (click)="edit(element)"><mat-icon>edit</mat-icon>  {{ 'Edit' | translate }}</button>
                <!-- <a mat-raised-button [routerLink]="['/edit/${element.district_id}']" >Edit</a> -->
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <ng-template #load>
            <div class="row" style="align-items: center;">
              <div class="col-2">
                <div class="spinner-border text-blue">

                </div>
              </div>
              <div class="col-10">
                <h5>{{ 'Loading....' | translate }}</h5>
              </div>
            </div>
          </ng-template>
          <mat-paginator [pageSizeOptions]="[10, 20, 30,40,50,60]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </section>
  </div>
</section>
